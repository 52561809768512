import { levels, LogLevelDesc } from "loglevel"
import { Env } from "../enums"

type Config = {
  APP_NAME: string
  APP_ENVIRONMENT: Env
  APP_BUILD_NUMBER: string
  APP_BUNDLE_ID: string
  API_SERVER_URL: string
  ASSETS_SERVER_URL: string
  FIREBASE_API_KEY: string
  FIREBASE_AUTH_DOMAIN: string
  FIREBASE_DB_URL: string
  FIREBASE_PROJECT_ID?: string
  FIREBASE_EMULATOR_URL: string
  LOGGER_LEVEL?: LogLevelDesc
  MEDIA_SERVER_URL: string
  METRICS_SERVER_URL: string
  MEDIA_SERVER_ICE_SERVERS: string
  SENTRY_DSN?: string
}

const env = process.env.APP_ENVS as unknown as Config

export default {
  app: {
    name: env.APP_NAME,
    environment: env.APP_ENVIRONMENT,
    buildNumber: env.APP_BUILD_NUMBER,
    bundleId: env.APP_BUNDLE_ID,
  },
  api: {
    url: env.API_SERVER_URL,
  },
  assets: {
    url: env.ASSETS_SERVER_URL,
  },
  metricsServerUrl: env.METRICS_SERVER_URL,
  firebase: {
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    dbUrl: env.FIREBASE_DB_URL,
    projectId: env.FIREBASE_PROJECT_ID,
    emulatorUrl: env.FIREBASE_EMULATOR_URL,
  },
  logger: {
    level: env.LOGGER_LEVEL || levels.DEBUG,
  },
  media: {
    url: env.MEDIA_SERVER_URL,
    iceServers: JSON.parse(env.MEDIA_SERVER_ICE_SERVERS),
    // Possible default resolutions.
    resolutions: [
      { width: 320, height: 240, title: "QVGA", allowed: true },
      { width: 640, height: 480, title: "VGA", allowed: true },
      { width: 1280, height: 720, title: "HD", allowed: true },
      { width: 1920, height: 1080, title: "FHD", allowed: true },
      { width: 4096, height: 2160, title: "4K", allowed: true },
      { width: 4096, height: 2048, title: "4K 2:1", allowed: true },
      { width: 5760, height: 2880, title: "6K", allowed: true },
      { width: 7680, height: 4320, title: "8K", allowed: true },
    ],
    frameRate: { ideal: 30, max: 30 },
  },
  sentry: {
    dsn: env.SENTRY_DSN,
  },
}
