import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { List, ChevronDown } from "react-ionicons"
import ReactTooltip from "react-tooltip"
import { Flex, FlexProps } from "rebass"
import { Button } from "../../components/Button"
import { Select, SelectOption } from "../../components/Select"
import { selectDevices } from "../../redux/deviceSlice"

type SelectPositionX = "left" | "right"
type SelectPositionY = "top" | "bottom"

type Props = FlexProps & {
  deviceType: MediaDeviceKind
  onDeviceChange: (id: string) => void
  defaultDevice?: string
  allowAutoChoose?: boolean
  iconSize?: number
  positionX?: SelectPositionX
  positionY?: SelectPositionY
}

export const DeviceSelect: React.FC<Props> = ({
  deviceType,
  onDeviceChange,
  defaultDevice,
  allowAutoChoose = true,
  positionX = "right",
  positionY = "top",
  iconSize = 30,
  ...props
}) => {
  const [deviceId, setDeviceId] = useState<string>()
  const [showDeviceSelect, setShowDeviceSelect] = useState(false)
  const devices = useSelector(selectDevices)

  const deviceSelectOptions = devices
    .filter((device) => device.kind === deviceType)
    .map(({ id, title }) => ({
      title,
      value: id,
    }))

  const onDeviceSelect = (option: SelectOption) => {
    onDeviceChange(option.value)
    setShowDeviceSelect(false)
  }

  // Check/select default device on device list change.
  useEffect(() => {
    const deviceToChoose = defaultDevice || deviceId

    // Set first available device on first run or when the current device was disconnected.
    const isDeviceConnected = deviceSelectOptions.find((d) => d.value === deviceToChoose)
    if (isDeviceConnected) {
      setDeviceId(deviceToChoose)
    } else if (allowAutoChoose && deviceSelectOptions.length) {
      // Use the first device by default (which is system's default usually).
      setDeviceId(deviceSelectOptions[0].value)
    } else {
      // Clear the device. It will allow us to get it back when it's connected again.
      setDeviceId(undefined)
    }
  }, [allowAutoChoose, deviceId, deviceSelectOptions, defaultDevice])

  useEffect(() => {
    if (deviceId) {
      onDeviceChange(deviceId)
    }
    // NOTE: We skip onDeviceChange dependency to prevent extra call.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId])

  return (
    <Flex {...props}>
      <Button
        onClick={() => setShowDeviceSelect(true)}
        variant="warning"
        p="2px 6px"
        style={{ position: "relative" }}
        data-tip="Select device"
        data-for="media-device"
      >
        <List color="white" width={`${iconSize}px`} height={`${iconSize}px`} />
        <ChevronDown
          color="white"
          width={`${iconSize / 2}px`}
          height={`${iconSize / 2}px`}
          style={{
            position: "absolute",
            bottom: "-2px",
            right: "5px",
          }}
        />
      </Button>
      <ReactTooltip id="media-device" effect="solid" />
      {showDeviceSelect && (
        <Select
          options={deviceSelectOptions}
          selected={deviceId}
          onCheck={onDeviceSelect}
          onClose={() => setShowDeviceSelect(false)}
          width="320px"
          left={positionX === "right" ? "auto" : undefined}
          right={positionX === "left" ? "auto" : undefined}
          top={positionY === "top" ? "auto" : "50px"}
          bottom={positionY === "top" ? "45px" : "auto"}
          borderBottomLeftRadius={positionY === "bottom" ? "10px" : undefined}
          borderTopLeftRadius={positionY === "bottom" ? "0px" : undefined}
        />
      )}
    </Flex>
  )
}
