import { createGlobalStyle } from "styled-components"
import fontGilroyRegularWoff from "../assets/fonts/gilroy-regular.woff"
import fontGilroyRegularEot from "../assets/fonts/gilroy-regular.eot"
import fontGilroyRegularTtf from "../assets/fonts/gilroy-regular.ttf"
import fontGilroyMediumWoff from "../assets/fonts/gilroy-medium.woff"
import fontGilroyMediumWoff2 from "../assets/fonts/gilroy-medium.woff2"
import fontGilroyMediumEot from "../assets/fonts/gilroy-medium.eot"
import fontGilroyMediumTtf from "../assets/fonts/gilroy-medium.ttf"
import fontGilroySemiboldWoff from "../assets/fonts/gilroy-semibold.woff"
import fontGilroySemiboldEot from "../assets/fonts/gilroy-semibold.eot"
import fontGilroySemiboldTtf from "../assets/fonts/gilroy-semibold.ttf"
import { colors } from "./theme"

export const GlobalStyles = createGlobalStyle`
  /* Gilroy Regular */
  @font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 400;
    src: url("${fontGilroyRegularEot}");
    src: url("${fontGilroyRegularWoff}") format("woff"),
         url("${fontGilroyRegularTtf}") format("truetype");
  }
  /* Gilroy-Medium */
  @font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 600;
    src: url('${fontGilroyMediumEot}');
    src: url('${fontGilroyMediumWoff}') format("woff"),
         url('${fontGilroyMediumWoff2}') format("woff2"),
         url('${fontGilroyMediumTtf}') format("truetype");
  }
  /* Gilroy Semi Bold */
  @font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: bold;
    src: url("${fontGilroySemiboldEot}");
    src: url("${fontGilroySemiboldWoff}") format("woff"),
         url("${fontGilroySemiboldTtf}") format("truetype");
  }
  
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: ${colors.background};
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    color: ${colors.white};
  }
  
  ::-webkit-scrollbar {
    width: 18px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255,255,255, 0.5);
  }

  #app {
    width: 100%;
    position: relative;
  }
  
  .swal2-title {
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
    color: ${colors.gray};
  }
  .swal2-content {
    font-size: 20px;
    line-height: 24px;
    color: ${colors.gray};
  }
  .swal2-actions button {
    font-size: 20px;
    line-height: 24px;
    font-family: inherit;
    font-weight: bold;
  }

  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
  
  .hidden {
    display: none;
  }
  
  .vh-full {
    height: 100vh;
    height: 100dvh; // Mobile devices support.
  }
`
