import React, { useState, useCallback, useMemo, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { List, ChevronDown } from "react-ionicons"
import ReactTooltip from "react-tooltip"
import { Flex, FlexProps } from "rebass"
import { Button } from "../../components/Button"
import { Select, SelectOption } from "../../components/Select"
import { selectDevices } from "../../redux/deviceSlice"
import type { Device } from "../../enums"

type SelectPositionX = "left" | "right"
type SelectPositionY = "top" | "bottom"

type Props = FlexProps & {
  deviceType: MediaDeviceKind
  onDeviceChange: (device: Device) => void
  onDeviceConflict?: (device: Device) => void
  defaultDevice?: Device
  allowAutoChoose?: boolean
  iconSize?: number
  positionX?: SelectPositionX
  positionY?: SelectPositionY
}

export const DeviceSelect: React.FC<Props> = React.memo(
  ({
    deviceType,
    onDeviceChange,
    onDeviceConflict,
    defaultDevice,
    allowAutoChoose = true,
    positionX = "right",
    positionY = "top",
    iconSize = 30,
    ...props
  }) => {
    const [showDeviceSelect, setShowDeviceSelect] = useState(false)

    const isTriggered = useRef(false)
    const devices = useSelector(selectDevices)

    const deviceSelectOptions = useMemo(() => {
      return devices
        .filter((device) => device.kind === deviceType)
        .map(({ id, label }) => ({
          title: label,
          value: id,
        }))
    }, [devices, deviceType])

    const deviceToSelect = useMemo(() => {
      let device: Device | undefined
      if (defaultDevice) {
        // Try to pick the same exact device.
        device = devices.find(
          (d) => d.id === defaultDevice.id && d.label === defaultDevice.label && d.kind === defaultDevice.kind,
        )

        // Try to find based on label only if the above fails.
        if (!device) {
          const devicesByLabel = devices.filter((d) => d.label === defaultDevice.label && d.kind === defaultDevice.kind)
          device = devicesByLabel[defaultDevice.index] || devicesByLabel[0]
        }
      }

      if (!device && allowAutoChoose && devices.length) {
        // Pick the first available device if the above fails.
        device = devices.find(({ kind }) => kind === deviceType)
      }

      return device
    }, [defaultDevice, deviceType, devices, allowAutoChoose])

    const onDeviceSelect = useCallback(
      (option: SelectOption) => {
        const selectedDevice = devices.find((d) => d.id === option.value)
        if (selectedDevice) {
          onDeviceChange(selectedDevice)
        }
        setShowDeviceSelect(false)
      },
      [devices, onDeviceChange],
    )

    // If default device was provided but its id or label doesn't match with the selected device,
    // notify the parent about the possible conflict.
    useEffect(() => {
      if (defaultDevice && deviceToSelect && onDeviceConflict) {
        if (defaultDevice.id !== deviceToSelect.id || defaultDevice.label !== deviceToSelect.label) {
          onDeviceConflict(defaultDevice)
        }
      }
    }, [defaultDevice, deviceToSelect, onDeviceConflict])

    // Trigger onDeviceChange when deviceToSelect changes (e.g. when the selected device disconnects).
    useEffect(() => {
      if (defaultDevice && deviceToSelect) {
        if (defaultDevice.id !== deviceToSelect.id || defaultDevice.label !== deviceToSelect.label) {
          onDeviceChange(deviceToSelect)
        }
      }
    }, [defaultDevice, deviceToSelect, onDeviceChange])

    // NOTE: We use isTriggered flag to prevent onDeviceChange from being triggered every time the list of devices changes.
    //       So it gets triggered on mount only.
    useEffect(() => {
      if (deviceToSelect && !isTriggered.current) {
        onDeviceChange(deviceToSelect)
        isTriggered.current = true
      }
    }, [deviceToSelect, onDeviceChange])

    return (
      <Flex {...props}>
        <Button
          onClick={() => setShowDeviceSelect(true)}
          variant="warning"
          p="2px 6px"
          style={{ position: "relative" }}
          data-tip="Select device"
          data-for="media-device"
        >
          <List color="white" width={`${iconSize}px`} height={`${iconSize}px`} />
          <ChevronDown
            color="white"
            width={`${iconSize / 2}px`}
            height={`${iconSize / 2}px`}
            style={{
              position: "absolute",
              bottom: "-2px",
              right: "5px",
            }}
          />
        </Button>
        <ReactTooltip id="media-device" effect="solid" />
        {showDeviceSelect && (
          <Select
            options={deviceSelectOptions}
            selected={deviceToSelect?.id}
            onCheck={onDeviceSelect}
            onClose={() => setShowDeviceSelect(false)}
            width="320px"
            left={positionX === "right" ? "auto" : undefined}
            right={positionX === "left" ? "auto" : undefined}
            top={positionY === "top" ? "auto" : "50px"}
            bottom={positionY === "top" ? "45px" : "auto"}
            borderBottomLeftRadius={positionY === "bottom" ? "10px" : undefined}
            borderTopLeftRadius={positionY === "bottom" ? "0px" : undefined}
          />
        )}
      </Flex>
    )
  },
)
