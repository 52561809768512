import React, { useState } from "react"
import { Box, Flex } from "rebass"
import { Checkbox, Input, Label } from "@rebass/forms"
import { CloseOutline, AddOutline, CheckmarkOutline } from "react-ionicons"
import { useDispatch, useSelector } from "react-redux"
import { selectMediaSettings, setResolutions } from "../../redux/mediaSettingsSlice"
import { Button } from "../../components/Button"
import Swal from "sweetalert2"

import type { ResolutionSettings } from "../../libs/storage"

const newResolutionData = { width: 640, height: 480, title: "", allowed: false }

export const Resolutions: React.FC = () => {
  const [showResolutionForm, setShowResolutionForm] = useState(false)
  const [newResolution, setNewResolution] = useState(newResolutionData)

  const dispatch = useDispatch()
  const mediaSettings = useSelector(selectMediaSettings)

  const checkResolution = (resolution: ResolutionSettings) => {
    const updatedResolution = { ...resolution, allowed: !resolution.allowed }
    const updatedResolutions = mediaSettings.resolutions
      .filter((res) => res.title !== resolution.title)
      .concat(updatedResolution)
      .sort((a, b) => a.height - b.height)

    dispatch(setResolutions(updatedResolutions))
  }

  const addResolution = () => {
    if (!newResolution.width || !newResolution.height || !newResolution.title) {
      return
    }

    // Check if the resolution with the same title doesn't exist.
    const resolution = mediaSettings.resolutions.find((res) => res.title === newResolution.title)
    if (resolution) {
      Swal.fire("Please, use a unique name for the resolution.")
      return
    }

    dispatch(setResolutions(mediaSettings.resolutions.concat(newResolution).sort((a, b) => a.width - b.width)))
    setShowResolutionForm(false)
  }

  const deleteResolution = (resolution: ResolutionSettings) =>
    dispatch(setResolutions(mediaSettings.resolutions.filter((res) => res.title !== resolution.title)))

  return (
    <>
      <Flex p="20px 15px" alignItems="center">
        <Box width="320px">Allowed resolutions to choose</Box>
        <Box>
          {mediaSettings.resolutions.map((resolution, i) => (
            <Flex key={`resolution-${i}`} alignItems="center">
              <Flex>
                <Label alignItems="center">
                  <Checkbox
                    id={`resolution-${i}`}
                    checked={resolution.allowed}
                    onChange={() => checkResolution(resolution)}
                  />
                  {resolution.width}x{resolution.height} ({resolution.title})
                </Label>
                <CloseOutline
                  color="gray"
                  onClick={() => deleteResolution(resolution)}
                  style={{ marginTop: "3px", cursor: "pointer" }}
                />
              </Flex>
            </Flex>
          ))}
        </Box>
      </Flex>
      <Flex flexDirection="column">
        {showResolutionForm && (
          <Flex p="0 15px" alignItems="center">
            <Flex alignItems="center">
              Width:
              <Input
                ml="10px"
                mr="20px"
                width="80px"
                type="number"
                step={1}
                value={newResolution.width}
                onChange={(e) => setNewResolution({ ...newResolution, width: parseInt(e.target.value) })}
              />
            </Flex>
            <Flex alignItems="center">
              Height:
              <Input
                ml="10px"
                mr="20px"
                width="80px"
                type="number"
                step={1}
                value={newResolution.height}
                onChange={(e) => setNewResolution({ ...newResolution, height: parseInt(e.target.value) })}
              />
            </Flex>
            <Flex alignItems="center" flex={1}>
              Title:
              <Input
                ml="10px"
                type="text"
                step={1}
                value={newResolution.title}
                onChange={(e) => setNewResolution({ ...newResolution, title: e.target.value })}
              />
            </Flex>
          </Flex>
        )}

        <Flex justifyContent="flex-end" m="20px 15px 10px">
          {showResolutionForm && (
            <Button p="2px 6px" mr="10px" fontWeight="normal" variant="success" onClick={addResolution}>
              <CheckmarkOutline color="white" />
              Add
            </Button>
          )}

          <Button
            p="2px 6px"
            fontWeight="normal"
            variant={showResolutionForm ? "danger" : "primary"}
            onClick={() => setShowResolutionForm(!showResolutionForm)}
          >
            {showResolutionForm ? <CloseOutline color="white" /> : <AddOutline color="white" />}
            {showResolutionForm ? "Cancel" : "Add a Resolution"}
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
