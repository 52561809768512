import React, { useCallback } from "react"
import { Flex, Box } from "rebass"
import { Eye } from "react-ionicons"
import { skipToken } from "@reduxjs/toolkit/query"
import {
  useGetSettingsQuery,
  useGetParticipantsQuery,
  useSetRoomMuteStateMutation,
  useSetRoomAnnotationsStateMutation,
} from "../../libs/mediaServer"
import { Button } from "../../components/Button"
import { colors } from "../../etc/theme"

type Props = {
  isConnectionInitiated: boolean
}

export const AttendeesCount: React.FC<Props> = ({ isConnectionInitiated }) => {
  const { data: settings } = useGetSettingsQuery(isConnectionInitiated ? undefined : skipToken)
  const { data: connectedUsers = [] } = useGetParticipantsQuery(isConnectionInitiated ? undefined : skipToken)
  const [muteRoom] = useSetRoomMuteStateMutation()
  const [toggleAnnotations] = useSetRoomAnnotationsStateMutation()

  const onAnnotationsToggle = useCallback(() => {
    toggleAnnotations({ allowed: !settings?.isAnnotationsAllowed })
  }, [settings?.isAnnotationsAllowed])

  const onRoomMuteToggle = useCallback(() => {
    muteRoom({ muteState: !settings?.muted })
  }, [settings?.muted])

  return (
    <Flex p="30px" justifyContent="space-between" alignItems="center" fontSize="2">
      <Box opacity={connectedUsers.length > 0 ? 1 : 0.5}>
        <Eye color={colors.white} style={{ margin: "0 10px -4px 0" }} />
        {connectedUsers.length} Viewers
      </Box>
      <Button onClick={onAnnotationsToggle} disabled={!isConnectionInitiated} variant="primary" width="140px" py="8px">
        {settings?.isAnnotationsAllowed ? "Disable Annotations" : "Enable Annotations"}
      </Button>
      <Button
        onClick={onRoomMuteToggle}
        disabled={!isConnectionInitiated}
        variant="primary"
        width="140px"
        height="100%"
        py="8px"
      >
        {settings?.muted ? "Unmute Room" : "Mute Room"}
      </Button>
    </Flex>
  )
}
