import { Point } from "@influxdata/influxdb3-client"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import { addPoint } from "./api"
import type { MetricsCreateRecord } from "./model"

export const metricsApi = createApi({
  reducerPath: "metricsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (build) => ({
    createWebRTCOutboundMetrics: build.mutation<null, MetricsCreateRecord>({
      queryFn: async (record) => {
        const point = Point.measurement("stats-outbound")
          .setTag("eventId", record.eventId)
          .setTag("userId", record.userId)
          .setTag("trackId", record.trackId)
          .setTag("kind", record.metrics.kind)
          .setFloatField("bitrate", record.metrics.bitrate || 0)
          .setFloatField("availableOutgoingBitrate", record.metrics.availableOutgoingBitrate || 0)
          .setFloatField("bytesSent", record.metrics.bytesSent || 0)
          .setFloatField("retransmittedBytesSent", record.metrics.retransmittedBytesSent || 0)
          .setFloatField("firCount", record.metrics.firCount || 0)
          .setFloatField("nackCount", record.metrics.nackCount || 0)
          .setFloatField("pliCount", record.metrics.pliCount || 0)
          .setFloatField("frameHeight", record.metrics.frameHeight || 0)
          .setFloatField("frameWidth", record.metrics.frameWidth || 0)
          .setFloatField("framesEncoded", record.metrics.framesEncoded || 0)
          .setFloatField("framesPerSecond", record.metrics.framesPerSecond || 0)
          .setFloatField("keyFramesEncoded", record.metrics.keyFramesEncoded || 0)
          .setFloatField("headerBytesSent", record.metrics.headerBytesSent || 0)
          .setFloatField("totalEncodeTime", record.metrics.totalEncodeTime || 0)
          .setFloatField("totalPacketSendDelay", record.metrics.totalPacketSendDelay || 0)
          .setStringField("qualityLimitationReason", record.metrics.qualityLimitationReason || "")
          .setFloatField("qualityLimitationResolutionChanges", record.metrics.qualityLimitationResolutionChanges || 0)
          .setTimestamp(record.metrics.timestamp)

        await addPoint(point, record.token, record.bucket, "ms")

        return { data: null }
      },
    }),
  }),
})

export const { useCreateWebRTCOutboundMetricsMutation } = metricsApi
