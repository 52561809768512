import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Device } from "../enums"

const deviceSlice = createSlice({
  name: "device",
  initialState: [] as Device[],
  reducers: {
    setDevices: (_state, action: PayloadAction<Device[]>) => {
      return action.payload
    },
  },
})

export const { setDevices } = deviceSlice.actions
export const deviceReducer = deviceSlice.reducer
export const selectDevices = (state: { device: Device[] }): Device[] => state.device
