import React, { useState, useMemo, useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Flex, Text } from "rebass"
import { Add } from "react-ionicons"
import Swal from "sweetalert2"

import { Overlay } from "./Overlay"
import { selectDevices } from "../../../redux/deviceSlice"
import { selectMediaSettings, addOverlay } from "../../../redux/mediaSettingsSlice"
import { Select } from "../../../components/Select"
import { colors } from "../../../etc/theme"
import { deviceKinds } from "../../../enums"

import type { SelectOption } from "../../../components/Select"
import { useGetSettingsQuery } from "../../../libs/mediaServer"
import { skipToken } from "@reduxjs/toolkit/dist/query"

type Props = {
  isConnectionInitiated: boolean
}

export const Overlays: React.FC<Props> = ({ isConnectionInitiated }) => {
  const [showDeviceSelect, setShowDeviceSelect] = useState(false)

  const dispatch = useDispatch()
  const mediaSettings = useSelector(selectMediaSettings)
  const devices = useSelector(selectDevices)

  const { data: settings } = useGetSettingsQuery(isConnectionInitiated ? undefined : skipToken)

  const deviceOptions = devices
    .filter((device) => device.kind === deviceKinds.VIDEO_INPUT)
    .map(({ id, title }) => ({ value: id, title }))

  const overlays = useMemo(() => {
    // Consider only overlays set in localStorage and make sure the physical device exists.
    // NOTE: We don't remove nonexistent devices just in case they will be re-added.
    return Object.keys(mediaSettings.overlays)
      .filter((overlayId) => devices.find((d) => d.id.includes(mediaSettings.overlays[overlayId].device)))
      .map((overlayId) => {
        const overlay = mediaSettings.overlays[overlayId]
        const device = devices.find((d) => d.id === overlay.device)

        return {
          id: overlay.id,
          index: overlay.index,
          label: overlay.label,
          title: overlay.title,
          deviceId: device?.id as string, // Can't fail.
        }
      })
  }, [mediaSettings.overlays, devices])

  const onDeviceSelect = useCallback(
    async (option: SelectOption) => {
      setShowDeviceSelect(false)

      // Form default label.
      let index = 1
      while (index <= Object.keys(mediaSettings.overlays).length) {
        const overlay = Object.keys(mediaSettings.overlays).find((key) => mediaSettings.overlays[key].index === index)
        if (!overlay) {
          break
        }
        index++
      }

      const { value: label } = await Swal.fire({
        title: "Enter Overlay name",
        input: "text",
        inputValue: `Overlay ${index}`,
        inputAttributes: {
          maxlength: "24",
        },
        showCancelButton: true,
        confirmButtonColor: colors.primary,
        cancelButtonColor: colors.red,
      })

      if (label) {
        dispatch(addOverlay({ device: option.value, title: option.title, label }))
      }
    },
    [mediaSettings.overlays, dispatch],
  )

  useEffect(() => {
    const unmatchingOverlays: string[] = []
    overlays.forEach((overlay) => {
      const device = devices.find((d) => d.id === overlay.deviceId)
      if (device && overlay.title !== device.title) {
        unmatchingOverlays.push(overlay.label || "")
      }
    })

    if (unmatchingOverlays.length) {
      let text = "Seems like the following overlays might be swapped: "
      text += `${unmatchingOverlays.join(", ")}. `
      text += "Please, make sure you're using the correct devices."

      Swal.fire({
        icon: "warning",
        title: "Some of the overlays might be swapped",
        text,
      })
    }
  }, [overlays, devices])

  return (
    <Flex>
      {overlays.map((overlay) => (
        <Overlay
          key={overlay.id}
          isConnectionInitiated={isConnectionInitiated}
          isAnnotationsEnabled={settings?.isAnnotationsAllowed}
          {...overlay}
        />
      ))}

      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="311px"
        height="234px"
        mt="50px"
        css={{ position: "relative", cursor: "pointer", background: colors.black }}
        onClick={() => setShowDeviceSelect(true)}
      >
        <Add color={colors.white} />
        <Text>Add Overlay</Text>
        {showDeviceSelect && (
          <Select
            width="80%"
            bottom="50%"
            options={deviceOptions}
            onCheck={onDeviceSelect}
            onClose={() => setShowDeviceSelect(false)}
          />
        )}
      </Flex>
    </Flex>
  )
}
