export const deviceKinds = {
  VIDEO_INPUT: "videoinput",
  AUDIO_INPUT: "audioinput",
  AUDIO_OUTPUT: "audiooutput",
} as const

export type Device = {
  readonly id: string
  readonly label: string
  readonly kind: MediaDeviceKind
  readonly index: number // Indexes devices with the same label.
}
