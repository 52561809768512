import React, { useState, useEffect, useCallback, useRef } from "react"
import { useParams } from "react-router-dom"
import { Flex, Box, Text } from "rebass"
import { People } from "react-ionicons"
import { skipToken } from "@reduxjs/toolkit/query"
import {
  useGetParticipantsQuery,
  useGetSpeakingParticipantsQuery,
  useGetMediaTracksQuery,
  useGetConnectionStateQuery,
} from "../../../libs/mediaServer"
import { mediaKinds } from "../../../libs/mediaServer/enums"
import { colors } from "../../../etc/theme"
import { useGetEventQuery, useGetInstructorQuery, useListUsersQuery } from "../../../libs/api"
import { getEventMainInstructor, getEventAttendeesFromParticipants } from "../../../helpers"
import { Attendee } from "./Attendee"
import { AttendeeAudio } from "../AttendeeAudio"

import type { Attendee as EventAttendee } from "../../../libs/mediaServer/model"

type Props = {
  isConnectionInitiated: boolean
}

export const AttendeeList: React.FC<Props> = ({ isConnectionInitiated }) => {
  const [attendees, setAttendees] = useState<EventAttendee[]>([])
  const params = useParams()
  const eventId = params.eventId as string
  const isSpeakerErrorThrownAlready = useRef(false)

  // NOTE: We need polling, so when a new user is invited when event is started, it updates the user list.
  const { data: event } = useGetEventQuery(eventId, { pollingInterval: 15000 })
  const { data: instructor } = useGetInstructorQuery(event?.instructors?.length ? event.instructors[0] : skipToken)
  const { data: eventUsers } = useListUsersQuery(
    event?.users?.length ? { filter: { userIds: event.users.map(({ id }) => id) } } : skipToken,
  )
  const { data: connection } = useGetConnectionStateQuery(isConnectionInitiated ? undefined : skipToken)
  const { data: connectedUsers = [] } = useGetParticipantsQuery(isConnectionInitiated ? undefined : skipToken)
  const { data: speakingUsers = [] } = useGetSpeakingParticipantsQuery(isConnectionInitiated ? undefined : skipToken)
  const { data: mediaTrackRecords = [] } = useGetMediaTracksQuery(isConnectionInitiated ? undefined : skipToken)

  const isUserSpeaking = useCallback(
    (connectionId: string) => {
      return !!speakingUsers.find((user) => user.id === connectionId)
    },
    [speakingUsers],
  )

  useEffect(() => {
    if (!event || !eventUsers?.length || !connectedUsers.length || !connection?.id) {
      return
    }

    const eventInstructor = getEventMainInstructor(instructor, event.guestInstructors)
    const attendees = getEventAttendeesFromParticipants(eventInstructor, eventUsers, connectedUsers)
    setAttendees(attendees)
  }, [event, instructor, eventUsers, connectedUsers, connection?.id])

  return (
    <Box flex={1} minHeight="100px" css={{ overflowY: "auto" }}>
      {attendees.length ? (
        <Box p="0 12px 30px 30px">
          {attendees.map((attendee, i) => (
            <Attendee key={`attendee-${i}`} attendee={attendee} isSpeaking={isUserSpeaking(attendee.connectionId)} />
          ))}
        </Box>
      ) : (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" height="100%">
          <Text textAlign="center">
            <People width="50px" height="50px" color={colors.white} />
            <br />
            <span>No attendees</span>
          </Text>
        </Flex>
      )}

      {mediaTrackRecords
        .filter((record) => record.track.kind === mediaKinds.AUDIO)
        .map((record) => (
          <AttendeeAudio
            key={record.track.id}
            track={record.track}
            isSpeakerErrorThrownAlready={isSpeakerErrorThrownAlready}
          />
        ))}
    </Box>
  )
}
