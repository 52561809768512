import React, { useEffect } from "react"
import { useReplaceProducerTrackMutation, useSetTrackPlaybackStateMutation } from "../libs/mediaServer"

type Props = {
  id: string
  videoRef: React.RefObject<HTMLVideoElement>
  canvasRef: React.RefObject<HTMLCanvasElement>
  videoTrack?: MediaStreamTrack
  producerId?: string
  isPaused?: boolean
}

export const usePausedTrack = ({ id, videoRef, canvasRef, videoTrack, producerId, isPaused }: Props) => {
  const [setTrackPlaybackState] = useSetTrackPlaybackStateMutation()
  const [replaceProducerTrack] = useReplaceProducerTrackMutation()

  useEffect(() => {
    if (!canvasRef.current || videoRef.current?.readyState !== 4 || !videoTrack) {
      return
    }

    if (isPaused) {
      // NOTE: If the playback state is paused, we keep sending frames but still
      //       by creating canvas stream from the paused track.
      canvasRef.current.width = videoRef.current.videoWidth
      canvasRef.current.height = videoRef.current.videoHeight
      const ctx = canvasRef.current.getContext("2d") as CanvasRenderingContext2D
      const capturedStream = canvasRef.current.captureStream()
      ctx.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight)
      // Update paused frame with some interval, otherwise the stream gets empty.
      // TODO: Check if setting fps on captureStream(fps) allows us to get rid of the interval function.
      const canvasInterval = window.setInterval(() => {
        if (ctx && videoRef.current) {
          ctx.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight)
        }
      }, 1000)
      videoRef.current.pause()

      if (producerId) {
        replaceProducerTrack({ producerId, newTrack: capturedStream.getVideoTracks()[0] })
        setTrackPlaybackState({ trackId: id, isPlaying: false })
      }

      return () => {
        window.clearInterval(canvasInterval)
        capturedStream.getTracks().forEach((t: MediaStreamTrack) => t.stop())
      }
    } else {
      // Otherwise, replace the track back with the original video feed.
      videoRef.current.play()
      if (producerId) {
        replaceProducerTrack({ producerId, newTrack: videoTrack })
        setTrackPlaybackState({ trackId: id, isPlaying: true })
      }
    }
  }, [isPaused, producerId, videoTrack, setTrackPlaybackState, replaceProducerTrack])
}
