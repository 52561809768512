import React, { useState, useEffect, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { skipToken } from "@reduxjs/toolkit/query"
import { MicSensitivityControl } from "./MicSensitivityControl"
import { DeviceSelect } from "../DeviceSelect"
import { MicMuteControl } from "./MicMuteControl"
import { SliderWrapper } from "../SliderWrapper"
import { deviceKinds } from "../../../enums"
import logger from "../../../etc/logger"
import {
  useGetConnectionStateQuery,
  useProduceTrackMutation,
  useReplaceProducerTrackMutation,
  useSetUserMuteStateMutation,
} from "../../../libs/mediaServer"
import { connectionStates } from "../../../libs/mediaServer/enums"
import { showError } from "../../../helpers"
import { selectMediaSettings, setMicrophoneSettings } from "../../../redux/mediaSettingsSlice"
import { selectDevices } from "../../../redux/deviceSlice"

const defaultSensitivity = 60

type Props = {
  isConnectionInitiated: boolean
}

export const MicControl: React.FC<Props> = ({ isConnectionInitiated }) => {
  const [micTrack, setMicTrack] = useState<MediaStreamTrack>()
  const [sensitivity, setSensitivity] = useState(defaultSensitivity)

  const dispatch = useDispatch()
  const mediaSettings = useSelector(selectMediaSettings)
  const devices = useSelector(selectDevices)
  const micStream = useRef<MediaStream>()
  const audioContext = useRef<AudioContext>()
  const audioDest = useRef<MediaStreamAudioDestinationNode>()
  const gainNode = useRef<GainNode>()

  const { data: connection } = useGetConnectionStateQuery(isConnectionInitiated ? undefined : skipToken)
  const [produceTrack, { data: producerId, error: produceTrackError }] = useProduceTrackMutation()
  const [replaceTrack] = useReplaceProducerTrackMutation()
  const [muteAttendee] = useSetUserMuteStateMutation()

  const isConnected = connection?.state === connectionStates.CONNECTED

  const onMicMuteToggle = () => {
    if (sensitivity > 0) {
      setSensitivity(0)
    } else {
      setSensitivity(defaultSensitivity)
    }
  }

  const stopMicTrack = () => {
    // Stop previous stream tracks.
    micStream.current?.getAudioTracks().forEach((track) => track.stop())
    // Stop previous mic track.
    micTrack?.stop()
    // Stop gain nodes.
    gainNode.current?.disconnect()
    audioDest.current?.disconnect()
  }

  const onDeviceChange = useCallback(
    (deviceId: string) => {
      const device = devices.find((d) => d.id === deviceId)
      if (!device) {
        showError("Device Error", `Device "${deviceId}" wasn't found.`)
        return
      }

      const requestMicTrack = async (deviceId: string) => {
        if (!audioContext.current) {
          showError("AudioContext is not initialized.")
          return
        }

        try {
          micStream.current = await navigator.mediaDevices.getUserMedia({
            audio: { deviceId: { exact: deviceId } },
            video: false,
          })

          const micSource = audioContext.current.createMediaStreamSource(micStream.current)
          audioDest.current = audioContext.current.createMediaStreamDestination()
          gainNode.current = audioContext.current.createGain()
          micSource.connect(gainNode.current)
          gainNode.current.connect(audioDest.current)
          // Update sensitivity of the new track.
          gainNode.current.gain.value = (sensitivity / 100) * 2
          setMicTrack(audioDest.current.stream.getAudioTracks()[0])
          // Update the storage.
          dispatch(setMicrophoneSettings({ ...mediaSettings.microphone, device: deviceId }))
        } catch (e) {
          logger.error(e)
          showError("Media Error", `Unable to get media from ${device.title} device.`)
        }
      }

      if (micTrack) {
        // Stop previous mic track if there's one.
        stopMicTrack()
      }
      // Request a new one.
      requestMicTrack(deviceId)
    },
    [isConnected, sensitivity, devices, mediaSettings.microphone, replaceTrack, dispatch],
  )

  // Initialize Audio Context.
  useEffect(() => {
    audioContext.current = new AudioContext()

    return () => {
      stopMicTrack()
      audioContext.current?.close()
    }
  }, [])

  useEffect(() => {
    // Check system level mic sensitivity and apply if figured.
    const checkSystemSensitivity = async () => {
      try {
        const sensitivity = await window.electronAPI.audio.microphone.getSensitivity()
        setSensitivity(sensitivity)
      } catch (e) {
        logger.warn("Unable to get mic sensitivity.", e)
      }
    }

    checkSystemSensitivity()
  }, [])

  useEffect(() => {
    // Update system level mic sensitivity if possible, otherwise do it programmatically.
    const setMicSensitivity = async () => {
      try {
        await window.electronAPI.audio.microphone.setSensitivity(sensitivity)
      } catch (e) {
        // Setting system mic sensitivity is not supported, try to adjust programmatically.
        if (gainNode.current) {
          gainNode.current.gain.value = (sensitivity / 100) * 2
        }
      }
    }

    if (connection?.id) {
      muteAttendee({ peerId: connection.id, mute: sensitivity <= 0 })
    }

    setMicSensitivity()
  }, [sensitivity])

  useEffect(() => {
    if (isConnected && micTrack) {
      if (producerId) {
        // Send the new track to the media server.
        replaceTrack({ producerId, newTrack: micTrack })
      } else {
        produceTrack({
          transportName: mediaSettings.webrtcTransportNames.mic,
          track: micTrack,
          appData: { streamId: "mic" },
          stopTracks: false,
        }).then(() => {
          if (connection?.id) {
            muteAttendee({ peerId: connection.id, mute: sensitivity <= 0 })
          }
        })
      }
    }
    // NOTE: We skip producerId dependency to prevent extra call.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, micTrack])

  useEffect(() => {
    if (produceTrackError && "message" in produceTrackError) {
      showError("Unable to produce mic audio", produceTrackError.message)
    }
  }, [produceTrackError])

  return (
    <SliderWrapper>
      <MicSensitivityControl sensitivity={sensitivity} setSensitivity={setSensitivity} />
      <MicMuteControl muted={sensitivity === 0} onMicMuteToggle={onMicMuteToggle} />
      <DeviceSelect
        ml="auto"
        alignItems="center"
        fontSize="2"
        deviceType={deviceKinds.AUDIO_INPUT}
        defaultDevice={mediaSettings.microphone.device}
        onDeviceChange={onDeviceChange}
        positionY="bottom"
      />
    </SliderWrapper>
  )
}
