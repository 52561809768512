import React, { useMemo } from "react"
import { Flex, Image, Text } from "rebass"
import { VolumeHigh, Mic, MicOff, LockClosed } from "react-ionicons"
import { useSetUserMuteStateMutation } from "../../../libs/mediaServer"
import { colors } from "../../../etc/theme"
import noAvatarPath from "../images/no-avatar.png"

import type { Attendee as EventAttendee } from "../../../libs/mediaServer/model"
import styled from "styled-components"

type Props = {
  attendee: EventAttendee
  isSpeaking?: boolean
}

const MicLockIcon = styled(LockClosed)`
  position: absolute;
  bottom: 0;
  left: 15px;
`

export const Attendee: React.FC<Props> = ({ attendee, isSpeaking = false }) => {
  const [muteAttendee] = useSetUserMuteStateMutation()

  const attendeeName = useMemo(() => {
    let name = ""
    if (attendee) {
      name = `${attendee.name.first} ${attendee.name.last}`
      if (attendee.name.suffix) {
        name = `${name}, ${attendee.name.suffix}`
      }
    }
    return name
  }, [attendee])

  const onUserMuteToggle = () => {
    muteAttendee({ peerId: attendee.connectionId, mute: !attendee.muteLockedByModerator })
  }

  return (
    <Flex
      alignItems="center"
      fontSize="2"
      p="10px 12px"
      css={{
        borderTop: "2px solid rgba(255, 255, 255, 0.15)",
        cursor: "default",
      }}
    >
      <Image src={attendee.photo || noAvatarPath} variant="avatar" mr="22px" opacity={0.8} />
      <Text opacity={0.8}>{attendeeName}</Text>
      {isSpeaking && <VolumeHigh width="20px" height="20px" color={colors.green} style={{ margin: "5px 0 0 15px" }} />}

      <Flex ml="auto" style={{ position: "relative" }}>
        {attendee.muted ? (
          <>
            <MicOff color={colors.red} onClick={onUserMuteToggle} />
            {attendee.muteLockedByModerator && <MicLockIcon width="12px" height="12px" color={colors.gray} />}
          </>
        ) : (
          <Mic color={colors.white} onClick={onUserMuteToggle} />
        )}
      </Flex>
    </Flex>
  )
}
