import React, { useState } from "react"
import { ReloadOutline } from "react-ionicons"
import { colors } from "../etc/theme"
import { Box, BoxProps } from "rebass"
import Swal from "sweetalert2"

type Props = BoxProps & {
  askBeforeReload?: boolean
}

export const ReloadButton: React.FC<Props> = ({ width = "24px", height = "24px", askBeforeReload, ...props }) => {
  const [isReloading, setIsReloading] = useState(false)

  const onReload = () => {
    setIsReloading(true)

    if (askBeforeReload) {
      Swal.fire({
        title: "Are you sure?",
        text: "If the stream is live, participants will lose the feeds.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: colors.blue,
        cancelButtonColor: colors.red,
        confirmButtonText: "Reload",
      }).then(async (result) => {
        if (result.value) {
          window.location.reload()
        } else {
          setIsReloading(false)
        }
      })
    } else {
      window.location.reload()
    }
  }

  return (
    <Box sx={{ cursor: "pointer" }} onClick={onReload} {...props}>
      <ReloadOutline width={width} height={height} color={colors.white} rotate={isReloading} />
    </Box>
  )
}
