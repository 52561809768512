export const codecs = {
  VP8: "VP8",
  VP9: "VP9",
} as const

export const mediaKinds = {
  AUDIO: "audio",
  VIDEO: "video",
} as const

export const mediaReportTypes = {
  INBOUND_RTP: "inbound-rtp",
  OUTBOUND_RTP: "outbound-rtp",
  MEDIA_SOURCE: "media-source",
  TRACK: "track",
  ICE_CANDIDATE_LOCAL: "local-candidate",
  ICE_CANDIDATE_REMOTE: "remote-candidate",
  CANDIDATE_PAIR: "candidate-pair",
}

export const connectionStates = {
  UNINITIATED: "uninitiated",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  CONNECTING: "connecting",
  RECONNECTING: "reconnecting",
  ERRORED: "errored",
} as const

// Possible socket io disconnection reasons.
// https://socket.io/docs/v4/client-api/#event-disconnect
export const socketDisconnectionReasons = {
  SERVER_DISCONNECT: "io server disconnect",
  CLIENT_DISCONNECT: "io client disconnect",
  PING_TIMEOUT: "ping timeout",
  TRANSPORT_CLOSE: "transport close",
  TRANSPORT_ERROR: "transport error",
} as const

export const transportTypes = {
  SEND: "send",
  RECEIVE: "receive",
  DATA_PRODUCE: "data-produce",
  DATA_CONSUME: "data-consume",
} as const

export const mediaDataTypes = {
  ANNOTATIONS: "annotations",
} as const

export const clientTypes = {
  EVENTS: "EVENTS",
  IUC: "IUC",
  MS: "MS",
  MWV: "MWV",
} as const
