import { api as generatedApi } from "./generated"
import * as mappings from "./mappings"

import type * as model from "./model"

export * from "./enums"
export type { model }

// Generic function applying the given 'trans' to each element.
function transformListResponse<InT, OutT>(
  trans: (elem: InT) => OutT,
): (baseValue: { data?: readonly InT[] }) => readonly OutT[] {
  return ({ data }) => (data ?? []).map(trans)
}

export const api = generatedApi
  .enhanceEndpoints({
    addTagTypes: ["Event", "User", "Metrics"],
  })
  .injectEndpoints({
    overrideExisting: true,
    endpoints: (build) => ({
      // User.
      getUser: build.query<model.NormalizedUser | undefined, model.generated.FirebaseUserId>({
        query: (id) => ({
          url: `/v0/users/${id}`,
        }),
        transformResponse: (baseValue: model.generated.GetUserApiResponse) =>
          baseValue.data ? mappings.normalizeUser(baseValue.data) : undefined,
      }),

      // Instructor.
      getInstructor: build.query<model.NormalizedInstructor | undefined, string>({
        query: (id) => ({ url: `/v0/instructors/${id}` }),
        transformResponse: (baseValue: model.generated.GetUserApiResponse) =>
          baseValue.data ? mappings.normalizeInstructor(baseValue.data) : undefined,
      }),

      // Events.
      getEvent: build.query<model.NormalizedEvent | undefined, string>({
        query: (id) => ({ url: `/v0/events/${id}` }),
        providesTags: (result) => (result ? [{ type: "Event", id: result.id }, "Event"] : ["Event"]),
        transformResponse: (baseValue: model.generated.GetEventApiResponse): model.NormalizedEvent | undefined => {
          return baseValue.data ? mappings.normalizeEvent(baseValue.data) : undefined
        },
      }),

      getMetricsToken: build.query<model.generated.InfluxDbMetricsToken | undefined, void>({
        query: () => ({ url: `/v0/metrics/token` }),
        providesTags: [{ type: "Metrics", id: "TOKEN" }],
        transformResponse: (baseValue: model.generated.GetMetricsTokenResponse) => {
          return baseValue.data
        },
      }),

      listUsers: build.query<model.NormalizedUser[], model.ListUsersApiArg | null>({
        query: (queryArg) => ({
          url: "/v0/users",
          params: {
            filter: mappings.mapUserFilter(queryArg?.filter),
          },
        }),
        providesTags: (result) =>
          result ? [...result.map(({ id }) => ({ type: "User" as const, id })), "User"] : ["User"],
        transformResponse: (baseValue: model.generated.ListUsersApiResponse): model.NormalizedUser[] => {
          return transformListResponse(mappings.normalizeUser)(baseValue) as model.NormalizedUser[]
        },
      }),

      listEvents: build.query<model.NormalizedEvent[], model.ListEventsApiArg | null>({
        query: (queryArg) => ({
          url: "/v0/events",
          params: {
            filter: mappings.mapEventFilter(queryArg?.filter),
          },
        }),
        providesTags: (result) =>
          result ? [...result.map(({ id }) => ({ type: "Event" as const, id })), "Event"] : ["Event"],
        transformResponse: (baseValue: model.generated.ListEventsApiResponse): model.NormalizedEvent[] => {
          return transformListResponse(mappings.normalizeEvent)(baseValue) as model.NormalizedEvent[]
        },
      }),

      updateEventStatus: build.mutation<model.generated.UpdateEventStatusApiResponse, model.UpdateEventStatusApiArg>({
        query: (queryArg) => ({
          url: `/v0/events/${queryArg.id}/status`,
          method: "POST",
          body: { status: queryArg.status },
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
      }),

      updateEventSettings: build.mutation<
        model.generated.UpdateEventSettingsApiResponse,
        model.UpdateEventSettingsApiArg
      >({
        query: ({ id, settings }) => ({
          url: `/v0/events/${id}/settings`,
          method: "POST",
          body: {
            stream_settings: { media_server_url: settings.mediaServerUrl },
          },
        }),
        invalidatesTags: (result, error, arg) => [{ type: "Event", id: arg.id }],
      }),

      deleteMetricsToken: build.mutation<{ success: boolean; message?: string }, string>({
        invalidatesTags: [{ type: "Metrics", id: "TOKEN" }],
        query: (id) => ({
          url: `/v0/metrics/token/${id}`,
          method: "DELETE",
        }),
      }),
    }),
  })
  // TODO: Check why we have TypeScript errors without the additional enhanceEndpoints.
  .enhanceEndpoints({})

export const {
  useGetUserQuery,
  useGetInstructorQuery,
  useGetEventQuery,
  useGetMetricsTokenQuery,
  useListUsersQuery,
  useListEventsQuery,
  useUpdateEventStatusMutation,
  useUpdateEventSettingsMutation,
  useDeleteMetricsTokenMutation,
} = api
