import { io, Socket } from "socket.io-client"
import { closeTransports } from "./utils"
import { socketDisconnectionReasons } from "./enums"
import { getToken } from "../../etc/firebase"
import logger from "../../etc/logger"

import { SocketError } from "./SocketError"

const RECONNECT_TIMEOUT = 5000 // 5 sec.
const MAX_ATTEMPTS_TO_RECONNECT = 10
let reconnectionAttempts = 0
let socket: Socket | null
let reconnectTimeout: NodeJS.Timeout

// Whether the disconnect was intentional by the user or media server or due to connection issues.
export const isIntentionalDisconnect = (reason: Socket.DisconnectReason): boolean =>
  reason === socketDisconnectionReasons.CLIENT_DISCONNECT || reason === socketDisconnectionReasons.SERVER_DISCONNECT

// Whether to allow the user to reconnect or not.
export const isAllowedToReconnect = (reason: Socket.DisconnectReason, error?: SocketError): boolean => {
  let isFatal = false
  // If data.code exists in the Error object, it means the error comes from the media server middleware and is fatal.
  if ((error?.data?.code || 200) >= 300) {
    isFatal = true
  }

  return (
    (reason === socketDisconnectionReasons.PING_TIMEOUT ||
      reason === socketDisconnectionReasons.TRANSPORT_CLOSE ||
      reason === socketDisconnectionReasons.TRANSPORT_ERROR) &&
    socket !== null &&
    !isFatal &&
    reconnectionAttempts <= MAX_ATTEMPTS_TO_RECONNECT
  )
}

// Returns the last connection to the socket server or initiates a new one.
export const initConnection = (url: string): Socket => {
  if (!socket || url !== `https://${socket.io.opts.hostname}`) {
    socket = io(url, {
      autoConnect: false,
      reconnection: false,
      extraHeaders: {
        'X-Client-Id': 'MS',
      },
    })

    // Respond immediately to the ping message from the media server.
    // Needed to assure media server that we're still connected (manual check on demand).
    socket.on("connection-check", (cb) => cb())

    socket.on("connect_error", (err) => {
      logger.error("Connection error.", err)
      reconnectionAttempts++

      if (isAllowedToReconnect(socketDisconnectionReasons.TRANSPORT_ERROR, err)) {
        logger.debug(`Reconnecting (${reconnectionAttempts}/${MAX_ATTEMPTS_TO_RECONNECT})...`)
        // Try to reconnect after a few seconds.
        reconnectTimeout = setTimeout(() => connect(), RECONNECT_TIMEOUT)
      } else {
        reconnectionAttempts = 0
      }
    })

    socket.on("disconnect", (reason) => {
      logger.debug(`Disconnected from the media server (${reason}).`)
      // Clear media transports on each disconnection, so they can be safely re-created later.
      closeTransports(socket as Socket)

      if (isAllowedToReconnect(reason)) {
        logger.warn("Unexpected disconnect from the media server. Reconnecting...")
        // NOTE: The consecutive failure(s) will be handled with "connect_error" event.
        //       The disconnect event is emitted only after successful connection, so clear up the counter.
        reconnectionAttempts = 0
        connect()
      }
    })
  }

  return socket
}

// Returns the last connection to the socket server.
export const getConnection = (): Socket => {
  if (!socket) {
    throw new Error("Connection is not initialized.")
  }
  return socket
}

export const connect = (): void => {
  if (!socket) {
    throw new Error("Connection is not initialized.")
  }
  if (socket.connected) {
    logger.warn("Already connected to the media server.")
    return
  }

  // Make sure we use an up-to-date token.
  socket.auth = async (cb) => cb({ token: await getToken() })
  socket.connect()
}

export const disconnect = (): void => {
  clearTimeout(reconnectTimeout)

  if (!socket) {
    logger.warn("Connection is not initialized.")
  } else if (socket.disconnected) {
    logger.warn("Connection already closed.")
  } else {
    socket.disconnect()
  }

  socket = null
}
