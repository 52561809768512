import styled from "styled-components"
import { Flex } from "rebass"
import theme, { colors } from "../../etc/theme"

export const SliderWrapper = styled(Flex)`
  height: 55px;
  position: relative;
  margin-top: 20px;
  padding: 0 20px;
  border-radius: 4px;
  background: ${colors.white};
  color: ${colors.dark};
  box-shadow: ${theme.shadows.buttonOutside};
`
