// TODO: Set proper types.
// Merges array or object deeply.
export const merge = (target: any, ...sources: any): any => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        merge(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return merge(target, ...sources)
}

// Checks if item is an object.
export const isObject = (item: any): boolean => {
  return item && typeof item === "object" && !Array.isArray(item)
}

// Checks if there's a specific API available (accessible when running the app in a native context only).
export const isNativeContext = !!window.electronAPI
