import { initializeApp } from "firebase/app"
import { getAuth, connectAuthEmulator } from "firebase/auth"
import firebase from "firebase/compat"
import config from "./config"

// Reexport useful stuff.
export type FirebaseError = firebase.FirebaseError

const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  projectId: config.firebase.projectId,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.dbUrl,
}

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)

export const getToken = (forceUpdate = false): Promise<string> => {
  if (!auth.currentUser) {
    return Promise.resolve("")
  }
  return auth.currentUser.getIdToken(forceUpdate)
}

if (config.firebase.emulatorUrl) {
  connectAuthEmulator(auth, config.firebase.emulatorUrl)
}
