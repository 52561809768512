import { codecs, connectionStates, transportTypes, mediaDataTypes } from "./enums"
import { NormalizedUser } from "../api/model"

import type { ProducerOptions } from "mediasoup-client/lib/Producer"
import type { ClientType } from "../api/generated"

export type Codec = (typeof codecs)[keyof typeof codecs]
export type TransportType = (typeof transportTypes)[keyof typeof transportTypes]

export type MediaConsumerRecord = {
  track: MediaStreamTrack
  peerId: string // Track owner connection ID.
  appData?: any
}

export type TrackProducerParams = ProducerOptions & {
  transportName: string
}

// Server peer.
export type Peer = {
  id: string // User connection ID.
  uid: string // User real ID.
  client: PeerClient
  muted?: boolean
  muteLockedByModerator?: boolean
}

export type PeerClient = {
  type: ClientType
  device?: {
    type?: string
    model?: string
    vendor?: string
  }
  browser?: {
    name?: string
    version?: string
  }
  os?: {
    name?: string
    version?: string
  }
  cpu?: string
}

export type Settings = {
  isAnnotationsAllowed: boolean
  isUserListHidden: boolean
  isRecording: boolean
  muted: boolean
  gatherMediaStats: {
    vr: boolean
    overlays: boolean
  }
}

export enum MessageKind {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  CHAT = "chat",
}

export type ChatMessage = {
  id: string
  kind: MessageKind.CHAT
  message: string
  createdAt: number
  updatedAt: number
  sender: {
    id: string
    uid: string
    client: PeerClient
  }
  receiverIds?: string[]
}

export type SystemMessage = {
  id: string
  kind: MessageKind.INFO | MessageKind.WARNING | MessageKind.ERROR
  message: string
  appData?: Record<string, unknown>
}

export type Message = SystemMessage | ChatMessage

export enum UserActionKind {
  LEAVE = "leave",
  MEDIA_STATS_VR_TOGGLE = "media-stats-vr-toggle",
  MEDIA_STATS_OVERLAYS_TOGGLE = "media-stats-overlays-toggle",
  RECORDING_TOGGLE = "recording-toggle",
  NPS_SHOW = "NPS-show",
  NPS_HIDE = "NPS-hide",
}

export type UserAction = {
  kind: UserActionKind
  peerIds?: string[]
  appData?: Record<string, unknown>
}

export type Attendee = NormalizedUser & {
  connectionId: string
  client: PeerClient
  muted?: boolean
  muteLockedByModerator?: boolean
}

export type ConnectionState = {
  state: (typeof connectionStates)[keyof typeof connectionStates]
  id?: string
  error?: Error
}

export type PointCoordinates = {
  x: number
  y: number
}

export type AnnotationObjectSettings = {
  id: string
  width: number
  height: number
}

export type AnnotationData = {
  points: PointCoordinates[]
  color: string
  width: number
}

export type Annotation = {
  type: (typeof mediaDataTypes)[keyof typeof mediaDataTypes]
  object: AnnotationObjectSettings
  data: AnnotationData
  userId: string
  createdAt: string
  clearIn?: number
  concludesElement?: boolean
}

type MediaStatsOutbound = RTCOutboundRtpStreamStats & {
  bitrate: number
  availableOutgoingBitrate?: number
  qualityLimitationDurations?: number
  qualityLimitationReason?: string
}

export type ProducerStats = {
  [key: string]: MediaStatsOutbound
}

export type ConsumerStats = {
  framesDecoded: number
  keyFramesDecoded: number
  totalDecodeTime: number
  totalInterFrameDelay: number
  jitter: number
}
