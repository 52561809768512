import React from "react"
import { Flex, FlexProps } from "rebass"
import { Label, Select } from "@rebass/forms"

import type { SelectOption } from "./Select"

type Props = FlexProps & {
  label: string
  value: string
  emptyValue?: string
  options: SelectOption[]
  multiple?: boolean
  onSelectChange: (e: React.FormEvent<HTMLSelectElement>) => void
}

export const SelectGroup: React.FC<Props> = ({
  label,
  value,
  emptyValue,
  onSelectChange,
  options,
  multiple,
  ...styles
}) => {
  return (
    <Flex {...styles}>
      <Label width="auto">{label}</Label>
      <Select value={value} onChange={onSelectChange} multiple={!!multiple} css={{ minWidth: "60px" }}>
        {emptyValue && <option value="">{emptyValue}</option>}
        {options.map((option, key) => (
          <option key={key} value={option.value}>
            {option.title}
          </option>
        ))}
      </Select>
    </Flex>
  )
}
