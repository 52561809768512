import React, { useRef, useEffect, useMemo, MutableRefObject } from "react"
import { useSelector } from "react-redux"
import { selectMediaSettings } from "../../redux/mediaSettingsSlice"
import { selectDevices } from "../../redux/deviceSlice"
import { showError } from "../../helpers"

type Props = {
  track: MediaStreamTrack
  isSpeakerErrorThrownAlready: MutableRefObject<boolean>
}

export const AttendeeAudio: React.FC<Props> = ({ track, isSpeakerErrorThrownAlready }) => {
  const mediaSettings = useSelector(selectMediaSettings)
  const devices = useSelector(selectDevices)
  const audioEl = useRef<HTMLAudioElement & { setSinkId(deviceId: string): void }>(null)

  const speakerLevel = mediaSettings.speaker.level

  const speakerDeviceId = useMemo(() => {
    const deviceId = devices.find((d) => d.id === mediaSettings.speaker.device)?.id
    if (!deviceId && !isSpeakerErrorThrownAlready.current) {
      isSpeakerErrorThrownAlready.current = true
      showError("Audio Device Error", `Device "${mediaSettings.speaker.device}" wasn't found.`)
    }
    return deviceId
  }, [mediaSettings.speaker.device, devices.length])

  useEffect(() => {
    if (speakerDeviceId && track && audioEl.current) {
      audioEl.current.srcObject = new MediaStream([track])
      audioEl.current.setSinkId(speakerDeviceId)
    }
  }, [speakerDeviceId, track])

  useEffect(() => {
    if (!audioEl.current) {
      return
    }

    audioEl.current.volume = speakerLevel / 100
  }, [speakerLevel])

  return <audio ref={audioEl} autoPlay />
}
