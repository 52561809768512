import React from "react"
import { Flex } from "rebass"
import { useGetMessagesQuery, useRemoveMessageMutation } from "../../libs/mediaServer"
import { Alert } from "../../components/Alert"
import { MessageKind } from "../../libs/mediaServer/model"
import styled from "styled-components"

const Wrapper = styled(Flex)`
  position: fixed;
  right: 20px;
  bottom: 0;
  flex-direction: column-reverse;
`

export const Errors: React.FC = () => {
  const { data: messages } = useGetMessagesQuery()
  const [removeMessage] = useRemoveMessageMutation()

  const onClose = (id: string) => {
    removeMessage(id)
  }

  return (
    <Wrapper>
      {messages
        ?.filter(({ kind }) => kind !== MessageKind.CHAT)
        // Don't show error messages.
        // NOTE: It has been decided after numerous complains.
        .filter(({ kind }) => kind !== MessageKind.ERROR)
        .map(({ id, kind, message }) => (
          <Alert
            key={id}
            warning={kind === MessageKind.WARNING}
            error={kind === MessageKind.ERROR}
            onClose={() => onClose(id)}
          >
            {message}
          </Alert>
        ))}
    </Wrapper>
  )
}
