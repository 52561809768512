import React from "react"
import ReactTooltip from "react-tooltip"
import { VolumeHigh, VolumeMute } from "react-ionicons"
import { Flex } from "rebass"
import { Button } from "../../../components/Button"

type Props = {
  muted: boolean
  onMuteToggle: () => void
}

export const SpeakerMuteControl: React.FC<Props> = ({ muted, onMuteToggle }) => {
  return (
    <>
      <Flex ml="auto" alignItems="center" justifyContent="center">
        <Button
          onClick={onMuteToggle}
          variant={muted ? "danger" : "success"}
          width="50px"
          p="2px 0"
          data-tip={muted ? "Speaker is muted, tap to unmute" : "Mute speaker"}
          data-for="speaker"
        >
          {muted ? (
            <VolumeMute color="white" width="30px" height="30px" />
          ) : (
            <VolumeHigh color="white" width="30px" height="30px" />
          )}
        </Button>
      </Flex>
      <ReactTooltip id="speaker" effect="solid" />
    </>
  )
}
