import { mediaReportTypes } from "./enums"
import type { ProducerStats, ConsumerStats } from "./model"

export const buildFromProducerStats = (stats: RTCStatsReport, prevStats: RTCStatsReport) => {
  const calculatedStats: ProducerStats = {}

  // Calculates bitrate based on current and previous reports' total bytes sent.
  const calculateBitrate = (report: any) => {
    let bitrate = 0
    if (prevStats && prevStats.has(report.id)) {
      const prevReport = prevStats.get(report.id)
      bitrate = (8 * (report.bytesSent - prevReport.bytesSent)) / ((report.timestamp - prevReport.timestamp) / 1000)
    }

    return Math.trunc(bitrate)
  }

  // NOTE: FPS and frame size are under different reports on older versions of Chromium (e.g. 83).
  //       So, the below is just to support older versions.
  let fps = 0
  let frameHeight = 0
  let frameWidth = 0
  let availableOutgoingBitrate = 0

  stats.forEach((report) => {
    if (report.type === mediaReportTypes.OUTBOUND_RTP) {
      calculatedStats[report.id] = {
        ...report,
        bitrate: calculateBitrate(report),
      }
    }

    if (report.type === mediaReportTypes.CANDIDATE_PAIR) {
      availableOutgoingBitrate = report.availableOutgoingBitrate
    }

    // NOTE: The below is just to support legacy Chromium versions.
    if (report.type === mediaReportTypes.MEDIA_SOURCE) {
      fps = report.framesPerSecond
    }
    if (report.type === mediaReportTypes.TRACK) {
      frameHeight = report.frameHeight
      frameWidth = report.frameWidth
    }
  })

  // NOTE: The below is just to support legacy Chromium versions.
  Object.keys(calculatedStats).forEach((reportId) => {
    if (!calculatedStats[reportId].framesPerSecond) {
      calculatedStats[reportId].framesPerSecond = fps
    }
    if (!calculatedStats[reportId].frameHeight || !calculatedStats[reportId].frameWidth) {
      calculatedStats[reportId].frameHeight = frameHeight
      calculatedStats[reportId].frameWidth = frameWidth
    }
    calculatedStats[reportId].availableOutgoingBitrate = availableOutgoingBitrate
  })

  return calculatedStats
}

export const buildFromConsumerStats = (stats: RTCStatsReport) => {
  let calculatedStats: ConsumerStats | undefined

  stats.forEach((report) => {
    if (report.type === mediaReportTypes.INBOUND_RTP) {
      calculatedStats = {
        framesDecoded: report.framesDecoded,
        keyFramesDecoded: report.keyFramesDecoded,
        totalDecodeTime: report.totalDecodeTime,
        totalInterFrameDelay: report.totalInterFrameDelay,
        jitter: report.jitter,
      }
    }
  })

  return calculatedStats
}
