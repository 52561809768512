import { dataProducers } from "../libs/mediaServer"
import type { Annotation, AnnotationObjectSettings, PointCoordinates } from "../libs/mediaServer/model"
import { mediaDataTypes } from "../libs/mediaServer/enums"

// Generates a random color (light only).
export const generateRandomColor = () => {
  const r = Math.floor(Math.random() * 56) + 200
  const g = Math.floor(Math.random() * 56) + 200
  const b = Math.floor(Math.random() * 56) + 200

  return `rgb(${r},${g},${b})`
}

export const formAnnotationData = (
  parentObjectSettings: AnnotationObjectSettings,
  points: PointCoordinates[],
  userId: string,
  color: string,
  width: number,
  clearInterval: number,
  concludesElement?: boolean,
) => ({
  object: parentObjectSettings,
  data: {
    points,
    color,
    width,
  },
  type: mediaDataTypes.ANNOTATIONS,
  createdAt: new Date().toISOString(),
  clearIn: clearInterval,
  userId,
  concludesElement,
})

// Sends annotation to the media server.
export const sendAnnotation = (data: Annotation) => {
  if (dataProducers[mediaDataTypes.ANNOTATIONS]?.readyState === "open") {
    dataProducers[mediaDataTypes.ANNOTATIONS].send(JSON.stringify(data))
  }
}
