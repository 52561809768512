import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import config from "../etc/config"
import { codecs } from "../libs/mediaServer/enums"
import { get, storageKeys } from "../libs/storage"
import { merge } from "../helpers"
import logger from "../etc/logger"

import type {
  GatherMediaStatsSettings,
  MediaSettings,
  VRSettings,
  MicrophoneSettings,
  SpeakerSettings,
  OverlaySettings,
  ResolutionSettings,
  BitratesSettings,
  SimulcastSVCSettings,
  WebRTCTransportNames,
} from "../libs/storage"
import type { Device } from "../enums"

const defaultValues: MediaSettings = {
  vr: {
    device: undefined,
    resolution: config.media.resolutions[0],
    hShift: 0,
    vShift: 0,
    fov: 180,
    swap: false,
  },
  overlays: {},
  microphone: { device: undefined },
  speaker: {
    device: undefined,
    level: 100,
  },
  // Max bitrates.
  bitrates: {
    vr: 0,
    overlays: 0,
  },
  simulcastSvc: {
    codec: codecs.VP8,
    vr: false,
    overlays: false,
  },
  gatherMediaStats: {
    vr: false,
    overlays: false,
  },
  // Set different names if you want the media to be sent through different transports (WebRTC PeerConnections).
  webrtcTransportNames: {
    vr: "vr",
    overlays: "overlays",
    mic: "mic",
  },
  // Default possible resolutions to choose from.
  resolutions: config.media.resolutions,
}

// Merge default values with the values in the local storage.
const localSettings = get(storageKeys.MEDIA)
const initialState = merge({}, defaultValues, localSettings)

const mediaSettingsSlice = createSlice({
  name: storageKeys.MEDIA,
  initialState,
  reducers: {
    updateVRSettings: (state, { payload }: PayloadAction<Partial<VRSettings>>) => {
      state.vr = { ...state.vr, ...payload }
    },
    updateMicrophoneSettings: (state, { payload }: PayloadAction<MicrophoneSettings>) => {
      state.microphone = { ...state.microphone, ...payload }
    },
    updateSpeakerSettings: (state, { payload }: PayloadAction<Partial<SpeakerSettings>>) => {
      state.speaker = { ...state.speaker, ...payload }
    },
    addOverlay: (state, { payload: { device, label } }: PayloadAction<{ device: Device; label: string }>) => {
      // TODO: Replace with unique ids.
      const overlayId = `${Math.trunc(Date.now() / 1000)}`
      let index = 1
      while (index <= Object.keys(state.overlays).length) {
        const overlay = Object.keys(state.overlays).find((key) => state.overlays[key].index === index)
        if (!overlay) {
          break
        }
        index++
      }

      state.overlays[overlayId] = {
        index,
        device,
        label,
        id: overlayId,
        resolution: config.media.resolutions[0],
      }
    },
    removeOverlay: (state, { payload: id }: PayloadAction<string>) => {
      if (!state.overlays[id]) {
        logger.warn("No such overlay:", id)
        return
      }
      delete state.overlays[id]
    },
    updateOverlay: (
      state,
      { payload: { id, settings } }: PayloadAction<{ id: string; settings: Partial<OverlaySettings> }>,
    ) => {
      if (!state.overlays[id]) {
        logger.warn("No such overlay:", id)
        return
      }
      state.overlays[id] = { ...state.overlays[id], ...settings }
    },
    setResolutions: (state, { payload }: PayloadAction<ResolutionSettings[]>) => {
      state.resolutions = payload
    },
    setBitrates: (state, { payload }: PayloadAction<BitratesSettings>) => {
      state.bitrates = payload
    },
    setSimulcastSvc: (state, { payload }: PayloadAction<SimulcastSVCSettings>) => {
      state.simulcastSvc = payload
    },
    setGatherMediaStats: (state, { payload }: PayloadAction<GatherMediaStatsSettings>) => {
      state.gatherMediaStats = payload
    },
    setWebRTCTransportNames: (state, { payload }: PayloadAction<WebRTCTransportNames>) => {
      state.webrtcTransportNames = payload
    },
  },
})

export const mediaSettingsReducer = mediaSettingsSlice.reducer
export const selectMediaSettings = (state: { mediaSettings: MediaSettings }): MediaSettings => state.mediaSettings
export const {
  updateVRSettings,
  updateMicrophoneSettings,
  updateSpeakerSettings,
  addOverlay,
  removeOverlay,
  updateOverlay,
  setResolutions,
  setBitrates,
  setSimulcastSvc,
  setGatherMediaStats,
  setWebRTCTransportNames,
} = mediaSettingsSlice.actions
