import logger from "../../etc/logger"
import config from "../../etc/config"
import { clientTypes } from "../mediaServer/enums"
import { InfluxDBClient, Point } from "@influxdata/influxdb3-client"
import type { WritePrecision } from "@influxdata/influxdb3-client"

// Amount of points we store before sending them to the server.
// NOTE: The point for WebRTC stats is written every second, so the request will be sent every BUFFER_SIZE seconds.
const BUFFER_SIZE = 5
const clients: Record<string, InfluxDBClient> = {}
const points: Record<string, Point[]> = {}

export const getClient = (token: string, bucket: string, precision?: WritePrecision) => {
  if (!clients[token]) {
    clients[token] = new InfluxDBClient({
      token,
      host: config.metricsServerUrl,
      database: bucket,
      writeOptions: {
        precision,
        defaultTags: {
          clientType: clientTypes.MS,
          env: config.app.environment,
        },
      },
    })
  }

  return clients[token]
}

// Writes points and clears out the buffer.
const flushPoints = async (token: string, bucket: string, precision?: WritePrecision) => {
  const client = getClient(token, bucket, precision)
  const pointsToFlush = points[token]
  if (!pointsToFlush) {
    throw new Error("No points to flush.")
  }

  // Send the points to the metrics server.
  await client.write(pointsToFlush)
  // Clear out the points array.
  points[token] = []
}

export const addPoint = async (point: Point, token: string, bucket: string, precision?: WritePrecision) => {
  if (points[token]) {
    points[token].push(point)
    if (points[token].length >= BUFFER_SIZE) {
      await flushPoints(token, bucket, precision)
    }
  } else {
    points[token] = [point]
  }
}

export const closeClient = async (token: string) => {
  if (clients[token]) {
    await clients[token].close()
    delete clients[token]
    delete points[token]
  } else {
    logger.warn(`Couldn't find the Api client for token: ${token}`)
  }
}
