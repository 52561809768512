import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { Socket } from "socket.io-client"
import { connect, disconnect, getConnection, initConnection, isAllowedToReconnect } from "./connection"
import { connectionStates, socketDisconnectionReasons } from "./enums"
import { auth } from "../../etc/firebase"
import {
  addProducerToAwaiting,
  checkUrlHealth,
  getReceiveDataTransport,
  getReceiveTransport,
  getSendDataTransport,
  getSendTransport,
  isActiveSpeakersEqual,
  isProducerAwaiting,
  prepareDevice,
  prepareTransports,
  removeProducerFromAwaiting,
  stopUrlHealthCheck,
} from "./utils"
import logger from "../../etc/logger"
import config from "../../etc/config"

import type { Consumer } from "mediasoup-client/lib/Consumer"
import type { Producer } from "mediasoup-client/lib/Producer"
import type { DataConsumer } from "mediasoup-client/lib/DataConsumer"
import type { DataProducer } from "mediasoup-client/lib/DataProducer"
import {
  ConnectionState,
  MediaConsumerRecord,
  Message,
  Peer,
  ProducerStats,
  Settings,
  TrackProducerParams,
  UserAction,
  UserActionKind,
} from "./model"
import { buildFromProducerStats } from "./stats"

export const dataConsumers: { [key: string]: DataConsumer } = {}
export const dataProducers: { [key: string]: DataProducer } = {}

export const consumers: { [key: string]: Consumer } = {}
export const producers: { [key: string]: Producer } = {}

export const mediaServerApi = createApi({
  reducerPath: "mediaServerApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (build) => ({
    getMediaServerUrl: build.query<string, string>({
      queryFn: async (eventId) => {
        if (config.media.url) {
          return { data: config.media.url }
        }

        const token = await auth.currentUser?.getIdToken()
        const deployResponse = await fetch(`${config.api.url}/v0/events/${eventId}/servers/start`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        const data = await deployResponse.json()
        const url = data?.data?.url
        if (!url) {
          throw new Error(data.message || "Unable to deploy the media server.")
        }

        // Now check whether the server is ready.
        const isHealthy = await checkUrlHealth(url)
        if (isHealthy) {
          return { data: url }
        }

        throw new Error("Media server is not ready.")
      },
      async onCacheEntryAdded(_, { cacheEntryRemoved }) {
        await cacheEntryRemoved
        // Stop checking url in case it was still in progress after we unsubscribed from the query.
        stopUrlHealthCheck()
      },
    }),

    getConnectionState: build.query<ConnectionState, void>({
      queryFn: () => ({ data: { state: connectionStates.UNINITIATED } }),
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const mediaServer = getConnection()

        try {
          await cacheDataLoaded

          mediaServer.on("connect", () => updateCachedData(() => ({ state: connectionStates.CONNECTING })))
          mediaServer.on("room-joined", async ({ rtpCapabilities }) => {
            // Prepare Device in advance.
            const device = await prepareDevice(rtpCapabilities)
            // We're preparing transports in advance, so they do not conflict further.
            await prepareTransports(mediaServer)
            // Tell media server that we're ready to consume media stream.
            mediaServer.emit("media-ready", { rtpCapabilities: device.rtpCapabilities })
            // Tell media server that we're ready to consume media data.
            mediaServer.emit("data-ready")
            updateCachedData(() => ({ id: mediaServer.id, state: connectionStates.CONNECTED }))
          })

          mediaServer.on("connect_error", (err) =>
            updateCachedData(() =>
              isAllowedToReconnect(socketDisconnectionReasons.TRANSPORT_ERROR, err)
                ? {
                    state: connectionStates.RECONNECTING,
                  }
                : {
                    state: connectionStates.DISCONNECTED,
                    error: err,
                  },
            ),
          )

          mediaServer.on("disconnect", (reason: Socket.DisconnectReason) =>
            updateCachedData(() => ({
              state: isAllowedToReconnect(reason) ? connectionStates.RECONNECTING : connectionStates.DISCONNECTED,
            })),
          )
        } catch (e) {
          logger.error(e)
        }

        await cacheEntryRemoved
        mediaServer.off("connect")
        mediaServer.off("room-joined")
      },
    }),

    getSettings: build.query<Settings, void>({
      queryFn: () => ({
        data: {
          isAnnotationsAllowed: false,
          muted: false,
          gatherMediaStats: { vr: false, overlays: false },
          isUserListHidden: false,
          isRecording: false,
        },
      }),
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const connection = getConnection()

        try {
          await cacheDataLoaded
          connection.on("settings", (settings: Settings) => {
            updateCachedData(() => settings)
          })

          // Listen to individual settings updates.
          connection.on("user-action", ({ kind, appData }: UserAction) => {
            switch (kind) {
              case UserActionKind.RECORDING_TOGGLE:
                updateCachedData((currentSettings) => ({
                  ...currentSettings,
                  isRecording: !!appData?.enableRecording,
                }))
                break
            }
          })
        } catch (e) {
          logger.error(e)
        }

        await cacheEntryRemoved
        connection.off("settings")
      },
    }),

    getMessages: build.query<Message[], void>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const mediaServer = getConnection()

        try {
          await cacheDataLoaded
          mediaServer.on("message", (message: Message) =>
            updateCachedData((draft) => {
              draft.push(message)
            }),
          )
        } catch (e) {
          logger.error(e)
        }

        await cacheEntryRemoved
        mediaServer.off("message")
      },
    }),

    getParticipants: build.query<Peer[], void>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const mediaServer = getConnection()

        try {
          await cacheDataLoaded

          // Get participant list on initial join.
          mediaServer.on("room-joined", ({ peers }: { peers: Peer[] }) => {
            updateCachedData(() => peers)
          })

          // Listen to the new participant connection.
          mediaServer.on("peer-connect", (peer: Peer) =>
            updateCachedData((draft) => {
              draft.push(peer)
            }),
          )

          // Listen to a participant disconnection.
          mediaServer.on("peer-disconnect", ({ id }: Peer) =>
            updateCachedData((draft) => draft.filter((peer) => peer.id !== id)),
          )

          mediaServer.on("peer-mute-toggle", ({ peerId, mute, muteLockedByModerator }) => {
            updateCachedData((currentPeers) => {
              const peerToToggle = currentPeers.find((peer) => peer.id === peerId)
              if (peerToToggle) {
                peerToToggle.muted = mute
                peerToToggle.muteLockedByModerator = muteLockedByModerator
              }
              return currentPeers
            })
          })
        } catch (e) {
          logger.error(e)
        }

        await cacheEntryRemoved
        mediaServer.off("peer-connect")
        mediaServer.off("peer-disconnect")
        mediaServer.off("peer-mute-toggle")
        mediaServer.off("room-joined")
      },
    }),

    getSpeakingParticipants: build.query<Peer[], void>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState }) {
        const mediaServer = getConnection()

        try {
          await cacheDataLoaded

          mediaServer.on("active-speakers", (peers: Peer[]) => {
            const currentSpeakers = (getState().mediaServerApi.queries["getSpeakingParticipants(undefined)"]?.data ||
              []) as Peer[]

            if (!isActiveSpeakersEqual(currentSpeakers, peers)) {
              updateCachedData(() => peers)
            }
          })
        } catch (e) {
          logger.error(e)
        }

        await cacheEntryRemoved
        mediaServer.off("active-speakers")
      },
    }),

    getMediaTracks: build.query<MediaConsumerRecord[], void>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const mediaServer = getConnection()

        try {
          await cacheDataLoaded

          const addTrack = (trackRecord: MediaConsumerRecord) =>
            updateCachedData((existingRecords) => {
              existingRecords.push(trackRecord)
            })

          const removeTrack = (trackId: string) =>
            updateCachedData((existingRecords) => existingRecords.filter((record) => record.track.id !== trackId))

          mediaServer.on(
            "consumer-new",
            async ({ id, producerId, peerId, kind, rtpParameters, appData }, cb: () => void) => {
              logger.debug(`Got new consumer: ${id} [${kind}]`)

              const receiveTransport = await getReceiveTransport(mediaServer)
              const consumer = await receiveTransport.consume({ id, producerId, kind, rtpParameters, appData })
              consumers[id] = consumer
              // Update the server side that the local consumer has been successfully created, so it can play it.
              cb()

              // Add the track to store.
              addTrack({ track: consumer.track, peerId, appData })

              consumer.on("transportclose", () => {
                logger.debug(`Consumer closed: ${consumer.id} [${consumer.kind}].`)
                removeTrack(consumer.track.id)
              })
            },
          )

          mediaServer.on("consumer-close", ({ id }) => {
            const consumer = consumers[id]
            if (consumer) {
              consumer.close()
              delete consumers[id]
              logger.debug(`Consumer closed: ${consumer.id} [${consumer.kind}].`)
              removeTrack(consumer.track.id)
            } else {
              logger.warn(`Couldn't find the consumer: ${id}`)
            }
          })
        } catch (e) {
          logger.error(e)
        }

        await cacheEntryRemoved
        mediaServer.off("consumer-new")
        mediaServer.off("consumer-close")
      },
    }),

    getProducerMediaStats: build.query<ProducerStats | undefined, string>({
      queryFn: () => ({ data: undefined }),
      async onCacheEntryAdded(id, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const producer = producers[id]
        if (!producer) {
          throw new Error(`Cannot find producer ${id} to get stats from.`)
        }

        let prevStats: RTCStatsReport
        await cacheDataLoaded

        const statsInterval: NodeJS.Timeout = setInterval(async () => {
          if (producer.closed) {
            return clearInterval(statsInterval)
          }

          const stats = await producer.getStats()
          const calculatedStats = buildFromProducerStats(stats, prevStats)
          prevStats = stats
          updateCachedData(() => calculatedStats)
        }, 1000)

        await cacheEntryRemoved
        clearInterval(statsInterval)
      },
    }),

    getOverlayTitle: build.query<string, string>({
      queryFn: () => ({ data: "" }),
      async onCacheEntryAdded(id, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const mediaServer = getConnection()

        try {
          await cacheDataLoaded

          mediaServer.on("overlay-rename", ({ id: overlayId, title }) => {
            if (overlayId === id) {
              updateCachedData(() => title)
            }
          })
        } catch (e) {
          logger.error(e)
        }

        await cacheEntryRemoved
        // Do not unsubscribe as it's used by multiple overlays.
        // mediaServer.off("overlay-rename")
      },
    }),

    consumeData: build.query<Array<string>, void>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        const mediaServer = getConnection()

        try {
          await cacheDataLoaded

          const addDataConsumer = (dataConsumer: DataConsumer) => {
            dataConsumers[dataConsumer.id] = dataConsumer
            updateCachedData((ids) => {
              ids.push(dataConsumer.id)
            })
          }

          const removeDataConsumer = (id: string) => {
            delete dataConsumers[id]
            updateCachedData((ids) => ids.filter((cId) => cId !== id))
            logger.debug(`Data Consumer closed: ${id}}.`)
          }

          mediaServer.on("data-consumer-new", async ({ id, dataProducerId, sctpStreamParameters, label, appData }) => {
            logger.debug("Got new data consumer:", label)

            const receiveDataTransport = await getReceiveDataTransport(mediaServer)
            const dataConsumer = await receiveDataTransport.consumeData({
              id,
              dataProducerId,
              sctpStreamParameters,
              label,
              appData,
            })

            addDataConsumer(dataConsumer)

            dataConsumer.on("transportclose", () => {
              removeDataConsumer(dataConsumer.id)
            })
          })

          mediaServer.on("data-consumer-close", ({ id }) => {
            const dataConsumer = dataConsumers[id]
            if (dataConsumer) {
              dataConsumer.close()
              removeDataConsumer(id)
            } else {
              logger.warn(`Couldn't find the data consumer: ${id}`)
            }
          })
        } catch (e) {
          logger.error(e)
        }

        await cacheEntryRemoved
        mediaServer.off("data-consumer-new")
        mediaServer.off("data-consumer-close")
      },
    }),

    produceData: build.mutation<string, string>({
      queryFn: async (id) => {
        if (isProducerAwaiting(id)) {
          logger.debug(`Data producer "${id}" is being created...`)
          return { data: "" }
        }

        if (dataProducers[id]) {
          logger.warn(`Data producer "${id}" already active.`)
          return { data: dataProducers[id].id }
        }

        addProducerToAwaiting(id)
        const mediaServer = getConnection()
        const sendDataTransport = await getSendDataTransport(mediaServer)
        const dataProducer = await sendDataTransport.produceData({ label: id })
        dataProducers[id] = dataProducer
        removeProducerFromAwaiting(id)

        dataProducer.on("transportclose", () => {
          removeProducerFromAwaiting(id)
          delete dataProducers[id]
        })

        return { data: dataProducer.id }
      },
    }),

    removeMessage: build.mutation<null, string>({
      queryFn: () => ({ data: null }),
      async onQueryStarted(id, { dispatch }) {
        dispatch(
          mediaServerApi.util?.updateQueryData("getMessages", undefined, (draft) =>
            draft.filter((message) => message.id !== id),
          ),
        )
      },
    }),

    produceTrack: build.mutation<string, TrackProducerParams>({
      queryFn: async ({ transportName, ...producerParams }) => {
        const mediaServer = getConnection()
        const sendTransport = await getSendTransport(transportName, mediaServer)
        const producer = await sendTransport.produce(producerParams)
        producers[producer.id] = producer

        producer.on("transportclose", () => {
          delete producers[producer.id]
        })

        return { data: producer.id }
      },
    }),

    replaceProducerTrack: build.mutation<null, { producerId: string; newTrack: MediaStreamTrack }>({
      queryFn: ({ producerId, newTrack }) => {
        const producer = producers[producerId]
        if (!producer) {
          throw new Error(`Cannot find producer ${producerId} to replace the track.`)
        }

        producer.replaceTrack({ track: newTrack })

        return { data: null }
      },
    }),

    pauseProducer: build.mutation<null, { producerId: string }>({
      queryFn: ({ producerId }) => {
        const mediaServer = getConnection()
        const producer = producers[producerId]
        if (!producer) {
          throw new Error(`Cannot find producer ${producerId} to pause.`)
        }

        mediaServer.emit("producer-pause", producerId, () => {
          if (producer.paused) {
            logger.warn(`Producer ${producerId} already paused.`)
          } else {
            producer.pause()
          }
        })

        return { data: null }
      },
    }),

    resumeProducer: build.mutation<null, { producerId: string }>({
      queryFn: ({ producerId }) => {
        const mediaServer = getConnection()
        const producer = producers[producerId]
        if (!producer) {
          throw new Error(`Cannot find producer ${producerId} to resume.`)
        }

        mediaServer.emit("producer-resume", producerId, () => {
          if (!producer.paused) {
            logger.warn(`Producer ${producerId} is already active.`)
          } else {
            producer.resume()
          }
        })

        return { data: null }
      },
    }),

    closeProducer: build.mutation<null, string>({
      queryFn: (producerId) => {
        try {
          const mediaServer = getConnection()

          const producer = producers[producerId]
          if (!producer) {
            throw new Error(`Cannot find producer ${producerId} to stop.`)
          }

          if (producer.closed) {
            logger.warn(`Producer ${producerId} already stopped.`)
          } else {
            mediaServer.emit("producer-close", producer.id)
            producer.close()
            delete producers[producerId]
            logger.debug(`Producer closed: ${producer.id} [${producer.kind}]`)
          }
        } catch (e) {
          if (e instanceof Error) {
            logger.warn(e.message)
          } else {
            logger.warn(e)
          }
        }

        return { data: null }
      },
    }),

    closeDataProducer: build.mutation<null, string>({
      queryFn: (producerId) => {
        try {
          removeProducerFromAwaiting(producerId)
          const mediaServer = getConnection()

          const dataProducer = dataProducers[producerId]
          if (!dataProducer) {
            throw new Error(`Cannot find data producer "${producerId}" to close.`)
          }

          if (dataProducer.closed) {
            logger.warn(`Data producer "${producerId}" already closed.`)
          } else {
            mediaServer.emit("data-producer-close", dataProducer.id)
            dataProducer.close()
            delete dataProducers[producerId]
            logger.debug(`Data producer closed: ${dataProducer.id}`)
          }
        } catch (e) {
          if (e instanceof Error) {
            logger.warn(e.message)
          } else {
            logger.warn(e)
          }
        }

        return { data: null }
      },
    }),

    setTrackPlaybackState: build.mutation<null, { trackId: string; isPlaying: boolean }>({
      queryFn: ({ trackId, isPlaying }) => {
        const mediaServer = getConnection()

        mediaServer.emit("media-track-playback-toggle", trackId, isPlaying)

        return { data: null }
      },
    }),

    setUserMuteState: build.mutation<null, { peerId: string; mute: boolean }>({
      queryFn: ({ peerId, mute }) => {
        const mediaServer = getConnection()
        if (mediaServer.disconnected) {
          throw new Error("No active media server connection.")
        }
        mediaServer.emit("peer-mute-toggle", peerId, mute)

        return { data: null }
      },
    }),

    setRoomMuteState: build.mutation<null, { muteState: boolean }>({
      queryFn: ({ muteState }) => {
        const mediaServer = getConnection()
        mediaServer.emit("room-mute-toggle", muteState)

        return { data: null }
      },
    }),

    setRoomAnnotationsState: build.mutation<null, { allowed: boolean }>({
      queryFn: ({ allowed }) => {
        const mediaServer = getConnection()
        mediaServer.emit("room-annotations-toggle", allowed)

        return { data: null }
      },
    }),

    sendUserAction: build.mutation<null, UserAction>({
      queryFn: (userActionParams) => {
        const mediaServer = getConnection()
        mediaServer.emit("user-action", userActionParams)

        return { data: null }
      },
    }),

    renameOverlay: build.mutation<null, { id: string; title: string }>({
      queryFn: ({ id, title }) => {
        const mediaServer = getConnection()
        mediaServer.emit("overlay-rename", { id, title })

        return { data: null }
      },
    }),

    connect: build.mutation<null, string>({
      queryFn: (url) => {
        const mediaServer = initConnection(url)

        if (mediaServer.disconnected) {
          connect()
        } else {
          logger.warn("Already connected to the media server.")
        }

        return { data: null }
      },
    }),

    disconnect: build.mutation<null, void>({
      queryFn: () => {
        disconnect()
        return { data: null }
      },
    }),
  }),
})

export const {
  useGetMediaServerUrlQuery,
  useGetConnectionStateQuery,
  useGetSettingsQuery,
  useGetMessagesQuery,
  useGetParticipantsQuery,
  useGetSpeakingParticipantsQuery,
  useGetMediaTracksQuery,
  useGetProducerMediaStatsQuery,
  useGetOverlayTitleQuery,
  useConsumeDataQuery,
  useProduceDataMutation,
  useRemoveMessageMutation,
  useProduceTrackMutation,
  useReplaceProducerTrackMutation,
  usePauseProducerMutation,
  useResumeProducerMutation,
  useCloseProducerMutation,
  useCloseDataProducerMutation,
  useSetTrackPlaybackStateMutation,
  useSetUserMuteStateMutation,
  useSetRoomMuteStateMutation,
  useSetRoomAnnotationsStateMutation,
  useSendUserActionMutation,
  useRenameOverlayMutation,
  useConnectMutation,
  useDisconnectMutation,
} = mediaServerApi
