import React from "react"
import { useNavigate } from "react-router-dom"
import { Flex, Box, Image, Heading, Text } from "rebass"
import styled from "styled-components"
import { ArrowForwardOutline } from "react-ionicons"
import formatDate from "date-fns/format"

import { Loader } from "../../components/Loader"

import { colors } from "../../etc/theme"
import { model, useListEventsQuery, useGetInstructorQuery, enums } from "../../libs/api"
import { formatToDateTimeZone, todayStartTime, getEventMainInstructorName } from "../../helpers"

import logo from "../../assets/images/logo/immertec-icon.png"
import { skipToken } from "@reduxjs/toolkit/query"

const ArrowIcon = styled(ArrowForwardOutline)`
  position: absolute;
  bottom: 10px;
  right: 16px;
  opacity: 0;
  transition: opacity 0.25s ease-out;
`

const CardWrapper = styled(Box)`
  filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.15));
  &:hover {
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
    ${ArrowIcon} {
      opacity: 1;
    }
  }
`

type Props = {
  id: model.generated.FirestoreDocumentId
}

export const Event: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate()

  const { event } = useListEventsQuery(
    { filter: { endAfter: todayStartTime, streamType: enums.EventStreamType.REGULAR } },
    {
      selectFromResult: ({ data }) => ({
        event: data?.find((event) => event.id === id),
      }),
    },
  )

  const { data: instructor } = useGetInstructorQuery(event?.instructors?.length ? event.instructors[0] : skipToken)

  const onEventSelect = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    navigate(`/events/${id}`)
  }

  if (!event) {
    return (
      <Box width="340px" height="165px">
        <Loader size="25px" color="primary" />
      </Box>
    )
  }

  return (
    <CardWrapper
      mx={4}
      bg="grays.600"
      sx={{
        position: "relative",
        borderRadius: 8,
        overflow: "hidden",
        cursor: "pointer",
        border: "1px solid transparent",
      }}
      onClick={onEventSelect}
    >
      {event?.photo ? (
        <Image src={event.photo} width={1} maxHeight={200} />
      ) : (
        <Flex width={1} height={160} justifyContent="center" alignItems="center" bg="grays.700">
          <Image src={logo} opacity="0.1" />
        </Flex>
      )}
      <Box p={4} pt={4} textAlign="left">
        <Box
          width={1 / 3}
          py={2}
          px={3}
          mt={-5}
          mb={4}
          bg="grays.600"
          textAlign="center"
          sx={{ borderRadius: 8, filter: "drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.15))" }}
        >
          <Heading fontSize={3} color="secondary">
            {formatDate(event.startTime, "d")}
          </Heading>
          <Text mt={1}>{formatDate(event.startTime, "MMM")}</Text>
        </Box>
        <Heading fontSize={2} className="line-clamp-1">
          {event.title}
        </Heading>
        {(instructor || event.guestInstructors?.length) && (
          <Text mt={2} fontSize={0} color="light">
            {getEventMainInstructorName(instructor, event.guestInstructors)}
          </Text>
        )}
        <Text mt={2} fontSize={0}>
          {formatToDateTimeZone(new Date(event.startTime))}
        </Text>
      </Box>
      <ArrowIcon color={colors.secondary} />
    </CardWrapper>
  )
}
