import React, { useState, useCallback } from "react"
import { FingerPrintOutline } from "react-ionicons"
import { Flex, Box } from "rebass"
import { useNavigate } from "react-router-dom"
import { Logo } from "./Logo"
import { Time } from "./Time"
import { MicControl } from "./MicControl"
import { SpeakerControl } from "./SpeakerControl"
import { useMediaDevices } from "../../hooks/useMediaDevices"
import { StreamStateControl } from "./StreamStateControl"
import { colors } from "../../etc/theme"
import { AttendeeList } from "./AttendeeList"
import { AttendeesCount } from "./AttendeesCount"
import { VRVideo } from "./VRVideo"
import { Overlays } from "./Overlays"
import { Errors } from "./Errors"
import { ReloadButton } from "../../components/ReloadButton"

export const Stream: React.FC = () => {
  const [isConnectionInitiated, setIsConnectionInitiated] = useState(false)
  const [isTouchScreenDisabled, setIsTouchScreenDisabled] = useState(false)

  const navigate = useNavigate()

  // Setup listener for media devices.
  useMediaDevices()

  const onLogoTaps = useCallback(() => {
    if (!isConnectionInitiated) {
      navigate("/settings")
    }
  }, [isConnectionInitiated, navigate])

  const onTouchScreenDisableToggle = useCallback(() => {
    setIsTouchScreenDisabled(!isTouchScreenDisabled)
  }, [isTouchScreenDisabled])

  return (
    <Flex className="vh-full">
      <Flex flexDirection="column" width="470px" bg={colors.side}>
        <Flex flexDirection="column" width="100%" p="20px 24px" css={{ position: "relative" }}>
          <Flex alignItems="center">
            <Logo onTap={onLogoTaps} />
            <Time ml="30px" fontSize="26px" />
            <ReloadButton ml="10px" askBeforeReload />
          </Flex>
          <MicControl isConnectionInitiated={isConnectionInitiated} />
          <SpeakerControl />
        </Flex>

        <Box width="30px" height="2px" bg="#000" opacity={0.2} m="5px auto 18px" p="1px" />
        <AttendeeList isConnectionInitiated={isConnectionInitiated} />
        <AttendeesCount isConnectionInitiated={isConnectionInitiated} />

        <Box p="35px 45px" bg={colors.black} fontSize="2">
          <StreamStateControl setIsConnectionInitiated={setIsConnectionInitiated} />
        </Box>
      </Flex>
      <Box flex="1" p="20px 40px">
        <Flex flexDirection="column" height="100%" m="0 auto">
          <Box css={{ position: "relative" }} flex="1" minHeight="80px">
            <VRVideo isConnectionInitiated={isConnectionInitiated} />
          </Box>
          <Overlays isConnectionInitiated={isConnectionInitiated} />
          {isConnectionInitiated && <Errors />}
        </Flex>
      </Box>

      {isTouchScreenDisabled && <Box css={{ position: "fixed", left: 0, top: 0, width: "100%", height: "100%" }} />}
      <FingerPrintOutline
        width="40px"
        height="40px"
        style={{ position: "absolute", left: "385px", top: "25px", cursor: "pointer" }}
        color={isTouchScreenDisabled ? colors.red : colors.dark}
        onClick={onTouchScreenDisableToggle}
      />
    </Flex>
  )
}
