import React, { useState, useEffect, useRef, useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { Image, Images, Settings, RadioButtonOn, RadioButtonOff } from "react-ionicons"
import { Flex, Box } from "rebass"
import { selectMediaSettings, updateVRSettings } from "../../redux/mediaSettingsSlice"
import { Button } from "../../components/Button"
import { Select } from "../../components/Select"
import { getEncodings, showError, VR_TRACK_ID } from "../../helpers"
import { colors } from "../../etc/theme"
import { deviceKinds } from "../../enums"
import type { Device } from "../../enums"
import { DeviceSelect } from "./DeviceSelect"
import config from "../../etc/config"
import logger from "../../etc/logger"
import {
  useGetConnectionStateQuery,
  useProduceTrackMutation,
  useReplaceProducerTrackMutation,
} from "../../libs/mediaServer"
import { codecs, connectionStates, mediaKinds } from "../../libs/mediaServer/enums"
import { MediaStats } from "./MediaStats"
import { usePausedTrack } from "../../hooks/usePausedTrack"

const defaultBitrate = 10000000 // 10Mb if not set.

const Video = styled.video<{ isStretched: boolean }>`
  width: ${({ isStretched }) => (isStretched ? "200%" : "100%")};
  height: 100%;
  background: #000;
  object-fit: cover;
`

const videoWidthToSqueeze = 2000

type Props = {
  isConnectionInitiated: boolean
}

export const VRVideo: React.FC<Props> = ({ isConnectionInitiated }) => {
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack>()
  const [isPaused, setIsPaused] = useState(false)
  const [isStretched, setIsStretched] = useState(false)
  const [showResolutionSelect, setShowResolutionSelect] = useState(false)
  const [showGreenStrip, setShowGreenStrip] = useState(false)

  const dispatch = useDispatch()
  const mediaSettings = useSelector(selectMediaSettings)

  const videoRef = useRef<HTMLVideoElement>(null)
  // Used to keep paused frame and stream it.
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const { data: connection } = useGetConnectionStateQuery(isConnectionInitiated ? undefined : skipToken)
  const [produceTrack, { data: producerId, error: produceTrackError }] = useProduceTrackMutation()
  const [replaceProducerTrack] = useReplaceProducerTrackMutation()

  // Handle video track pause/resume.
  usePausedTrack({ id: VR_TRACK_ID, videoRef, canvasRef, videoTrack, producerId, isPaused })

  const isConnected = connection?.state === connectionStates.CONNECTED

  const resolutionSelectOptions = useMemo(() => {
    return mediaSettings.resolutions
      .filter((res) => res.allowed)
      .map((res) => ({
        value: res.title,
        title: `${res.width}x${res.height} (${res.title})`,
      }))
  }, [mediaSettings.resolutions])

  const getDeviceMedia = useCallback(
    (deviceId: string) =>
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          deviceId: { exact: deviceId },
          // NOTE: It may not change the device if the selected doesn't support selected resolution.
          width: { exact: mediaSettings.vr.resolution.width },
          height: { exact: mediaSettings.vr.resolution.height },
          frameRate: config.media.frameRate,
        },
      }),
    [mediaSettings.vr],
  )

  const stopVideoTrack = useCallback(() => {
    videoTrack?.stop()
  }, [videoTrack])

  const onResolutionChange = useCallback(
    async ({ value }: { value: string }) => {
      if (!videoTrack) {
        return
      }

      const resolution = mediaSettings.resolutions.find((res) => res.title === value)

      if (!resolution) {
        return showError("No such resolution", "Such resolution is not allowed.")
      }

      try {
        await videoTrack.applyConstraints({
          width: { exact: resolution.width },
          height: { exact: resolution.height },
          frameRate: config.media.frameRate,
        })
        setShowResolutionSelect(false)
        dispatch(updateVRSettings({ resolution }))
      } catch (e) {
        logger.warn(e)
        showError("Bad resolution", "The device doesn't support selected resolution.")
      }
    },
    [videoTrack, mediaSettings.resolutions, dispatch],
  )

  const onDeviceChange = useCallback(
    async (device: Device) => {
      try {
        const mediaStream = await getDeviceMedia(device.id)
        const newVideoTrack = mediaStream.getVideoTracks()[0]

        // Set srcObject of the video element.
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream
        }

        // If there's a track already, we need to replace it.
        if (isConnected && producerId) {
          // Send the new track to the media server.
          await replaceProducerTrack({ producerId, newTrack: newVideoTrack })
        }

        // NOTE: This will trigger stopVideoTrack and stop the old track.
        setVideoTrack(newVideoTrack)
        dispatch(updateVRSettings({ device }))
      } catch (e) {
        logger.warn(e)
        showError(
          "Media Error",
          "Unable to get media from requested device. Make sure it supports the selected resolution.",
        )
      }
    },
    [isConnected, producerId, getDeviceMedia, replaceProducerTrack, dispatch],
  )

  useEffect(() => {
    if (!isConnected || !videoTrack) {
      return
    }

    const videoEncodings = getEncodings({
      maxBitrate: mediaSettings.bitrates.vr || defaultBitrate,
      useSimulcast: mediaSettings.simulcastSvc.vr && mediaSettings.simulcastSvc.codec === codecs.VP8,
      useSVC: mediaSettings.simulcastSvc.vr && mediaSettings.simulcastSvc.codec === codecs.VP9,
    })

    produceTrack({
      transportName: mediaSettings.webrtcTransportNames.vr,
      track: videoTrack,
      appData: {
        streamId: VR_TRACK_ID,
        settings: {
          hShift: mediaSettings.vr.hShift,
          vShift: mediaSettings.vr.vShift,
          fov: mediaSettings.vr.fov,
          swap: mediaSettings.vr.swap,
        },
      },
      stopTracks: false,
      zeroRtpOnPause: true,
      encodings: videoEncodings,
      codec: {
        kind: mediaKinds.VIDEO,
        mimeType: `${mediaKinds.VIDEO}/${mediaSettings.simulcastSvc.codec || codecs.VP8}`,
        clockRate: 90000, // Both VP8 and VP9 support 90000 only.
      },
    })
    // NOTE: We do not include other dependencies on purpose, so their change doesn't trigger produce.
  }, [isConnected]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mediaSettings.vr.resolution) {
      const stretch = mediaSettings.vr.resolution.width > videoWidthToSqueeze
      setIsStretched(stretch)
    }
  }, [mediaSettings.vr.resolution])

  useEffect(() => {
    if (produceTrackError && "message" in produceTrackError) {
      showError("Unable to produce VR video", produceTrackError.message)
    }
  }, [produceTrackError])

  // Stop video track on unmount and new video track set.
  useEffect(() => {
    return () => stopVideoTrack()
  }, [stopVideoTrack])

  return (
    <Box width="100%" height="100%" css={{ position: "absolute", overflow: "hidden" }}>
      <Video ref={videoRef} autoPlay playsInline isStretched={isStretched} />
      <canvas ref={canvasRef} />

      {mediaSettings.vr.resolution && mediaSettings.vr.resolution.width > videoWidthToSqueeze && (
        <>
          <Button
            onClick={() => setIsStretched(!isStretched)}
            variant="warning"
            p="2px 6px"
            css={{ position: "absolute", right: "70px", bottom: "10px" }}
            data-tip="Toggle View"
            data-for="vr-view"
          >
            {isStretched ? <Images color={colors.white} /> : <Image color={colors.white} />}
          </Button>
          <ReactTooltip id="vr-view" effect="solid" />
        </>
      )}

      {isPaused && (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          fontSize="2"
          fontWeight="bold"
          bg="rgba(0, 0, 0, 0.5)"
          css={{
            position: "absolute",
            left: 0,
            top: 0,
            backdropFilter: "blur(6px)",
          }}
        >
          Offline
        </Flex>
      )}

      <Button
        onClick={() => setShowResolutionSelect(true)}
        variant="dark"
        p="2px 6px"
        css={{ position: "absolute", left: "6px", bottom: "10px" }}
        data-tip="Select resolution"
        data-for="vr-res"
      >
        <Settings color={colors.white} />
      </Button>
      <ReactTooltip id="vr-res" effect="solid" />
      {showResolutionSelect && (
        <Select
          options={resolutionSelectOptions}
          selected={mediaSettings.vr.resolution.title}
          onCheck={onResolutionChange}
          onClose={() => setShowResolutionSelect(false)}
        />
      )}

      <DeviceSelect
        css={{ position: "absolute", left: "55px", bottom: "10px" }}
        deviceType={deviceKinds.VIDEO_INPUT}
        defaultDevice={mediaSettings.vr.device}
        onDeviceChange={onDeviceChange}
        allowAutoChoose={false}
        iconSize={22}
      />

      <Button
        onClick={() => setIsPaused(!isPaused)}
        variant={isPaused ? "danger" : "success"}
        p="2px 6px"
        css={{ position: "absolute", right: "6px", bottom: "10px" }}
        data-tip={isPaused ? "Resume" : "Pause"}
        data-for="vr-pause"
      >
        {isPaused ? <RadioButtonOff color={colors.white} /> : <RadioButtonOn color={colors.white} />}
      </Button>
      <ReactTooltip id="vr-pause" effect="solid" />

      <Box
        onClick={() => setShowGreenStrip(!showGreenStrip)}
        width="15px"
        height="15px"
        bg="green"
        css={{ position: "absolute", right: "15px", top: "15px", borderRadius: "0%" }}
        data-tip="Toggle the camera placement guide"
        data-for="vr-green-indicator"
      />
      <ReactTooltip id="vr-green-indicator" />

      {showGreenStrip && (
        <Flex
          css={{
            position: "absolute",
            left: 0,
            top: "30%",
            height: "40%",
          }}
          width="100%"
          height="40%"
          bg="rgba(77, 175, 124, 0.2)"
        />
      )}

      {producerId && <MediaStats producerId={producerId} trackId={VR_TRACK_ID} />}
    </Box>
  )
}
