import React from "react"
import { Button as RButton, ButtonProps } from "rebass"
import { Loader } from "./Loader"

type Props = ButtonProps & {
  children: React.ReactNode
  isLoading?: boolean
  to?: string
}

export const Button: React.FC<Props> = ({ children, isLoading, ...props }) => (
  <RButton
    disabled={props.disabled || isLoading}
    sx={{
      display: "flex",
      padding: "6px 11px",
      border: "4px solid transparent",
      borderRadius: "medium",
      boxShadow: "buttonOutside",
      justifyContent: "center",
      alignItems: "center",
      textDecoration: "none",
      fontFamily: "inherit",
      fontSize: "inherit",
      fontWeight: "bold",
      color: "white",
      cursor: "pointer",
      outline: "none",
      ":active": {
        boxShadow: "buttonInside",
      },
      ":disabled": {
        opacity: 0.9,
        cursor: "not-allowed",
        boxShadow: "none",
      },
    }}
    {...props}
  >
    {isLoading ? <Loader size="14px" /> : children}
  </RButton>
)
