import React from "react"
import ReactTooltip from "react-tooltip"
import { Mic, MicOff } from "react-ionicons"
import { Flex } from "rebass"
import { Button } from "../../../components/Button"

type Props = {
  muted: boolean
  onMicMuteToggle: () => void
}

export const MicMuteControl: React.FC<Props> = ({ muted, onMicMuteToggle }) => {
  return (
    <>
      <Flex ml="auto" alignItems="center" justifyContent="center">
        <Button
          onClick={onMicMuteToggle}
          variant={muted ? "danger" : "success"}
          width="50px"
          p="2px 0"
          data-tip={muted ? "Microphone is muted, tap to unmute" : "Mute microphone"}
          data-for="microphone"
        >
          {muted ? (
            <MicOff color="white" width="30px" height="30px" />
          ) : (
            <Mic color="white" width="30px" height="30px" />
          )}
        </Button>
      </Flex>
      <ReactTooltip id="microphone" effect="solid" />
    </>
  )
}
