import { NormalizedUser, NormalizedInstructor } from "../libs/api/model"

import type { Attendee, Peer } from "../libs/mediaServer/model"
import { clientTypes } from "../libs/mediaServer/enums"

export const getEventAttendeesFromParticipants = (
  eventInstructor: NormalizedInstructor,
  users: NormalizedUser[],
  peers: Peer[],
): Attendee[] => {
  return peers.reduce((attendees, { id: connectionId, uid, client, muted, muteLockedByModerator }) => {
    // Use the first user as a template for instructor or guest if no such user in the event found.
    let user = users.find((u) => u.id === uid) || users[0]

    if (client.type === clientTypes.MS) {
      // Display ourselves as an Instructor.
      user = {
        ...user,
        name: eventInstructor.name,
        photo: eventInstructor.photo,
      }
    } else {
      user = users.find((u) => u.id === uid) || {
        // If the user is not in the event users list, it means it's a guest or Admin.
        ...user,
        // Clear major template data.
        id: "",
        name: { first: "Guest", last: "" },
        photo: undefined,
      }
    }

    attendees.push({
      ...user,
      connectionId,
      client,
      muted,
      muteLockedByModerator,
    })

    return attendees
  }, [] as Attendee[])
}
