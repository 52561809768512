import { FetchArgs } from "@reduxjs/toolkit/query"

type FetchOptions = {
  timeout?: number
  mode?: FetchArgs["mode"]
}

// Reference:
//   https://dmitripavlutin.com/timeout-fetch-request/
const fetchWithTimeout = async (resource: string, options: FetchOptions = {}) => {
  const { timeout = 3000 } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  })
  clearTimeout(id)
  return response
}

// Checks if the URL is reachable
export const checkUrl = (url: string, options?: FetchOptions): Promise<void> =>
  fetchWithTimeout(url, options).then((res) => {
    return new Promise((resolve, reject) => {
      if (res && res.status === 200) {
        return resolve()
      }
      return reject()
    })
  })
