import React, { useState, useRef, useEffect } from "react"
import { Link } from "rebass"
import logoPath from "./images/logo-mini.png"

type Props = {
  onTap: () => void
}

export const Logo: React.FC<Props> = ({ onTap }) => {
  const [clicksCount, setClicksCount] = useState(0)
  const lastClickTime = useRef(0)

  useEffect(() => {
    if (clicksCount >= 3) {
      onTap()
      setClicksCount(0)
    }
  }, [clicksCount])

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    const currentTime = new Date().getTime()
    if (currentTime - lastClickTime.current < 1000) {
      setClicksCount(clicksCount + 1)
    } else {
      setClicksCount(1)
    }
    lastClickTime.current = currentTime
  }

  return (
    <Link
      onClick={onClick}
      display="block"
      width="46px"
      height="50px"
      css={{ backgroundImage: `url(${logoPath})`, cursor: "pointer" }}
    />
  )
}
