import React, { useRef, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { selectMediaSettings } from "../../redux/mediaSettingsSlice"
import { selectDevices } from "../../redux/deviceSlice"

type Props = {
  track: MediaStreamTrack
}

export const AttendeeAudio: React.FC<Props> = ({ track }) => {
  const mediaSettings = useSelector(selectMediaSettings)
  const devices = useSelector(selectDevices)
  const audioEl = useRef<HTMLAudioElement & { setSinkId(deviceId: string): void }>(null)

  const speakerLevel = mediaSettings.speaker.level

  const speakerDeviceId = useMemo(() => {
    // NOTE: The device availability has already been checked in the parent component.
    return devices.find((d) => d.label === mediaSettings.speaker.device?.label)?.id
  }, [mediaSettings.speaker.device, devices])

  useEffect(() => {
    if (speakerDeviceId && track && audioEl.current) {
      audioEl.current.srcObject = new MediaStream([track])
      audioEl.current.setSinkId(speakerDeviceId)
    }
  }, [speakerDeviceId, track])

  useEffect(() => {
    if (!audioEl.current) {
      return
    }

    audioEl.current.volume = speakerLevel / 100
  }, [speakerLevel])

  return <audio ref={audioEl} autoPlay />
}
