import { enums } from "../libs/api"

export const buttonTitles = {
  PATIENT_ARRIVED: "Patient arrived",
  PATIENT_PREPARED: "Patient is prepared",
  TIME_OUT: "Time Out",
  START: "Start Stream",
  END: "End Stream",
} as const

export type ButtonTitle = typeof buttonTitles[keyof typeof buttonTitles]
export type EventStatus = keyof typeof enums.EventStatus
