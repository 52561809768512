import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { position, layout, border, LayoutProps, PositionProps, BorderProps } from "styled-system"
import { Checkmark } from "react-ionicons"
import { darken } from "polished"
import { colors } from "../etc/theme"

const SelectWrapper = styled.ul`
  width: 180px;
  margin: 0;
  padding: 10px;
  position: absolute;
  left: 6px;
  bottom: 55px;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  overflow: hidden;
  list-style: none;
  background: ${colors.white};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.dark};
  z-index: 2;
  ${position};
  ${layout};
  ${border};
`
const SelectItem = styled.li`
  padding: 5px 2px;
  height: 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  cursor: pointer;
  &:hover {
    background-color: ${darken(0.05, colors.white)};
  }
  &:last-child {
    border-bottom: none;
  }
`

export type SelectOption = {
  value: string
  title: string
}

type Props = LayoutProps &
  PositionProps &
  BorderProps & {
    options: SelectOption[]
    selected?: string
    onCheck: (o: SelectOption) => void
    onClose: () => void
  }

// Displays designed select with predefined options.
export const Select: React.FC<Props> = ({ options, selected, onCheck, onClose, ...styles }) => {
  const selfRef = useRef<HTMLUListElement>(null)

  /**
   * Executes after first render and unmount
   */
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = selfRef.current
      if (!target) {
        return
      }
      if (!target.contains(e.target as Node)) {
        onClose()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [onClose])

  return (
    <SelectWrapper ref={selfRef} {...styles}>
      {options.map((option, i) => (
        <SelectItem key={i} onClick={() => onCheck(option)}>
          {option.title}
          {JSON.stringify(selected) === JSON.stringify(option.value) && (
            <Checkmark color={colors.blue} width="25px" height="25px" />
          )}
        </SelectItem>
      ))}
    </SelectWrapper>
  )
}
