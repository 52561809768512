import React, { useState, useEffect } from "react"
import { isNativeContext } from "../helpers"
import { Alert } from "./Alert"
import styled from "styled-components"
import { Box } from "rebass"

const Wrapper = styled(Box)`
  position: absolute;
  top: 10px;
  right: 10px;
`

export const WebContextWarning: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    // Check if there's a specific API available (it's there only when running the app in a native context).
    if (!isNativeContext) {
      setShowAlert(true)
    }
  }, [])

  return showAlert ? (
    <Wrapper>
      <Alert warning onClose={() => setShowAlert(false)}>
        You are using the app in a web environment. The native functions will not work.
      </Alert>
    </Wrapper>
  ) : null
}
