import React, { createRoot } from "react-dom/client"
import { HashRouter as Router, Routes, Route } from "react-router-dom"
import { Provider } from "react-redux"
import { ThemeProvider } from "emotion-theming"

import AuthProvider from "./providers/AuthProvider"
import { GlobalStyles } from "./etc/styles"
import theme from "./etc/theme"
import { Home } from "./screens/Home"
import { Events } from "./screens/Events"
import { Stream } from "./screens/Stream"
import { Settings } from "./screens/Settings"
import { MediaCheck } from "./screens/MediaCheck"
import { WebContextWarning } from "./components/WebContextWarning"
import { store } from "./redux/store"

const container = document.getElementById("app") as HTMLElement
const root = createRoot(container)

root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Provider store={store}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/:eventId" element={<Stream />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/media-check" element={<MediaCheck />} />
          </Routes>
        </Router>
      </AuthProvider>
      <WebContextWarning />
    </Provider>
  </ThemeProvider>,
)
