import { useCallback, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"

import { skipToken } from "@reduxjs/toolkit/query"
import { useGetEventQuery, useGetUserQuery, useListUsersQuery } from "../libs/api"
import {
  dataConsumers,
  useCloseDataProducerMutation,
  useConsumeDataQuery,
  useGetConnectionStateQuery,
  useProduceDataMutation,
} from "../libs/mediaServer"
import type { Annotation, AnnotationData, AnnotationObjectSettings, PointCoordinates } from "../libs/mediaServer/model"
import { connectionStates, mediaDataTypes } from "../libs/mediaServer/enums"

import { formAnnotationData, generateRandomColor, sendAnnotation } from "./utils"
import { auth } from "../etc/firebase"

// TODO: Make configurable.
const lineWidth = 2
const lineColor = generateRandomColor()
const clearIn = 5000

export const useDraw = (
  id: string,
  canvasEl: HTMLCanvasElement | null,
  videoEl: HTMLVideoElement | null,
  readOnly = false,
  useMediaServer = true,
): void => {
  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D>()

  const params = useParams()
  const eventId = params.eventId as string

  const { data: user } = useGetUserQuery(auth.currentUser?.uid?.toString() || skipToken)
  const { data: event } = useGetEventQuery(eventId?.toString() || skipToken)
  const { data: eventUsers } = useListUsersQuery(
    event?.users?.length ? { filter: { userIds: event.users.map(({ id }) => id) } } : skipToken,
  )

  const { data: connection } = useGetConnectionStateQuery(undefined, { skip: !useMediaServer })
  const { data: dataConsumerIds = [] } = useConsumeDataQuery(undefined, { skip: !useMediaServer })
  const [initiateDataProducer] = useProduceDataMutation()
  const [closeDataProducer] = useCloseDataProducerMutation()

  const previousTime = useRef(new Date().getTime())
  const annotationArchive = useRef<Annotation[]>([])
  const pointsInDraw = useRef<PointCoordinates[]>([])
  const remoteAnnotationInDraw = useRef<{ [userId: string]: Annotation }>()

  const drawLine = useCallback(
    (
      {
        x0,
        y0,
        x1,
        y1,
        color = lineColor,
        width = lineWidth,
        relativeWidth = 0,
        relativeHeight = 0,
      }: {
        x0: number
        y0: number
        x1: number
        y1: number
        color?: string
        width?: number
        relativeWidth?: number
        relativeHeight?: number
      },
      throttleDelay = 10,
    ) => {
      if (!canvasContext) {
        return
      }

      if (throttleDelay) {
        const time = new Date().getTime()
        if (time - previousTime.current < throttleDelay) {
          return
        }
        previousTime.current = time
      }

      let rx0 = x0
      let ry0 = y0
      let rx1 = x1
      let ry1 = y1
      if (relativeWidth && relativeHeight) {
        const px0 = (x0 / relativeWidth) * 100
        const px1 = (x1 / relativeWidth) * 100
        const py0 = (y0 / relativeHeight) * 100
        const py1 = (y1 / relativeHeight) * 100
        rx0 = (px0 / 100) * canvasContext.canvas.width
        rx1 = (px1 / 100) * canvasContext.canvas.width
        ry0 = (py0 / 100) * canvasContext.canvas.height
        ry1 = (py1 / 100) * canvasContext.canvas.height
      }

      canvasContext.beginPath()
      canvasContext.moveTo(rx0, ry0)
      canvasContext.lineTo(rx1, ry1)
      canvasContext.strokeStyle = color
      canvasContext.lineWidth = width
      canvasContext.stroke()
      canvasContext.closePath()
    },
    [canvasContext],
  )

  const drawLineLabel = useCallback(
    (text: string, x: number, y: number, relativeWidth: number, relativeHeight: number) => {
      if (!canvasContext) {
        return
      }

      const px = (x / relativeWidth) * 100
      const py = (y / relativeHeight) * 100
      const rx = (px / 100) * canvasContext.canvas.width
      const ry = (py / 100) * canvasContext.canvas.height

      // Set font styles.
      canvasContext.font = "10px Gilroy"

      // Draw label background.
      const width = canvasContext.measureText(text).width + 12
      const height = 16
      const labelPosition = {
        x: rx > canvasContext.canvas.width / 2 ? rx - width - 2 : rx + 2,
        y: ry > canvasContext.canvas.height / 2 ? ry - height - 2 : ry - 14,
      }
      canvasContext.fillStyle = "rgba(255, 255, 255, 0.9)"
      canvasContext.beginPath()
      // canvasContext.roundRect(labelPosition.x, labelPosition.y, width, height, [20]) // Stopped working :(
      canvasContext.rect(labelPosition.x, labelPosition.y, width, height)
      canvasContext.fill()

      // Draw label text.
      canvasContext.fillStyle = "#243746" // TODO: Take from theme colors.
      canvasContext.fillText(text, labelPosition.x + 6, labelPosition.y + 11)
    },
    [canvasContext],
  )

  // Draw annotations data.
  const draw = useCallback(
    (data: AnnotationData, parentObjectSettings: AnnotationObjectSettings) => {
      for (let i = 0; i < data.points.length; i++) {
        const firstPoint = data.points[i]
        const secondPoint = data.points[i + 1]
        if (firstPoint && secondPoint) {
          drawLine(
            {
              x0: firstPoint.x,
              y0: firstPoint.y,
              x1: secondPoint.x,
              y1: secondPoint.y,
              color: data.color,
              width: data.width,
              relativeWidth: parentObjectSettings.width,
              relativeHeight: parentObjectSettings.height,
            },
            0,
          )
        }
      }
    },
    [drawLine],
  )

  const drawLabel = useCallback(
    (annotation: Annotation) => {
      if (!eventUsers) return

      const user = eventUsers.find((u) => u.id === annotation.userId)
      if (user) {
        const lastPoint = annotation.data.points[annotation.data.points.length - 1]
        drawLineLabel(user.name.first, lastPoint.x, lastPoint.y, annotation.object.width, annotation.object.height)
      }
    },
    [eventUsers, drawLineLabel],
  )

  // Activate/Deactivate data producer for drawing.
  useEffect(() => {
    if (connection?.state === connectionStates.CONNECTED && !readOnly) {
      initiateDataProducer(mediaDataTypes.ANNOTATIONS)

      return () => {
        closeDataProducer(mediaDataTypes.ANNOTATIONS)
      }
    }
  }, [connection?.state, readOnly, initiateDataProducer, closeDataProducer])

  // Setup mouse listeners.
  useEffect(() => {
    if (readOnly || !canvasEl || !user?.id) {
      return
    }

    let isDrawing = false

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault()
      isDrawing = true
      pointsInDraw.current.push({ x: e.offsetX, y: e.offsetY })
    }

    const onMouseMove = (e: MouseEvent) => {
      e.preventDefault()
      if (isDrawing) {
        // Get the previous point;
        const prevPoint = pointsInDraw.current[pointsInDraw.current.length - 1]
        const x0 = prevPoint?.x
        const y0 = prevPoint?.y
        const x1 = e.offsetX
        const y1 = e.offsetY
        pointsInDraw.current.push({ x: x1, y: y1 })

        if (x0 && y0) {
          // Draw the line locally.
          drawLine({ x0, y0, x1, y1 })

          // Send it to remote.
          const objectSettings = { id, width: canvasEl.offsetWidth, height: canvasEl.offsetHeight }
          sendAnnotation(
            formAnnotationData(
              objectSettings,
              [
                { x: x0, y: y0 },
                { x: x1, y: y1 },
              ],
              user.id,
              lineColor,
              lineWidth,
              clearIn,
            ),
          )
        }
      }
    }

    const onMouseUp = () => {
      if (isDrawing) {
        isDrawing = false

        // Add the points to the archive.
        const parentObject = { id, width: canvasEl.offsetWidth, height: canvasEl.offsetHeight }
        annotationArchive.current.push(
          formAnnotationData(parentObject, pointsInDraw.current, user.id, lineColor, lineWidth, clearIn),
        )
        sendAnnotation(
          formAnnotationData(
            parentObject,
            [pointsInDraw.current[pointsInDraw.current.length - 1]],
            user.id,
            lineColor,
            lineWidth,
            clearIn,
            true,
          ),
        )
        pointsInDraw.current = []
      }
    }

    canvasEl.addEventListener("pointerdown", onMouseDown, false)
    canvasEl.addEventListener("pointerup", onMouseUp, false)
    canvasEl.addEventListener("pointerout", onMouseUp, false)
    canvasEl.addEventListener("pointermove", onMouseMove, false)

    // Clear out the listeners.
    return () => {
      canvasEl.removeEventListener("pointerdown", onMouseDown)
      canvasEl.removeEventListener("pointerup", onMouseUp)
      canvasEl.removeEventListener("pointerout", onMouseUp)
      canvasEl.removeEventListener("pointermove", onMouseMove)
    }
  }, [id, user?.id, readOnly, canvasEl, drawLine])

  // Listen for incoming annotations.
  useEffect(() => {
    // Parses the incoming annotation data and draws it locally.
    const parseAndDraw = (message: string) => {
      const annotation: Annotation = JSON.parse(message)
      if (annotation.type !== mediaDataTypes.ANNOTATIONS || annotation.object?.id !== id) {
        return
      }

      draw(annotation.data, annotation.object)

      if (remoteAnnotationInDraw.current?.[annotation.userId]) {
        remoteAnnotationInDraw.current[annotation.userId].data.points.push(...annotation.data.points)
      } else {
        remoteAnnotationInDraw.current = { [annotation.userId]: annotation }
      }

      if (annotation.concludesElement) {
        // Add to archive.
        annotationArchive.current.push({
          ...remoteAnnotationInDraw.current[annotation.userId],
          concludesElement: true,
          createdAt: new Date().toISOString(),
        })

        const isLabelDrawnAlready =
          annotationArchive.current.filter((a) => a.userId === annotation.userId && a.concludesElement).length > 1

        // Label the annotation.
        if (!isLabelDrawnAlready) {
          drawLabel(annotation)
        }

        delete remoteAnnotationInDraw.current[annotation.userId]
      }
    }

    dataConsumerIds.forEach((id) => {
      const dataConsumer = dataConsumers[id]
      if (dataConsumer) {
        dataConsumer.on("message", parseAndDraw)
      }
    })

    return () => {
      dataConsumerIds.forEach((id) => {
        const dataConsumer = dataConsumers[id]
        if (dataConsumer) {
          dataConsumer.off("message", parseAndDraw)
        }
      })
    }
  }, [dataConsumerIds, id, eventUsers, draw, drawLabel])

  // Set up a listener that will clear out outdated elements on the canvas.
  useEffect(() => {
    if (!canvasContext) {
      return
    }

    const dataClearInterval = window.setInterval(() => {
      const now = new Date().getTime()
      annotationArchive.current = annotationArchive.current.filter(
        (a) => !a.clearIn || new Date(a.createdAt).getTime() + a.clearIn > now,
      )
      // Clear the canvas completely.
      canvasContext.clearRect(0, 0, canvasContext.canvas.width, canvasContext.canvas.height)
      // Re-draw the valid elements.
      annotationArchive.current.forEach((a, i) => {
        draw(a.data, a.object)
        if (a.concludesElement && i === annotationArchive.current.length - 1) {
          drawLabel(a)
        }
      })
      // Re-draw the points being drawn.
      const object = { id, width: canvasContext.canvas.width, height: canvasContext.canvas.height }
      draw({ points: pointsInDraw.current, color: lineColor, width: lineWidth }, object)
      // Re-draw the remote points being drawn.
      Object.keys(remoteAnnotationInDraw?.current || {}).forEach((id) => {
        if (remoteAnnotationInDraw.current?.[id]) {
          draw(remoteAnnotationInDraw.current[id].data, remoteAnnotationInDraw.current[id].object)
        }
      })
    }, 2000)

    return () => window.clearInterval(dataClearInterval)
  }, [id, canvasContext, draw])

  // Handle canvas resize.
  useEffect(() => {
    if (!canvasEl || !videoEl) return

    const context = canvasEl.getContext("2d") as CanvasRenderingContext2D
    setCanvasContext(context)

    const onVideoResize = () => {
      context.canvas.width = videoEl.offsetWidth
      context.canvas.height = videoEl.offsetHeight
      // Redraw lines on canvas resize.
      annotationArchive.current.forEach((a) => draw(a.data, a.object))
    }

    // Listen for canvas resize event.
    const resizeObserver = new ResizeObserver(onVideoResize)
    resizeObserver.observe(videoEl)

    // Unsubscribe from canvas resize listener.
    return () => resizeObserver.disconnect()
  }, [canvasEl, videoEl, draw])
}
