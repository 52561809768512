import React, { useEffect, useState } from "react"
import { Box } from "rebass"
import styled, { keyframes } from "styled-components"
import { position, space } from "styled-system"

import { colors } from "../etc/theme"
import { Button } from "./Button"

type Props = {
  size: string
  centered?: boolean
  color?: string
  title?: string
  showReloadButtonTimeout?: number
}

const spinAnimation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`
const defaultSize = "35px"

const Spinner = styled.div<Props>`
  ${position}
  ${space}
  display: inline-block;
  width: ${({ size = defaultSize }) => size};
  height: ${({ size = defaultSize }) => size};
  margin: 0 auto;
  position: ${({ centered }) => (centered ? "absolute" : "relative")};
  left: ${({ centered, size = defaultSize }) => (centered ? `calc(50% - ${size} / 2)` : "auto")};
  top: ${({ centered, size = defaultSize }) => (centered ? `calc(50% - ${size} / 2)` : "auto")};
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: ${({ color }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return `5px solid ${colors?.[color] || color || "#ffffff"}`
  }};
  border-radius: 50%;
  font-size: 10px;
  text-indent: -9999px;
  transform: translateZ(0);
  animation: ${spinAnimation} 1.1s infinite linear;
  &::after {
    border-radius: 50%;
    width: ${({ size = "35px" }) => size};
    height: ${({ size = "35px" }) => size};
  }
`

export const Loader: React.FC<Props> = ({ title, showReloadButtonTimeout, ...props }) => {
  const [showReloadButton, setShowReloadButton] = useState(false)

  const onReload = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (showReloadButtonTimeout) {
      window.setTimeout(() => {
        setShowReloadButton(true)
      }, showReloadButtonTimeout)
    }
  }, [showReloadButtonTimeout])

  return (
    <>
      <Spinner {...props} />
      {title && <Box mt="20px">{title}</Box>}
      {showReloadButton && (
        <Button variant="dark" mt="20px" fontWeight="normal" onClick={onReload}>
          Reload
        </Button>
      )}
    </>
  )
}
