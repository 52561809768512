// TODO: Move to a separate file.
const enumTitles = {
  EventStatus: {
    UNKNOWN: "Unknown",
    REGISTRATION_OPEN: "Registration open",
    REGISTRATION_CLOSED: "Registration closed",
    PATIENT_ARRIVED: "Patient arrived",
    PATIENT_PREPARED: "Patient prepared",
    TIMED_OUT: "Timed out",
    LIVE: "Live",
    STOPPED: "Past event",
    CANCELLED: "Cancelled",
  },
  EventStreamType: {
    REGULAR: "Regular",
    PRERECORDED: "Prerecorded",
  },
  CommonStatus: {
    UNKNOWN: "Unknown",
    INACTIVE: "Inactive",
  },
  UserRole: {
    UNKNOWN: "Unknown",
    GLOBAL_ADMIN: "Global Admin",
    MARKETING: "Marketing",
    CUSTOMER_SUCCESS: "Customer Success",
    EVENT_SUCCESS: "Event Success",
    FIELD_SUPPORT_ENGINEER: "Field Support Engineer",
    USER: "User",
    STREAMER: "Streamer",
    JOIN_CODE: "Join Code User",
    IMMERTEC_EMPLOYEE: "Immertec Employee",
  },
  OrganizationRole: {
    UNKNOWN: "Unknown",
    ADMIN: "Admin",
    MANAGER: "Manager",
    MEMBER: "Member",
    TRAINEE: "Trainee",
    JOIN_CODE: "Join Code Org User",
  },
}

const namePrefixes = ["Dr", "Mr", "Mrs", "Ms"]
const nameSuffixes = ["DDM", "DDS", "DO", "DPM", "EdD", "MD", "MPH", "PhD", "RN"]

export type NamePrefix = typeof namePrefixes[number]
export type NameSuffix = typeof nameSuffixes[number]

type EnumTitles = typeof enumTitles
type Enums = { [Property in keyof EnumTitles]: { [Sub in keyof EnumTitles[Property]]: Sub } }

// Format enums to be {UNKNOWN: "UNKNOWN", REGISTRATION_OPEN: "REGISTRATION_OPEN",...}
export const enums = Object.entries(enumTitles).reduce(
  (prev, [key, vals]) => ({
    ...prev,
    [key]: Object.keys(vals).reduce((prev, val) => ({ ...prev, [val]: val }), {}),
  }),
  {},
) as Enums
