import type { Codec } from "../mediaServer/model"
import type { Device } from "../../enums"

export type ResolutionSettings = {
  width: number
  height: number
  title: string
  allowed: boolean
}

export type VRSettings = {
  device?: Device
  resolution: ResolutionSettings
  hShift: number
  vShift: number
  fov: number
  swap: boolean
}

type OverlayID = string

export type OverlaySettings = {
  id: OverlayID
  index: number
  device: Device
  resolution: ResolutionSettings
  label?: string // Label of overlay set by user.
}

export type OverlaysSettings = {
  [key: OverlayID]: OverlaySettings
}

export type MicrophoneSettings = {
  device?: Device
}

export type SpeakerSettings = {
  device?: Device
  level: number
}

export type BitratesSettings = {
  vr: number
  overlays: number
}

export type SimulcastSVCSettings = {
  codec: Codec
  vr: boolean
  overlays: boolean
}

export type GatherMediaStatsSettings = {
  vr: boolean
  overlays: boolean
}

export type WebRTCTransportNames = {
  vr: string
  overlays: string
  mic: string
}

export type MediaSettings = {
  vr: VRSettings
  overlays: OverlaysSettings
  microphone: MicrophoneSettings
  speaker: SpeakerSettings
  bitrates: BitratesSettings
  simulcastSvc: SimulcastSVCSettings
  resolutions: ResolutionSettings[]
  gatherMediaStats: GatherMediaStatsSettings
  webrtcTransportNames: WebRTCTransportNames
}

type AuthSettings = {
  token: string
}

export const storageKeys = {
  MEDIA: "mediaSettings",
  AUTH: "auth",
} as const

export type StorageKey = (typeof storageKeys)[keyof typeof storageKeys]

export type StorageVal<T> = T extends typeof storageKeys.MEDIA
  ? MediaSettings
  : T extends typeof storageKeys.AUTH
  ? AuthSettings
  : never

export type Storage = {
  get: <T extends StorageKey>(key: T) => Promise<StorageVal<T>>
  set: <V extends StorageKey>(key: V, val: StorageVal<V>) => void
}

export const get = (key: StorageKey) => {
  if (window.electronAPI?.storage) {
    return window.electronAPI.storage.get(key)
  }
  return JSON.parse(window.localStorage.getItem(key) || "{}")
}

export const set = (key: StorageKey, val: StorageVal<StorageKey>) => {
  if (window.electronAPI?.storage) {
    window.electronAPI.storage.set(key, val)
  } else {
    window.localStorage.setItem(key, JSON.stringify(val))
  }
}
