import type { NormalizedInstructor, GuestInstructor } from "../libs/api/model"

export const getEventMainInstructor = (
  instructor?: NormalizedInstructor,
  guestInstructors?: readonly GuestInstructor[],
) => {
  return instructor || (guestInstructors?.[0] as NormalizedInstructor)
}

export const getEventMainInstructorName = (
  instructor?: NormalizedInstructor,
  guestInstructors?: readonly GuestInstructor[],
) => {
  const eventInstructor = getEventMainInstructor(instructor, guestInstructors)
  if (!eventInstructor) {
    return ""
  }

  const name = [
    eventInstructor.name.prefix,
    eventInstructor.name.first,
    eventInstructor.name.alias && `"${eventInstructor.name.alias}"`,
    eventInstructor.name.middle,
    eventInstructor.name.last,
    eventInstructor.name.suffix,
  ]

  return name.join(" ")
}
