import styled from "styled-components"
import { colors } from "../../etc/theme"

export const LevelInput = styled.input.attrs(() => ({
  type: "range",
  min: 0,
  max: 100,
}))`
  width: 245px;
  height: 2px;
  margin: 26px 20px 0 0;
  appearance: none;
  background: ${colors.light};
  outline: none;
  &::-moz-range-progress {
    background-color: ${colors.dark};
  }
  &::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    width: 21px;
    height: 21px;
    position: relative;
    border-radius: 50%;
    background: ${colors.dark};
  }
  &::-moz-range-thumb {
    width: 21px;
    height: 21px;
    position: relative;
    border-radius: 50%;
    background: ${colors.dark};
  }
`
