import React from "react"
import { Flex, Box } from "rebass"
import { lighten } from "polished"
import styled from "styled-components"
import { AlertCircleOutline, WarningOutline, InformationOutline, CloseOutline } from "react-ionicons"
import { colors } from "../etc/theme"

type Props = {
  children: React.ReactNode
  onClose: () => void
  warning?: boolean
  error?: boolean
}

type WrapperProps = {
  isWarning?: boolean
  isError?: boolean
}

const Wrapper = styled(Flex)<WrapperProps>`
  width: 400px;
  position: relative;
  padding: 8px 10px;
  align-items: center;
  border-radius: 2px;
  background: ${({ isWarning, isError }) =>
    isWarning ? lighten(0.1, colors.yellow) : isError ? lighten(0.1, colors.red) : lighten(0.1, colors.dark)};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
  cursor: default;
  &:hover {
    opacity: 1;
  }
`

const CloseButton = styled(CloseOutline)`
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`

export const Alert: React.FC<Props> = ({ children, onClose, warning, error }) => {
  return (
    <Wrapper mb={2} isWarning={warning} isError={error}>
      {warning ? (
        <WarningOutline color={lighten(0.1, colors.red)} width="30px" height="30px" />
      ) : error ? (
        <AlertCircleOutline color={lighten(0.1, colors.black)} width="30px" height="30px" />
      ) : (
        <InformationOutline color={lighten(0.1, colors.blue)} width="30px" height="30px" />
      )}
      <Box ml="6px">{children}</Box>
      <CloseButton onClick={onClose} />
    </Wrapper>
  )
}
