import React, { useState } from "react"
import { Flex, Box, Heading } from "rebass"
import { Label, Input } from "@rebass/forms"
import { Button } from "./Button"
import { colors } from "../etc/theme"

export type TSignInProps = {
  email: string
  password: string
}

type Props = {
  onFormSubmit: (props: TSignInProps) => void
  isLoading?: boolean
  error?: string | null
}

const SignInForm: React.FC<Props> = ({ onFormSubmit, isLoading, error }) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onFormSubmit({ email, password })
  }

  return (
    <Flex flexDirection="column">
      <Heading textAlign="center" mb="10px">
        Authentication
      </Heading>
      <Flex as="form" onSubmit={onSubmit} py={3} flexDirection="column" width="340px" p="20px" bg={colors.darkgray}>
        <Box mb="15px">
          <Label htmlFor="email" mb="5px">
            Email
          </Label>
          <Input
            id="email"
            name="email"
            type="email"
            required={true}
            placeholder="Enter your Immertec's email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box mb="20px">
          <Label htmlFor="password" mb="5px">
            Password
          </Label>
          <Input
            id="password"
            name="password"
            type="password"
            required={true}
            placeholder="Enter your Immertec's password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        {error && (
          <Box m="-10px 0 20px" fontSize="12px" color={colors.red}>
            {error}
          </Box>
        )}
        <Button type="submit" isLoading={isLoading}>
          Sign In
        </Button>
      </Flex>
    </Flex>
  )
}

export default SignInForm
