import React from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { Flex, Box, BoxProps, Text } from "rebass"
import Swal from "sweetalert2"

import { Button } from "../Button"

import { auth } from "../../etc/firebase"
import { colors } from "../../etc/theme"
import { ReloadButton } from "../ReloadButton"
import { ChevronBackOutline, ExitOutline } from "react-ionicons"

type Props = BoxProps & {
  children: React.ReactNode
}

export const AppShell: React.FC<Props> = ({ children, ...props }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const isMediaCheckPage = location.pathname.startsWith("/media-check")

  const shutDown = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    Swal.fire({
      title: "Would you like to shut down the system?",
      text: "This will turn the system off completely",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.red,
      cancelButtonColor: colors.primary,
      confirmButtonText: "Shut Down",
    }).then((result) => {
      if (result.value) {
        window.electronAPI.shutDown()
      }
    })
  }

  const signOut = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    Swal.fire({
      title: "Are you sure?",
      text: "You will have to contact Immertec to be able to log back into this device!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.red,
      cancelButtonColor: colors.primary,
      confirmButtonText: "Sign Out",
    }).then((result) => {
      if (result.value) {
        auth.signOut()
        navigate("/")
      }
    })
  }

  return (
    <Box {...props}>
      <Flex justifyContent="space-between" width={1} p={4}>
        <Flex>
          <Box onClick={shutDown} sx={{ cursor: "pointer" }}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5371 1.92734C15.5084 1.91289 15.4765 1.90604 15.4444 1.90747C15.4123 1.90889 15.3811 1.91854 15.3538 1.93548C15.3265 1.95242 15.304 1.97608 15.2885 2.00421C15.273 2.03233 15.265 2.06397 15.2652 2.09609V3.60078C15.2652 3.72969 15.3332 3.84922 15.441 3.91953C15.9695 4.2522 16.4586 4.64376 16.8988 5.08672C17.6652 5.85547 18.2675 6.74844 18.6871 7.74219C19.1209 8.77093 19.3433 9.87648 19.341 10.993C19.341 12.1203 19.1207 13.2148 18.6871 14.2438C18.268 15.2361 17.6607 16.1378 16.8988 16.8992C16.1394 17.6631 15.2381 18.2713 14.2457 18.6898C13.2191 19.1258 12.1246 19.3461 10.9996 19.3461C9.87457 19.3461 8.78004 19.1258 7.75347 18.6898C6.76099 18.2713 5.85975 17.6631 5.10035 16.8992C4.3384 16.1378 3.73112 15.2361 3.31207 14.2438C2.8782 13.215 2.65581 12.1095 2.65816 10.993C2.65816 9.86563 2.87847 8.77109 3.31207 7.74219C3.7316 6.74844 4.33394 5.85547 5.10035 5.08672C5.54566 4.64141 6.03316 4.25234 6.55816 3.91953C6.66832 3.85156 6.73394 3.72969 6.73394 3.60078V2.09375C6.73394 1.95312 6.58629 1.86406 6.46207 1.925C3.18316 3.575 0.921443 6.95234 0.874568 10.8664C0.808943 16.4633 5.38629 21.1133 10.9808 21.125C16.5824 21.1367 21.1246 16.5945 21.1246 10.9906C21.1246 7.025 18.8511 3.59141 15.5371 1.92734ZM10.2496 12.125H11.7496C11.8527 12.125 11.9371 12.0406 11.9371 11.9375V1.0625C11.9371 0.959375 11.8527 0.875 11.7496 0.875H10.2496C10.1464 0.875 10.0621 0.959375 10.0621 1.0625V11.9375C10.0621 12.0406 10.1464 12.125 10.2496 12.125Z"
                fill={colors.white}
              />
            </svg>
          </Box>
          <ReloadButton ml="10px" />
        </Flex>

        <Flex>
          <Button as={Link} to={isMediaCheckPage ? "/events" : "/media-check"} mr={2}>
            {isMediaCheckPage ? (
              <>
                <ChevronBackOutline width="20px" height="20px" color={colors.white} style={{ marginBottom: "-5px" }} />{" "}
                Go Back
              </>
            ) : (
              <>Check Media</>
            )}
          </Button>
          <Button variant="outline" onClick={signOut}>
            <ExitOutline width="20px" height="20px" color={colors.white} style={{ marginBottom: "-5px" }} />
            <Text ml="5px">Sign Out</Text>
          </Button>
        </Flex>
      </Flex>
      {children}
    </Box>
  )
}
