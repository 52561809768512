type EncodingParams = {
  maxBitrate: number
  useSimulcast?: boolean
  useSVC?: boolean
}

export const VR_TRACK_ID = "vr"

// NOTE: May be versatile if maxBitrate too big (https://www.w3.org/TR/webrtc/#dom-rtcrtpencodingparameters-maxbitrate).
export const getEncodings = ({ maxBitrate, useSimulcast = false, useSVC = false }: EncodingParams) => {
  let encodings: RTCRtpEncodingParameters[] = [{ maxBitrate }]

  if (useSimulcast) {
    // NOTE: 3 is maximum that is supported by the browser engine.
    // Reference: https://mediasoup.discourse.group/t/simulcast-layers/2664
    const simulcastLayersAmount = 3
    encodings = []
    for (let i = simulcastLayersAmount; i > 0; i--) {
      encodings.push({ maxBitrate: Math.trunc(maxBitrate / i) })
    }
  }

  if (useSVC) {
    // NOTE: Setting maxBitrate is not supported with SVC.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    encodings = [{ scalabilityMode: "S3T3_KEY" }]
  }

  return encodings
}
