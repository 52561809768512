import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Flex, Box } from "rebass"
import { CloudCircleOutline, Exit, StopCircleOutline } from "react-ionicons"
import ReactTooltip from "react-tooltip"
import Swal from "sweetalert2"
import { skipToken } from "@reduxjs/toolkit/query"
import { convertSecToHHMMSS, showError } from "../../../helpers"
import { Loader } from "../../../components/Loader"
import { useUpdateEventStatusMutation, useUpdateEventSettingsMutation, enums } from "../../../libs/api"
import logger from "../../../etc/logger"
import {
  useGetMediaServerUrlQuery,
  useGetConnectionStateQuery,
  useConnectMutation,
  useDisconnectMutation,
  useSendUserActionMutation,
  useGetSettingsQuery,
} from "../../../libs/mediaServer"
import { connectionStates } from "../../../libs/mediaServer/enums"
import { Button } from "../../../components/Button"
import { colors } from "../../../etc/theme"
import { buttonTitles, EventStatus, ButtonTitle } from "../../../enums"
import { UserActionKind } from "../../../libs/mediaServer/model"
import { useSelector } from "react-redux"
import { selectMediaSettings } from "../../../redux/mediaSettingsSlice"

type Props = {
  setIsConnectionInitiated: (s: boolean) => void
}

export const StreamStateControl: React.FC<Props> = ({ setIsConnectionInitiated }) => {
  const [streamingTime, setStreamingTime] = useState(0)
  const [streamState, setStreamState] = useState<EventStatus>()
  const [isRecording, setIsRecording] = useState(false)

  const params = useParams()
  const navigate = useNavigate()
  const eventId = params.eventId as string
  const mediaSettings = useSelector(selectMediaSettings)

  const {
    data: mediaServerUrl,
    error: mediaServerUrlError,
    isLoading: isMediaServerUrlPreparing,
  } = useGetMediaServerUrlQuery(eventId)

  const [connect, { isUninitialized }] = useConnectMutation()
  const { data: connection } = useGetConnectionStateQuery(isUninitialized ? skipToken : undefined)
  const { data: settings } = useGetSettingsQuery(isUninitialized ? skipToken : undefined)
  const [disconnect] = useDisconnectMutation()
  const [sendUserAction] = useSendUserActionMutation()
  const [updateEventStatus, { isLoading: isUpdatingEventStatus }] = useUpdateEventStatusMutation()
  const [updateEventSettings, { error: updateEventSettingsError }] = useUpdateEventSettingsMutation()

  const broadcastingTimeFormatted = convertSecToHHMMSS(streamingTime)
  const isReconnectingToMediaServer = connection?.state === connectionStates.RECONNECTING
  const isLoading = isUpdatingEventStatus || isReconnectingToMediaServer || isMediaServerUrlPreparing

  const buttonTitle = useMemo(() => {
    let title: ButtonTitle

    switch (streamState) {
      case enums.EventStatus.PATIENT_ARRIVED:
        title = buttonTitles.PATIENT_PREPARED
        break
      case enums.EventStatus.PATIENT_PREPARED:
        title = buttonTitles.TIME_OUT
        break
      case enums.EventStatus.TIMED_OUT:
        title = buttonTitles.START
        break
      case enums.EventStatus.LIVE:
        title = buttonTitles.END
        break
      default:
        title = buttonTitles.PATIENT_ARRIVED
    }

    return title
  }, [streamState])

  const toggleStreamState = async () => {
    let newState: EventStatus

    // Set the new state based on the current one.
    switch (streamState) {
      case enums.EventStatus.PATIENT_ARRIVED:
        newState = enums.EventStatus.PATIENT_PREPARED
        break
      case enums.EventStatus.PATIENT_PREPARED:
        newState = enums.EventStatus.TIMED_OUT
        break
      case enums.EventStatus.TIMED_OUT:
        newState = enums.EventStatus.LIVE
        break
      case enums.EventStatus.LIVE:
        newState = enums.EventStatus.STOPPED
        break
      default:
        // Covers undefined (initial) and STOPPED cases.
        newState = enums.EventStatus.PATIENT_ARRIVED
    }

    try {
      if (newState === enums.EventStatus.STOPPED) {
        // If we try to stop the stream, lead the user through confirmation.
        await onExit(true)
      } else {
        // Update the event status with streamState.
        await updateEventStatus({ id: eventId, status: newState })
        setStreamState(newState)
      }
    } catch (e) {
      logger.error("Unable to update Stream status.", e)
    }
  }

  const onExit = async (endStream = false) => {
    if (streamState === enums.EventStatus.LIVE) {
      Swal.fire({
        title: "Are you sure?",
        text: endStream ? "You are live. The stream will be ended." : "The streaming feeds will be stopped.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: colors.blue,
        cancelButtonColor: colors.red,
        confirmButtonText: endStream ? "End stream" : "Exit",
      }).then(async (result) => {
        if (result.value) {
          if (endStream) {
            await updateEventStatus({ id: eventId, status: enums.EventStatus.STOPPED })
            await sendUserAction({ kind: UserActionKind.LEAVE })
          }
          navigate("/events")
        }
      })
    } else {
      navigate("/events")
    }
  }

  const recordingIcon = useMemo(() => {
    return isRecording ? (
      <StopCircleOutline color={colors.white} width="35px" height="35px" />
    ) : (
      <CloudCircleOutline color={colors.white} width="35px" height="35px" />
    )
  }, [isRecording])

  const onRecordingToggle = useCallback(() => {
    sendUserAction({
      kind: UserActionKind.RECORDING_TOGGLE,
      appData: { enableRecording: !isRecording },
    })
  }, [isRecording, sendUserAction])

  // Listen to recording settings.
  useEffect(() => setIsRecording(!!settings?.isRecording), [settings?.isRecording])

  useEffect(() => {
    if (!isUninitialized) {
      setIsConnectionInitiated(true)
    }
  }, [isUninitialized])

  useEffect(() => {
    if (streamState === enums.EventStatus.LIVE && mediaServerUrl) {
      // Initiate connection to the media server.
      connect(mediaServerUrl)
    }
  }, [streamState, mediaServerUrl])

  useEffect(() => {
    if (mediaServerUrl) {
      updateEventSettings({ id: eventId, settings: { mediaServerUrl } })
    }
  }, [mediaServerUrl])

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (connection?.state === connectionStates.CONNECTED) {
      timer = setInterval(() => {
        setStreamingTime((time) => time + 1)
      }, 1000)
    }

    if (connection?.error) {
      showError("Connection Error", connection.error.message)
        .then(() => disconnect())
        .then(() => navigate("/events"))
    }

    return () => {
      clearInterval(timer)
    }
  }, [connection])

  useEffect(() => {
    if (mediaServerUrlError) {
      let errorDescription = "Unable to connect to the media server."
      if ("data" in mediaServerUrlError) {
        errorDescription = mediaServerUrlError.data as string
      }
      showError("Connection failed", errorDescription)
    }
  }, [mediaServerUrlError])

  useEffect(() => {
    if (updateEventSettingsError && "status" in updateEventSettingsError && updateEventSettingsError.status > 304) {
      showError(
        "Unable to update event settings",
        "Something went wrong while updating the event settings. Please contact customer.success@immertec.com or your IT administrator.",
      )
    }
  }, [updateEventSettingsError])

  // Send some settings to the media server on connect.
  useEffect(() => {
    if (connection?.state === connectionStates.CONNECTED) {
      sendUserAction({
        kind: UserActionKind.MEDIA_STATS_VR_TOGGLE,
        appData: { gatherVRMediaStats: mediaSettings.gatherMediaStats.vr },
      })
      sendUserAction({
        kind: UserActionKind.MEDIA_STATS_OVERLAYS_TOGGLE,
        appData: { gatherOverlaysMediaStats: mediaSettings.gatherMediaStats.overlays },
      })
    }
  }, [connection?.state, mediaSettings.gatherMediaStats, sendUserAction])

  useEffect(() => {
    return () => {
      disconnect()
    }
  }, [])

  return (
    <Flex flexDirection="column">
      <Box textAlign="center" m="-10px 140px 15px 0">
        {isMediaServerUrlPreparing
          ? "Preparing media server..."
          : connection?.state === connectionStates.CONNECTING
          ? "Connecting..."
          : connection?.state === connectionStates.RECONNECTING
          ? "Trying to reconnect..."
          : broadcastingTimeFormatted}
      </Box>
      <Flex>
        <Button
          onClick={toggleStreamState}
          disabled={isLoading || !!mediaServerUrlError}
          variant="danger"
          mr="12px"
          p="8px 16px"
          flex={1}
        >
          {isLoading ? <Loader size="25px" /> : buttonTitle}
        </Button>

        <Button
          data-tip={isRecording ? "Stop recording" : "Record session"}
          data-for="record-btn"
          mr="8px"
          disabled={isUninitialized}
          onClick={onRecordingToggle}
        >
          {recordingIcon}
        </Button>
        <ReactTooltip id="record-btn" effect="solid" />

        <Button data-tip="Exit" data-for="exit-btn" onClick={() => onExit()}>
          <Exit color={colors.white} width="35px" height="35px" />
        </Button>
        <ReactTooltip id="exit-btn" effect="solid" />
      </Flex>
    </Flex>
  )
}
