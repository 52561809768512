import { Device } from "mediasoup-client"
import { Socket } from "socket.io-client"
import logger from "../../etc/logger"
import config from "../../etc/config"
import { transportTypes } from "./enums"

import type { RtpCapabilities } from "mediasoup-client/lib/RtpParameters"
import type { Transport, TransportOptions } from "mediasoup-client/lib/Transport"
import type { Peer, TransportType } from "./model"
import { checkUrl } from "../../helpers"
import * as Sentry from "@sentry/electron"
import { get, storageKeys } from "../storage"

const sendTransports: { [key: string]: Transport } = {}
let sendDataTransport: Transport
let receiveTransport: Transport
let receiveDataTransport: Transport
let device: Device

// Media server URL health check.
const MAX_ATTEMPTS_HEALTH_CHECK = 40
let healthCheckAttempts = 0

// Store producers that are in process of creation, so we don't create duplicates.
const producersAwaiting: string[] = []

export const isProducerAwaiting = (id: string) => producersAwaiting.includes(id)

export const addProducerToAwaiting = (id: string) => {
  producersAwaiting.push(id)
}

export const removeProducerFromAwaiting = (id: string) => {
  const index = producersAwaiting.indexOf(id)
  if (index > -1) {
    producersAwaiting.splice(index, 1)
  }
}

export const prepareDevice = async (routerRtpCapabilities: RtpCapabilities): Promise<Device> => {
  if (!device) {
    device = new Device()
    await device.load({ routerRtpCapabilities })
    logger.debug("The device is loaded!", { routerRtpCapabilities })
  }

  return device
}

export const getDevice = (): Device => {
  if (!device) {
    throw new Error("Device hasn't been prepared.")
  }

  return device
}

export const getReceiveTransport = async (mediaServer: Socket): Promise<Transport> => {
  if (!receiveTransport || receiveTransport.closed) {
    logger.debug(`Receive transport wasn't found. Creating...`)
    receiveTransport = await createTransport(transportTypes.RECEIVE, mediaServer)
  }

  return receiveTransport
}

export const getReceiveDataTransport = async (mediaServer: Socket): Promise<Transport> => {
  if (!receiveDataTransport || receiveDataTransport.closed) {
    logger.debug(`Receive data transport wasn't found. Creating...`)
    receiveDataTransport = await createTransport(transportTypes.DATA_CONSUME, mediaServer)
  }

  return receiveDataTransport
}

export const getSendTransport = async (name: string, mediaServer: Socket): Promise<Transport> => {
  if (!sendTransports[name] || sendTransports[name].closed) {
    logger.debug(`Send transport "${name}" wasn't found. Creating...`)
    sendTransports[name] = await createTransport(transportTypes.SEND, mediaServer)
  }

  return sendTransports[name]
}

export const getSendDataTransport = async (mediaServer: Socket): Promise<Transport> => {
  if (!sendDataTransport || sendDataTransport.closed) {
    logger.debug(`Send data transport wasn't found. Creating...`)
    sendDataTransport = await createTransport(transportTypes.DATA_PRODUCE, mediaServer)
  }

  return sendDataTransport
}

const createTransport = async (type: TransportType, mediaServer: Socket): Promise<Transport> =>
  new Promise((resolve, reject) => {
    try {
      mediaServer.emit(
        "transport-create",
        type,
        ({ id, iceParameters, iceCandidates, dtlsParameters, sctpParameters }: TransportOptions) => {
          const device = getDevice()
          let transport: Transport

          if (type === transportTypes.SEND || type === transportTypes.DATA_PRODUCE) {
            transport = device.createSendTransport({
              id,
              iceParameters,
              iceCandidates,
              dtlsParameters,
              sctpParameters,
              iceServers: config.media.iceServers,
              // Used for WebRTC transport (deprecated in WebRTC 1.0).
              // NOTE: googCpuOveruseDetection is not supported starting from Chromium M103, so use with caution.
              // Reference:
              //   https://bugs.chromium.org/p/chromium/issues/detail?id=1315569
              proprietaryConstraints: {
                optional: [{ googCpuOveruseDetection: false }],
              },
            })

            // Set transport "produce" event handler.
            transport.on("produce", async ({ kind, rtpParameters, appData }, callback, errback) => {
              // Here we must communicate our local parameters to our remote transport.
              mediaServer.emit(
                "producer-create",
                { transportId: transport.id, kind, rtpParameters, appData },
                (err: Error, id: string) => {
                  if (err) {
                    errback(err)
                  } else {
                    logger.debug(`Producer created: ${id} [${kind}]`, { rtpParameters })
                    callback({ id })
                  }
                },
              )
            })

            // Set transport "producedata" event handler. Gets invoked on transport.produceData(...) call.
            transport.on("producedata", async ({ sctpStreamParameters, label, appData }, callback, errback) => {
              // Here we must communicate our local parameters to our remote transport.
              mediaServer.emit(
                "data-producer-create",
                { transportId: transport.id, sctpStreamParameters, label, appData },
                (err: Error, id: string) => {
                  if (err) {
                    errback(err)
                  } else {
                    logger.debug(`Data Producer created: ${id} [${label}]`)
                    callback({ id })
                  }
                },
              )
            })
          } else {
            transport = device.createRecvTransport({
              id,
              iceParameters,
              iceCandidates,
              dtlsParameters,
              sctpParameters,
              iceServers: config.media.iceServers,
            })
          }

          logger.debug(`Transport created: ${id} [${type}].`)

          transport.on("connect", ({ dtlsParameters }, callback, errback) => {
            mediaServer.emit("transport-connect", { transportId: transport.id, dtlsParameters }, (err: Error) => {
              if (err) {
                errback(err)
              } else {
                callback()
              }
            })
          })

          return resolve(transport)
        },
      )
    } catch (e) {
      return reject(e)
    }
  })

// Closes transport by its name, which stops tracks from being streamed/received.
// This will close all producers/consumers related to this transport.
const closeTransport = (type: TransportType, mediaServer: Socket, name?: string): void => {
  if (type === transportTypes.SEND && name && sendTransports[name] && !sendTransports[name].closed) {
    if (mediaServer.connected) {
      mediaServer.emit("transport-close", sendTransports[name].id)
    }
    sendTransports[name].close()
    delete sendTransports[name]
    logger.debug(`Send Transport "${name}" closed.`)
  } else if (type === transportTypes.RECEIVE && receiveTransport && !receiveTransport.closed) {
    receiveTransport.close()
    logger.debug("Receive Transport closed.")
  } else if (type === transportTypes.DATA_PRODUCE && sendDataTransport && !sendDataTransport.closed) {
    sendDataTransport.close()
    logger.debug("Send data transport closed.")
  } else if (type === transportTypes.DATA_CONSUME && receiveDataTransport && !receiveDataTransport.closed) {
    receiveDataTransport.close()
    logger.debug("Receive data transport closed.")
  } else {
    logger.warn(`Transport ${name || ""} [${type}] doesn't exist or already closed.`)
  }
}

const getUniqueSendTransportNames = () => {
  const mediaSettings = get(storageKeys.MEDIA)
  return [...new Set(Object.values(mediaSettings.webrtcTransportNames))] as string[]
}

export const prepareTransports = (mediaServer: Socket) => {
  return Promise.all([
    getReceiveTransport(mediaServer),
    getReceiveDataTransport(mediaServer),
    getSendDataTransport(mediaServer),
    ...getUniqueSendTransportNames().map((n) => getSendTransport(n, mediaServer)),
  ])
}

export const closeTransports = (mediaServer: Socket) => {
  closeTransport(transportTypes.RECEIVE, mediaServer)
  closeTransport(transportTypes.DATA_PRODUCE, mediaServer)
  closeTransport(transportTypes.DATA_CONSUME, mediaServer)
  getUniqueSendTransportNames().forEach((name) => {
    closeTransport(transportTypes.SEND, mediaServer, name)
  })
}

// Compares two arrays of active speakers.
export const isActiveSpeakersEqual = (oldSpeakers: Peer[], newSpeakers: Peer[]): boolean => {
  const isNewHasOld = oldSpeakers.every((oldSpeaker) =>
    newSpeakers.find((newSpeaker) => oldSpeaker.id === newSpeaker.id),
  )
  const isOldHasNew = newSpeakers.every((newSpeaker) =>
    oldSpeakers.find((oldSpeaker) => newSpeaker.id === oldSpeaker.id),
  )

  return isNewHasOld && isOldHasNew
}

export const checkUrlHealth = async (url: string): Promise<boolean> => {
  logger.debug(`Checking url ${url}...`)
  healthCheckAttempts++

  try {
    await checkUrl(url)
    healthCheckAttempts = 0
    return true
  } catch (e) {
    if (healthCheckAttempts >= MAX_ATTEMPTS_HEALTH_CHECK) {
      healthCheckAttempts = 0
      Sentry.captureMessage(`Media server is unhealthy: ${url}.`, Sentry.Severity.Error)
      return false
    }

    if (healthCheckAttempts > 5) {
      logger.warn(`Media server is unhealthy (${healthCheckAttempts}/${MAX_ATTEMPTS_HEALTH_CHECK}).`)
    }

    // Try again in 5 seconds. With 40 tries it will take ~3.5min in total.
    await new Promise((r) => setTimeout(r, 5000))
    return checkUrlHealth(url)
  }
}

export const stopUrlHealthCheck = () => {
  // A trick to stop checking the url if it's still in progress.
  if (healthCheckAttempts > 0) {
    healthCheckAttempts = MAX_ATTEMPTS_HEALTH_CHECK
  }
}
