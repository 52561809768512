import React from "react"
import ReactTooltip from "react-tooltip"
import { LevelInput } from "../LevelInput"

type Props = {
  sensitivity: number
  setSensitivity: (v: number) => void
}

export const MicSensitivityControl: React.FC<Props> = ({ sensitivity, setSensitivity }) => {
  const muted = sensitivity === 0

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSensitivity(parseInt(e.currentTarget.value))
  }

  return (
    <>
      <LevelInput
        data-tip="Microphone sensitivity"
        data-for="microphone-sensitivity"
        style={{ opacity: muted ? 0.5 : 1 }}
        value={sensitivity}
        onChange={onInputChange}
      />
      <ReactTooltip id="microphone-sensitivity" effect="solid" />
    </>
  )
}
