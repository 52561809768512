import React, { useMemo } from "react"
import { Flex, Box, Image, Heading, Text } from "rebass"
import { Link as RouterLink } from "react-router-dom"
import { skipToken } from "@reduxjs/toolkit/query"

import { AppShell } from "../../components/Shell/AppShell"
import { Loader } from "../../components/Loader"
import { Event } from "./Event"
import { Button } from "../../components/Button"

import { useListEventsQuery, useGetUserQuery, enums } from "../../libs/api"
import { todayStartTime } from "../../helpers"
import { auth } from "../../etc/firebase"

import logo from "../../assets/images/logo/immertec-icon.png"

export const Events: React.FC = () => {
  const { data: eventsData, isLoading } = useListEventsQuery(
    { filter: { endAfter: todayStartTime, streamType: enums.EventStreamType.REGULAR } },
    { pollingInterval: 10000 },
  )
  const { data: userData } = useGetUserQuery(auth.currentUser?.uid?.toString() || skipToken)

  const events = useMemo(() => {
    if (!eventsData || !userData) {
      return []
    }

    const now = Date.now()
    const today = new Date().toDateString()

    return eventsData
      .filter(
        (event) =>
          event.status !== enums.EventStatus.CANCELLED &&
          // Consider only events supposed to start today or in the past.
          (event.startTime <= now || new Date(event.startTime).toDateString() === today) &&
          // Do not consider past events.
          event.endTime > now,
      )
      .filter((event) => {
        const userOrg = userData.organizations.find((o) => o.organization === event.organization)
        return (
          userOrg?.role === enums.OrganizationRole.ADMIN ||
          userOrg?.role === enums.OrganizationRole.MANAGER ||
          userData.roles.includes(enums.UserRole.GLOBAL_ADMIN) ||
          userData.roles.includes(enums.UserRole.STREAMER)
        )
      })
      .sort((a, b) => a.startTime - b.startTime)
  }, [eventsData, userData])

  return (
    <AppShell maxHeight="100vh" sx={{ overflow: "hidden" }}>
      <Flex flexDirection="column" alignItems="center" alignSelf="center" textAlign="center">
        <Image src={logo} />
        <Heading fontSize={3} mt={3}>
          Choose Your Event
        </Heading>
        <Text mt={2} color="light">
          Choose which event you are instructing today.
        </Text>
      </Flex>

      <Flex flexDirection="column" mt={4} mb={6} textAlign="center">
        {isLoading && <Loader size="70px" color="primary" showReloadButtonTimeout={10000} />}

        {!isLoading && !events.length && (
          <Flex alignSelf="center" flexDirection="column">
            <Text>No events found.</Text>
            <Button as={RouterLink} to="/" mt={3} variant="secondary">
              Back
            </Button>
          </Flex>
        )}

        {!isLoading && !!events.length && (
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            alignSelf="center"
            alignContent="stretch"
            height="calc(100vh - 255px)"
            overflowY="auto"
            maxWidth={1480}
          >
            {events.map((event) => (
              <Box key={event.id} width={[1, 1 / 2, 1 / 2, 1 / 4]} minWidth={315} mb={5}>
                <Event id={event.id} />
              </Box>
            ))}
          </Flex>
        )}
      </Flex>
    </AppShell>
  )
}
