import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { setDevices } from "../redux/deviceSlice"
import logger from "../etc/logger"
import { showError } from "../helpers"

export const useMediaDevices = (): void => {
  const dispatch = useDispatch()
  const alreadyAskedForUserMedia = useRef(false)
  const alreadyCaught = useRef(false)

  useEffect(() => {
    if (!navigator.mediaDevices) {
      throw new Error("WebRTC is not supported.")
    }

    const checkMediaDevices = (constraints: DisplayMediaStreamOptions = { audio: true, video: true }) => {
      const getUserMedia = () => {
        if (alreadyAskedForUserMedia.current) {
          return Promise.resolve()
        }

        alreadyAskedForUserMedia.current = true
        return (
          navigator.mediaDevices
            // NOTE: We need to request for media in order to get device ids.
            .getUserMedia(constraints)
            .then((stream) => {
              stream.getTracks().forEach((track) => track.stop())
              return Promise.resolve()
            })
        )
      }

      getUserMedia()
        .then(() => {
          navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
            const formattedDevices = devices
              // NOTE: We filter out devices with id "default" as they get duplicated.
              .filter((device) => device.deviceId !== "default")
              .map((device) => ({
                id: device.deviceId,
                title: device.label,
                kind: device.kind,
              }))
            logger.debug("Available devices:", formattedDevices)

            dispatch(setDevices(formattedDevices))
          })
        })
        .catch((err) => {
          logger.error("Error reading media devices:", err)
          // If it fails, most probably it's due to lack of audio devices. Repeat with only video devices.
          if (!alreadyCaught.current) {
            alreadyCaught.current = true
            showError(
              err.message,
              "Either audio or video devices haven't been found. The app may not function correctly.",
            )
            checkMediaDevices({ video: true })
          }
        })
    }

    // Initial check.
    checkMediaDevices()

    const onDeviceChange = () => checkMediaDevices()

    // Listen for devices plug/unplug.
    // NOTE: Multiple listeners will not work, so better to use the hook once.
    navigator.mediaDevices.addEventListener("devicechange", onDeviceChange)
    return () => {
      navigator.mediaDevices.removeEventListener("devicechange", onDeviceChange)
    }
  }, [dispatch])
}
