import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Box, Flex, Heading, Image, Text } from "rebass"
import { AppShell } from "../../components/Shell/AppShell"

import { selectMediaSettings, updateMicrophoneSettings, updateSpeakerSettings } from "../../redux/mediaSettingsSlice"
import { selectDevices } from "../../redux/deviceSlice"

import { deviceKinds } from "../../enums"
import type { Device } from "../../enums"
import { DeviceSelect } from "../Stream/DeviceSelect"
import { showError } from "../../helpers"
import logger from "../../etc/logger"
import { colors } from "../../etc/theme"
import { useMediaDevices } from "../../hooks/useMediaDevices"

import { Button } from "../../components/Button"

import logo from "../../assets/images/logo/immertec-icon.png"
import chimeSound from "../../assets/sounds/chime.ogg"

export const MediaCheck: React.FC = () => {
  const [micTrack, setMicTrack] = useState<MediaStreamTrack>()

  const micLevelElement = useRef<HTMLDivElement>(null)
  const speakerAudioElement = useRef<HTMLAudioElement & { setSinkId(deviceId: string): void }>(null)

  const dispatch = useDispatch()
  const mediaSettings = useSelector(selectMediaSettings)
  const devices = useSelector(selectDevices)

  // Setup listener for media devices.
  useMediaDevices()

  const onAudioPlay = () => {
    speakerAudioElement.current?.play().catch((e) => {
      logger.error(e)
      showError("Media Error", "Unable to play media through the selected device.")
    })
  }

  const onMicChange = useCallback(
    (device: Device) => {
      navigator.mediaDevices
        .getUserMedia({
          audio: { deviceId: { exact: device.id } },
          video: false,
        })
        .then((stream) => {
          setMicTrack(stream.getAudioTracks()[0])
          dispatch(updateMicrophoneSettings({ device }))
        })
        .catch((e) => {
          logger.error(e)
          showError("Media Error", "Unable to get media from requested device.")
        })
    },
    [dispatch],
  )

  const onSpeakerChange = useCallback(
    (device: Device) => {
      dispatch(updateSpeakerSettings({ device }))
    },
    [dispatch],
  )

  useEffect(() => {
    if (!micTrack) return

    const onAudioProcess = (e: AudioProcessingEvent) => {
      // Much of this is taken from https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/volume/js/soundmeter.js
      const input = e.inputBuffer.getChannelData(0)
      let sum = 0.0
      for (let i = 0; i < input.length; ++i) {
        sum += input[i] * input[i]
      }

      const micLevel = Math.round(Math.min(Math.sqrt(sum / input.length) * 1000, 100))
      if (micLevelElement.current) {
        micLevelElement.current.style.width = (micLevel > 100 ? 100 : micLevel) + "%"
      }
    }

    const audioContext = new AudioContext()
    const script = audioContext.createScriptProcessor(2048, 1, 1)
    const mic = audioContext.createMediaStreamSource(new MediaStream([micTrack]))

    mic.connect(script)
    script.connect(audioContext.destination)
    script.addEventListener("audioprocess", onAudioProcess)

    return () => {
      script.removeEventListener("audioprocess", onAudioProcess)
      micTrack.stop()
    }
  }, [micTrack])

  useEffect(() => {
    const deviceId = devices.find((d) => d.id === mediaSettings.speaker.device?.id)?.id
    if (deviceId && speakerAudioElement.current) {
      speakerAudioElement.current.setSinkId(deviceId)
    }
  }, [mediaSettings.speaker.device, devices])

  return (
    <AppShell maxHeight="100vh" sx={{ overflow: "hidden" }}>
      <Flex flexDirection="column" alignItems="center">
        <Link to="/events">
          <Image src={logo} />
        </Link>

        <Heading fontSize={3} mt={3} mb={2}>
          Check media devices
        </Heading>

        <Text color="light">Choose a microphone and inspect its level</Text>

        <Flex mb={4} mt={3} minWidth="350px">
          <DeviceSelect
            alignItems="center"
            fontSize="2"
            deviceType={deviceKinds.AUDIO_INPUT}
            defaultDevice={mediaSettings.microphone.device}
            onDeviceChange={onMicChange}
          />
          <Flex flexDirection="column" justifyContent="center" ml={3}>
            <Text fontWeight="bold" mb={2}>
              {devices.find(({ id }) => id === mediaSettings.microphone.device?.id)?.label}
            </Text>
            <Box
              width="150px"
              height="20px"
              backgroundColor={colors.dark}
              sx={{ borderRadius: "4px", overflow: "hidden" }}
            >
              <Box ref={micLevelElement} height="100%" backgroundColor={colors.gray} />
            </Box>
          </Flex>
        </Flex>

        <Text color="light">Choose a speaker and inspect its sound (chime effect)</Text>

        <Flex mt={3} minWidth="350px">
          <DeviceSelect
            alignItems="center"
            fontSize="2"
            deviceType={deviceKinds.AUDIO_OUTPUT}
            defaultDevice={mediaSettings.speaker.device}
            onDeviceChange={onSpeakerChange}
          />
          <Flex flexDirection="column" justifyContent="center" ml={3}>
            <Text fontWeight="bold" mb={2}>
              {devices.find(({ id }) => id === mediaSettings.speaker.device?.id)?.label}
            </Text>
            <Button variant="primary" onClick={onAudioPlay}>
              Play
            </Button>
            <audio ref={speakerAudioElement} preload="auto">
              <source src={chimeSound} type="audio/ogg" />
            </audio>
          </Flex>
        </Flex>
      </Flex>
    </AppShell>
  )
}
