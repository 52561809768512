import React from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Flex, Box, Heading, Link } from "rebass"
import { HelpCircleOutline } from "react-ionicons"
import { Input, Checkbox, Label } from "@rebass/forms"
import styled from "styled-components"
import Swal from "sweetalert2"

import { SelectGroup } from "../../components/SelectGroup"
import { codecs } from "../../libs/mediaServer/enums"
import { colors } from "../../etc/theme"
import logoPath from "../Stream/images/logo-mini.png"
import { auth } from "../../etc/firebase"
import { Resolutions } from "./Resolutions"
import {
  selectMediaSettings,
  setBitrates,
  setVRSettings,
  setSimulcastSvc,
  setGatherMediaStats,
  setWebRTCTransportNames,
} from "../../redux/mediaSettingsSlice"

import type { Codec } from "../../libs/mediaServer/model"
import type { BitratesSettings } from "../../libs/storage"
import ReactTooltip from "react-tooltip"

const SettingRow = styled(SelectGroup)`
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  option {
    background: ${colors.darkgray};
  }
`
const Hr = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.dark};
`

export const Settings: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mediaSettings = useSelector(selectMediaSettings)

  const onNavigateBack = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    navigate(-1)
  }

  const convertBitToKBit = (bits = 0) => {
    return bits <= 0 ? 0 : Math.round(bits / 1000)
  }

  const setBitrate = (bitrate: number, bitrateFor: keyof BitratesSettings = "vr") => {
    const bitrateSettings = { ...mediaSettings.bitrates }
    bitrateSettings[bitrateFor] = !bitrate || bitrate < 0 ? 0 : Math.round(bitrate) * 1000
    dispatch(setBitrates(bitrateSettings))
  }

  const confirmSignOut = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    Swal.fire({
      title: "Are you sure?",
      text: "You will have to contact Immertec to be able to log back into this device!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sign Out",
    }).then((result) => {
      if (result.value) {
        auth.signOut()
        navigate("/")
      }
    })
  }

  return (
    <Flex flexDirection="column" width="680px" m="0 auto 50px">
      <Link
        onClick={onNavigateBack}
        display="block"
        width="46px"
        height="50px"
        m="20px auto 10px"
        css={{ backgroundImage: `url(${logoPath})`, cursor: "pointer" }}
      />
      <Heading m="20px 0">Video input device settings</Heading>

      <Flex flexDirection="column" pb="10px" bg={colors.darkgray}>
        <Resolutions />
        <Hr />
        <Flex p="20px 15px" alignItems="center">
          <Box width="250px">VR Shift</Box>
          <Box mr="20px">
            <Box py="12px">Horizontal:</Box>
            <Input
              mr="20px"
              type="number"
              step={5}
              value={mediaSettings.vr.hShift}
              onChange={(e) => dispatch(setVRSettings({ ...mediaSettings.vr, hShift: parseInt(e.target.value) }))}
            />
          </Box>
          <Box mr="20px">
            <Box py="12px">Vertical:</Box>
            <Input
              mr="20px"
              type="number"
              step={5}
              value={mediaSettings.vr.vShift}
              onChange={(e) => dispatch(setVRSettings({ ...mediaSettings.vr, vShift: parseInt(e.target.value) }))}
            />
          </Box>
          <Box mr="15px">
            <Box py="12px">FOV:</Box>
            <Input
              mr="20px"
              type="number"
              step={10}
              value={mediaSettings.vr.fov}
              onChange={(e) => dispatch(setVRSettings({ ...mediaSettings.vr, fov: parseInt(e.target.value) }))}
            />
          </Box>
          <Box width="340px" pt="40px">
            <Label>
              <Checkbox
                id="vr-swap"
                checked={mediaSettings.vr.swap}
                onChange={() => dispatch(setVRSettings({ ...mediaSettings.vr, swap: !mediaSettings.vr.swap }))}
              />
              Swap Lenses
            </Label>
          </Box>
        </Flex>

        <Hr />
        <Flex py="20px" alignItems="center">
          <SettingRow
            width="900px"
            mr="10px"
            label="Video codec"
            value={mediaSettings.simulcastSvc.codec}
            options={[
              { value: codecs.VP8, title: codecs.VP8 },
              { value: codecs.VP9, title: codecs.VP9 },
            ]}
            onSelectChange={(e: React.FormEvent<HTMLSelectElement>) =>
              dispatch(setSimulcastSvc({ ...mediaSettings.simulcastSvc, codec: e.currentTarget.value as Codec }))
            }
          />
          <Label>
            <Checkbox
              id="vr-simulcast"
              checked={mediaSettings.simulcastSvc.vr}
              onChange={() =>
                dispatch(setSimulcastSvc({ ...mediaSettings.simulcastSvc, vr: !mediaSettings.simulcastSvc.vr }))
              }
            />
            {mediaSettings.simulcastSvc.codec === codecs.VP8 ? "Simulcast" : "SVC"} VR
          </Label>
          <Label>
            <Checkbox
              id="mio-simulcast"
              checked={mediaSettings.simulcastSvc.overlays}
              onChange={() =>
                dispatch(
                  setSimulcastSvc({ ...mediaSettings.simulcastSvc, overlays: !mediaSettings.simulcastSvc.overlays }),
                )
              }
            />
            {mediaSettings.simulcastSvc.codec === codecs.VP8 ? "Simulcast" : "SVC"} Overlays
          </Label>
        </Flex>

        <Hr />
        <Flex p="20px 15px" alignItems="center">
          <Box mr="20px" width="200px">
            Max bitrates, Kb/s:
          </Box>
          <Flex alignItems="center">
            VR:
            <Input
              ml="10px"
              mr="20px"
              type="number"
              min={0}
              step={100}
              value={convertBitToKBit(mediaSettings.bitrates.vr)}
              onChange={(e: React.FormEvent<HTMLInputElement>) => setBitrate(parseInt(e.currentTarget.value), "vr")}
            />
          </Flex>
          <Flex alignItems="center">
            Overlays:
            <Input
              ml="10px"
              mr="20px"
              type="number"
              min={0}
              step={100}
              value={convertBitToKBit(mediaSettings.bitrates.overlays)}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setBitrate(parseInt(e.currentTarget.value), "overlays")
              }
            />
          </Flex>
        </Flex>

        <Hr />
        <Flex p="20px 15px" alignItems="center">
          <Box
            width="250px"
            data-tip="Use different names if you want it to be sent with different transports"
            data-for="transport-description"
          >
            WebRTC Send Transport names
            <HelpCircleOutline color={colors.white} />
          </Box>
          <ReactTooltip id="transport-description" effect="solid" />
          <Box mr="20px">
            <Box py="12px">VR:</Box>
            <Input
              mr="20px"
              value={mediaSettings.webrtcTransportNames.vr}
              onChange={(e) =>
                dispatch(setWebRTCTransportNames({ ...mediaSettings.webrtcTransportNames, vr: e.target.value }))
              }
            />
          </Box>
          <Box mr="20px">
            <Box py="12px">Overlays:</Box>
            <Input
              mr="20px"
              value={mediaSettings.webrtcTransportNames.overlays}
              onChange={(e) =>
                dispatch(setWebRTCTransportNames({ ...mediaSettings.webrtcTransportNames, overlays: e.target.value }))
              }
            />
          </Box>
          <Box mr="15px">
            <Box py="12px">Microphone:</Box>
            <Input
              mr="20px"
              value={mediaSettings.webrtcTransportNames.mic}
              onChange={(e) =>
                dispatch(setWebRTCTransportNames({ ...mediaSettings.webrtcTransportNames, mic: e.target.value }))
              }
            />
          </Box>
        </Flex>

        <Hr />
        <Flex p="20px 15px" alignItems="center">
          <Box mr="20px" width="200px">
            Gather media stats:
          </Box>
          <Flex alignItems="center" mr="20px">
            <Label alignItems="center">
              <Checkbox
                id="vr-media-stats"
                checked={mediaSettings.gatherMediaStats.vr}
                onChange={() =>
                  dispatch(
                    setGatherMediaStats({ ...mediaSettings.gatherMediaStats, vr: !mediaSettings.gatherMediaStats.vr }),
                  )
                }
              />
              VR
            </Label>
          </Flex>
          <Flex alignItems="center">
            <Label alignItems="center">
              <Checkbox
                id="overlays-media-stats"
                checked={mediaSettings.gatherMediaStats.overlays}
                onChange={() =>
                  dispatch(
                    setGatherMediaStats({
                      ...mediaSettings.gatherMediaStats,
                      overlays: !mediaSettings.gatherMediaStats.overlays,
                    }),
                  )
                }
              />
              Overlays
            </Label>
          </Flex>
        </Flex>
      </Flex>

      <Hr />
      <Box mt="20px" textAlign="center">
        <Link href="#" onClick={confirmSignOut} fontSize="14px" color={colors.white}>
          Sign Out
        </Link>
      </Box>
    </Flex>
  )
}
