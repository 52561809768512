import React, { useState, useEffect, useMemo } from "react"
import { Flex } from "rebass"
import { onAuthStateChanged, signInWithEmailAndPassword, signInWithCustomToken, signOut } from "firebase/auth"
import { skipToken } from "@reduxjs/toolkit/query"
import * as Sentry from "@sentry/electron"
import { auth, FirebaseError } from "../etc/firebase"
import { Loader } from "../components/Loader"
import SignInForm, { TSignInProps } from "../components/SignInForm"
import Version from "../components/Version"
import { useGetUserQuery, enums } from "../libs/api"
import { get, storageKeys } from "../libs/storage"
import { mediaPermissionTypes } from "../enums"
import { isNativeContext, showError } from "../helpers"
import logger from "../etc/logger"

import type { MediaPermissionType } from "../enums"

type Props = {
  children?: React.ReactNode
}

const AuthProvider: React.FC<Props> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userId, setUserId] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  const { data: user } = useGetUserQuery(userId || skipToken, { refetchOnMountOrArgChange: true })

  const onFormSubmit = ({ email, password }: TSignInProps) => {
    setError(null)
    setIsFormLoading(true)
    signInWithEmailAndPassword(auth, email, password).catch((err: FirebaseError) => {
      // TODO: Move to enums.
      let message = "Couldn't sign in."
      switch (err.code) {
        case "auth/wrong-password":
          message = "Wrong credentials."
          break
        case "auth/user-not-found":
          message = "User not found."
          break
      }

      setError(message)
    })
  }

  const userData = useMemo(() => {
    if (userId && user) {
      return user
    }
    return null
  }, [userId, user])

  const canUseStreamer = useMemo(() => {
    if (!userData) {
      return false
    }

    // Streamers and Global Admins are allowed to login.
    if (userData.roles.includes(enums.UserRole.STREAMER) || userData.roles.includes(enums.UserRole.GLOBAL_ADMIN)) {
      return true
    }

    // Org admins and managers can also use the Streamer.
    let allowed = false
    for (const org of userData.organizations) {
      if (org.role === enums.OrganizationRole.ADMIN || org.role === enums.OrganizationRole.MANAGER) {
        allowed = true
        break
      }
    }

    return allowed
  }, [userData])

  useEffect(() => {
    const tryLoginWithCustomToken = () => {
      const authSettings = get(storageKeys.AUTH)
      if (authSettings.token) {
        logger.debug("Trying to login with a custom token...")
        signInWithCustomToken(auth, authSettings.token).catch((err: FirebaseError) => {
          logger.warn(err)
        })
      }
    }

    const unsubscribeAuth = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUserId(authUser.uid)

        Sentry.configureScope((scope) => {
          scope.setUser({ id: authUser.uid })
        })
      } else {
        setUserId(null)
        setIsLoggedIn(false)
        setIsLoading(false)
        // Use as a fallback.
        tryLoginWithCustomToken()
      }
    })

    return () => unsubscribeAuth()
  }, [])

  useEffect(() => {
    if (!isNativeContext) {
      return
    }

    const requestGlobalMediaPermissions = async (mediaType: MediaPermissionType) => {
      const result = await window.electronAPI.requestMediaPermissions(mediaType)
      if (!result) {
        showError("Media Error", `Could not get access to [${mediaType}]`)
      }
    }

    // Ask permissions for camera devices.
    requestGlobalMediaPermissions(mediaPermissionTypes.CAMERA)
    // Ask permissions for microphone devices.
    requestGlobalMediaPermissions(mediaPermissionTypes.MICROPHONE)
  }, [])

  useEffect(() => {
    if (userData) {
      setIsLoading(false)
      setIsFormLoading(false)

      if (canUseStreamer) {
        setError(null)
        setIsLoggedIn(true)
      } else {
        setError("Not enough permissions to use the Streamer.")
        signOut(auth)
      }
    }
  }, [userData])

  useEffect(() => {
    if (error) {
      setIsFormLoading(false)
    }
  }, [error])

  return (
    <>
      {isLoading && (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" className="vh-full">
          <Loader size="70px" title="Loading..." showReloadButtonTimeout={10000} />
        </Flex>
      )}

      {!isLoading && !isLoggedIn && (
        <Flex alignItems="center" justifyContent="center" className="vh-full">
          <SignInForm onFormSubmit={onFormSubmit} isLoading={isFormLoading} error={error} />
          <Version />
        </Flex>
      )}

      {!isLoading && isLoggedIn && children}
    </>
  )
}

export default AuthProvider
