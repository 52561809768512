// Converts seconds to hh:mm:ss format.
export const convertSecToHHMMSS = (input: number, separator = ":") => {
  const pad = (input: number) => (input < 10 ? "0" + input : input)

  return [pad(Math.floor(input / 3600)), pad(Math.floor((input % 3600) / 60)), pad(Math.floor(input % 60))].join(
    separator,
  )
}

// Converts date to hh:mm AM/PM format.
export const formatAMPM = (date = new Date()) => {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const minutesStr = minutes < 10 ? "0" + minutes : minutes

  return `${hours}:${minutesStr}${ampm}`
}

// Converts date to mm/dd/yy hh:mm.
export const formatToDateTimeZone = (date = new Date()) => {
  const m = date.getMonth() + 1
  const d = date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
  const y = date.getFullYear().toString().substr(2)
  return `${m}/${d}/${y} - ${formatAMPM(date)}`
}

// NOTE: We also consider past events if their end date is today. Just in case the event takes longer.
export const todayStartTime = new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString()
