import { configureStore, createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit"
import { deviceReducer } from "./deviceSlice"
import {
  mediaSettingsReducer,
  updateVRSettings,
  updateMicrophoneSettings,
  updateSpeakerSettings,
  addOverlay,
  removeOverlay,
  updateOverlay,
  setResolutions,
  setBitrates,
  setSimulcastSvc,
  setGatherMediaStats,
  setWebRTCTransportNames,
} from "./mediaSettingsSlice"
import { api } from "../libs/api"
import { mediaServerApi } from "../libs/mediaServer"
import { metricsApi } from "../libs/metrics-api"
import { set, storageKeys } from "../libs/storage"

const listenerMiddleware = createListenerMiddleware()
// Store media settings in the local storage, so they preserve when we reload.
listenerMiddleware.startListening({
  matcher: isAnyOf(
    updateVRSettings,
    updateMicrophoneSettings,
    updateSpeakerSettings,
    addOverlay,
    removeOverlay,
    updateOverlay,
    setResolutions,
    setBitrates,
    setSimulcastSvc,
    setGatherMediaStats,
    setWebRTCTransportNames,
  ),
  effect: (action, listenerApi) => {
    const state = listenerApi.getState() as RootState
    set(storageKeys.MEDIA, state.mediaSettings)
  },
})

const middlewares = [api.middleware, mediaServerApi.middleware, metricsApi.middleware]

export const store = configureStore({
  reducer: {
    device: deviceReducer,
    mediaSettings: mediaSettingsReducer,
    [api.reducerPath]: api.reducer,
    [mediaServerApi.reducerPath]: mediaServerApi.reducer,
    [metricsApi.reducerPath]: metricsApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // NOTE: We have MediaStreamTrack instances coming from getMediaTracks query.
        // TODO: Do not store unserializable media tracks in the store.
        ignoredActions: ["mediaServerApi/queries/queryResultPatched"],
        ignoredPaths: ["mediaServerApi.queries"],
      },
    })
      .concat(middlewares)
      // NOTE: Since this can receive actions with functions inside, it should go before the serializability check middleware.
      .prepend(listenerMiddleware.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
