import React, { useState, useEffect } from "react"
import { Box, Text } from "rebass"
import config from "../etc/config"

const Version: React.FC = () => {
  const [version, setVersion] = useState("")

  useEffect(() => {
    const populateVersion = async () => {
      // NOTE: electronAPI is not set when building for browser.
      if (window.electronAPI) {
        const version = await window.electronAPI.getVersion()
        setVersion(version)
      }
    }

    populateVersion()
  }, [])

  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        left: 0,
        bottom: "5px",
        textAlign: "center",
        fontSize: "10px",
        opacity: 0.4,
      }}
    >
      <Text>
        {version} ({config.app.buildNumber})
      </Text>
    </Box>
  )
}

export default Version
