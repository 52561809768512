import { init as initSentry } from "@sentry/electron/renderer"
import { isNativeContext } from "./helpers"
import config from "./etc/config"

if (config.sentry.dsn && isNativeContext) {
  initSentry({
    dsn: config.sentry.dsn,
    environment: config.app.environment,
    release: config.app.buildNumber,
  })
}

const clearCacheIfNeeded = () => {
  const localStorage = JSON.parse(window.localStorage.getItem("mediaSettings") || "{}")
  // If the localStorage includes the following settings, it's a sign of outdated app, clear the cache.
  const shouldClearCache = localStorage.mios || localStorage.sim_svc || localStorage.bitrate
  if (shouldClearCache) {
    window.localStorage.setItem("mediaSettings", "")
  }
}

// If the localStorage includes outdated data, better to clear it.
clearCacheIfNeeded()

// Main app.
import "./App"
