import React, { useState, useMemo, useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Flex, Text } from "rebass"
import { Add } from "react-ionicons"
import Swal from "sweetalert2"

import { Overlay } from "./Overlay"
import { selectDevices } from "../../../redux/deviceSlice"
import { selectMediaSettings, addOverlay } from "../../../redux/mediaSettingsSlice"
import { Select } from "../../../components/Select"
import { colors } from "../../../etc/theme"
import { deviceKinds } from "../../../enums"
import { connectionStates, mediaDataTypes } from "../../../libs/mediaServer/enums"

import type { SelectOption } from "../../../components/Select"
import {
  useCloseDataProducerMutation,
  useGetConnectionStateQuery,
  useGetSettingsQuery,
  useProduceDataMutation,
} from "../../../libs/mediaServer"
import { skipToken } from "@reduxjs/toolkit/query"
import type { OverlaySettings } from "../../../libs/storage"

type OverlayUI = Omit<OverlaySettings, "resolution">

type Props = {
  isConnectionInitiated: boolean
}

export const Overlays: React.FC<Props> = ({ isConnectionInitiated }) => {
  const [showDeviceSelect, setShowDeviceSelect] = useState(false)

  const dispatch = useDispatch()
  const mediaSettings = useSelector(selectMediaSettings)
  const devices = useSelector(selectDevices)

  const { data: connection } = useGetConnectionStateQuery(isConnectionInitiated ? undefined : skipToken)
  const { data: settings } = useGetSettingsQuery(isConnectionInitiated ? undefined : skipToken)
  const [initiateDataProducer] = useProduceDataMutation()
  const [closeDataProducer] = useCloseDataProducerMutation()

  const deviceOptions = useMemo(() => {
    return devices
      .filter((device) => device.kind === deviceKinds.VIDEO_INPUT)
      .map(({ id, label }) => ({ value: id, title: label }))
  }, [devices])

  const [overlays, unmatchingOverlays] = useMemo(() => {
    // Keep track of conflicting/missing overlays.
    const unmatchingOverlays: OverlaySettings[] = []

    // Consider only overlays set in localStorage and make sure the physical device exists.
    // NOTE: We don't remove nonexistent devices just in case they will be re-added.
    const overlaysBasedOnDevices = Object.keys(mediaSettings.overlays).reduce(
      (matchingOverlays: OverlayUI[], overlayId) => {
        const overlay = mediaSettings.overlays[overlayId]

        // Try to find based on id and label.
        let matchingDevice = devices.find(
          (d) => d.id === overlay.device.id && d.label === overlay.device.label && d.kind === deviceKinds.VIDEO_INPUT,
        )

        // Try to find based on label only if the above fails.
        if (!matchingDevice) {
          const lDevices = devices.filter((d) => d.label === overlay.device.label && d.kind === deviceKinds.VIDEO_INPUT)
          matchingDevice = lDevices[overlay.device.index] || lDevices[0]

          if (matchingDevice) {
            unmatchingOverlays.push(overlay)
          }
        }

        if (matchingDevice) {
          matchingOverlays.push({
            id: overlay.id,
            index: overlay.index,
            label: overlay.label,
            device: matchingDevice,
          })
        }

        return matchingOverlays
      },
      [],
    )

    return [overlaysBasedOnDevices, unmatchingOverlays]
  }, [mediaSettings.overlays, devices])

  const onDeviceSelect = useCallback(
    async (option: SelectOption) => {
      setShowDeviceSelect(false)

      const device = devices.find((d) => d.id === option.value)
      if (!device) return // Should never happen.

      // Form default label.
      let index = 1
      while (index <= Object.keys(mediaSettings.overlays).length) {
        const overlay = Object.keys(mediaSettings.overlays).find((key) => mediaSettings.overlays[key].index === index)
        if (!overlay) {
          break
        }
        index++
      }

      const { value: label } = await Swal.fire({
        title: "Enter Overlay name",
        input: "text",
        inputValue: `Overlay ${index}`,
        inputAttributes: {
          maxlength: "24",
        },
        showCancelButton: true,
        confirmButtonColor: colors.primary,
        cancelButtonColor: colors.red,
      })

      if (label) {
        dispatch(addOverlay({ device, label }))
      }
    },
    [mediaSettings.overlays, devices, dispatch],
  )

  useEffect(() => {
    if (unmatchingOverlays.length) {
      let text = "Seems like the following overlays might be swapped: "
      text += `${unmatchingOverlays.join(", ")}. `
      text += "Please, make sure you're using the correct devices."

      Swal.fire({
        icon: "warning",
        title: "Some of the overlays might be swapped",
        text,
      })
    }
  }, [unmatchingOverlays])

  // Activate/Deactivate data producer for drawing.
  useEffect(() => {
    if (connection?.state === connectionStates.CONNECTED && settings?.isAnnotationsAllowed) {
      initiateDataProducer(mediaDataTypes.ANNOTATIONS)

      return () => {
        closeDataProducer(mediaDataTypes.ANNOTATIONS)
      }
    }
  }, [connection?.state, settings?.isAnnotationsAllowed, initiateDataProducer, closeDataProducer])

  return (
    <Flex>
      {overlays.map((overlay) => (
        <Overlay
          key={overlay.id}
          isConnectionInitiated={isConnectionInitiated}
          isAnnotationsEnabled={settings?.isAnnotationsAllowed}
          {...overlay}
        />
      ))}

      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width="311px"
        height="234px"
        mt="50px"
        css={{ position: "relative", cursor: "pointer", background: colors.black }}
        onClick={() => setShowDeviceSelect(true)}
      >
        <Add color={colors.white} />
        <Text>Add Overlay</Text>
        {showDeviceSelect && (
          <Select
            width="80%"
            bottom="50%"
            options={deviceOptions}
            onCheck={onDeviceSelect}
            onClose={() => setShowDeviceSelect(false)}
          />
        )}
      </Flex>
    </Flex>
  )
}
