import { fetchBaseQuery } from "@reduxjs/toolkit/query"
import { auth } from "../../etc/firebase"
import config from "../../etc/config"
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query"

const _baseQuery = fetchBaseQuery({
  baseUrl: config.api.url,
  prepareHeaders: async (headers) => {
    const user = auth.currentUser
    if (!user) {
      return headers
    }

    const idToken = await user.getIdToken()
    headers.set("Authorization", `Bearer ${idToken}`)
    return headers
  },
})

const baseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError> = async (
  { params, ...args },
  api,
  extraOptions,
) => {
  return _baseQuery({ ...args, params: paramsSerializer(params) }, api, extraOptions)
}
export default baseQuery

/** ParamsSerializer encodes the query string. By default, Fetch will encode nested params as 'Object'. */
const paramsSerializer = (params: FetchArgs["params"] = {}): FetchArgs["params"] => {
  const out: Record<string, string> = {}

  for (const [k, v] of Object.entries(params)) {
    if (v === undefined) continue

    if (Array.isArray(v)) {
      out[k] = v.join(",")
    } else if (typeof v === "object") {
      for (const [k2, v2] of Object.entries(v)) {
        out[`${k}[${k2}]`] = v2 as string
      }
    } else {
      out[k] = v
    }
  }

  return out
}
