import React, { useState, useEffect, useRef, useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Flex, Box } from "rebass"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { Settings, Close, RadioButtonOn, RadioButtonOff, Pencil } from "react-ionicons"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"
import Swal from "sweetalert2"
import { selectMediaSettings, updateOverlay, removeOverlay } from "../../../redux/mediaSettingsSlice"
import { Select } from "../../../components/Select"
import { Button } from "../../../components/Button"
import { MediaStats } from "../MediaStats"
import { colors } from "../../../etc/theme"
import config from "../../../etc/config"
import { getEncodings, showError } from "../../../helpers"
import {
  producers,
  useGetConnectionStateQuery,
  useProduceTrackMutation,
  useCloseProducerMutation,
  useGetOverlayTitleQuery,
  useRenameOverlayMutation,
  useReplaceProducerTrackMutation,
} from "../../../libs/mediaServer"
import { codecs, connectionStates, mediaKinds } from "../../../libs/mediaServer/enums"
import logger from "../../../etc/logger"
import { usePausedTrack } from "../../../hooks/usePausedTrack"
import { useDraw } from "../../../hooks/useDraw"
import cursorPencilPath from "../../../assets/images/cursor-pencil.png"
import { deviceKinds } from "../../../enums"
import { DeviceSelect } from "../DeviceSelect"
import { selectDevices } from "../../../redux/deviceSlice"

const defaultBitrate = 2500000 // 2.5Mb if not set.
const DEVICE_REQUEST_MAX_COUNT = 5

const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`

const AnnotationsCanvas = styled.canvas<{ isAnnotationsEnabled?: boolean }>`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: ${({ isAnnotationsEnabled }) =>
    isAnnotationsEnabled ? `url(${cursorPencilPath}) 0 25, default` : "default"};
`

type Props = {
  id: string
  index: number
  deviceId: string
  label?: string
  isConnectionInitiated: boolean
  isAnnotationsEnabled?: boolean
}

export const Overlay: React.FC<Props> = ({
  id,
  deviceId,
  index,
  label,
  isConnectionInitiated,
  isAnnotationsEnabled,
}) => {
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack>()
  const [showResolutionSelect, setShowResolutionSelect] = useState(false)
  const [isPaused, setIsPaused] = useState(false)

  const streamId = `mio-${id}`

  const dispatch = useDispatch()
  const mediaSettings = useSelector(selectMediaSettings)
  const devices = useSelector(selectDevices)
  const videoRef = useRef<HTMLVideoElement>(null)
  // Used to keep paused frame and stream it.
  const canvasRef = useRef<HTMLCanvasElement>(null)
  // Used for annotations.
  const annotationCanvasRef = useRef<HTMLCanvasElement>(null)
  // How many times a device has been requested from getUserMedia API.
  const deviceRequestCount = useRef(0)

  const { data: connection } = useGetConnectionStateQuery(isConnectionInitiated ? undefined : skipToken)
  const { data: remoteLabel } = useGetOverlayTitleQuery(isConnectionInitiated ? streamId : skipToken)
  const [produceTrack, { data: producerId, error: produceTrackError }] = useProduceTrackMutation()
  const [stopProducer] = useCloseProducerMutation()
  const [replaceProducerTrack] = useReplaceProducerTrackMutation()
  const [renameOverlay] = useRenameOverlayMutation()

  const isConnected = connection?.state === connectionStates.CONNECTED
  const overlaySettings = mediaSettings.overlays[id]

  // Handle video track pause/resume.
  usePausedTrack({ id: streamId, videoRef, canvasRef, videoTrack, producerId, isPaused })

  // Activate drawing.
  useDraw(streamId, annotationCanvasRef.current, videoRef.current, !isAnnotationsEnabled, isConnected)

  const resolutionSelectOptions = useMemo(
    () =>
      mediaSettings.resolutions
        .filter((res) => res.allowed)
        .map((res) => ({
          value: res.title,
          title: `${res.width}x${res.height} (${res.title})`,
        })),
    [mediaSettings.resolutions],
  )

  const videoEncodings = useMemo(() => {
    return getEncodings({
      maxBitrate: mediaSettings.bitrates.overlays || defaultBitrate,
      useSimulcast: mediaSettings.simulcastSvc.overlays && mediaSettings.simulcastSvc.codec === codecs.VP8,
      useSVC: mediaSettings.simulcastSvc.overlays && mediaSettings.simulcastSvc.codec === codecs.VP9,
    })
  }, [mediaSettings.bitrates, mediaSettings.simulcastSvc])

  const onResolutionChange = async ({ value }: { value: string }) => {
    const resolution = mediaSettings.resolutions.find((res) => res.title === value)
    if (videoTrack && resolution) {
      try {
        await videoTrack.applyConstraints({
          width: { exact: resolution.width },
          height: { exact: resolution.height },
          frameRate: config.media.frameRate,
        })
        setShowResolutionSelect(false)
        dispatch(updateOverlay({ id, settings: { ...overlaySettings, resolution } }))
      } catch (e) {
        showError("Bad resolution", "The device doesn't support selected resolution.")
      }
    } else {
      showError("No such resolution", "Such resolution is not allowed.")
    }
  }

  const onDeviceChange = useCallback(
    async (deviceId: string) => {
      const device = devices.find((d) => d.id === deviceId)
      if (!device) {
        showError("Device Error", `Device "${deviceId} wasn't found."`)
        return
      }

      // If the previous overlay resolution is bigger than HD, lower down to default one.
      const resolution =
        overlaySettings.resolution.width > 1280 ? config.media.resolutions[0] : overlaySettings.resolution

      dispatch(
        updateOverlay({ id, settings: { ...overlaySettings, resolution, device: deviceId, title: device.title } }),
      )
      setIsPaused(false)
    },
    [dispatch, overlaySettings, devices, id],
  )

  const onOverlayRemove = useCallback(() => {
    Swal.fire({
      title: "Are you sure?",
      text: "Attendees will no longer receive this feed!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.value) {
        // Stop video track.
        if (isConnected && producerId) {
          stopProducer(producerId)
        }
        // Stop its producer.
        dispatch(removeOverlay(id))
      }
    })
  }, [id, isConnected, producerId, stopProducer, dispatch])

  const onOverlayRename = useCallback(async () => {
    const { value: title } = await Swal.fire({
      title: "Enter Overlay name",
      input: "text",
      inputValue: label,
      inputAttributes: {
        maxlength: "24",
      },
      showCancelButton: true,
      confirmButtonColor: colors.primary,
      cancelButtonColor: colors.red,
    })

    if (!title) return

    if (isConnected) {
      renameOverlay({ id: streamId, title })
    } else {
      // If connected, we get the new name from the server.
      dispatch(updateOverlay({ id, settings: { ...overlaySettings, label: title } }))
    }
  }, [id, streamId, isConnected, label, overlaySettings, renameOverlay, dispatch])

  useEffect(() => {
    if (remoteLabel) {
      dispatch(updateOverlay({ id, settings: { ...overlaySettings, label: remoteLabel } }))
    }
    // NOTE: We skip overlaySettings dependency to prevent extra call.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, remoteLabel, dispatch])

  useEffect(() => {
    if (!isConnected || !videoTrack) {
      return
    }

    if (producerId && producers[producerId]) {
      replaceProducerTrack({ producerId, newTrack: videoTrack })
    } else {
      produceTrack({
        transportName: mediaSettings.webrtcTransportNames.overlays,
        track: videoTrack,
        appData: { streamId, index, label },
        stopTracks: false,
        zeroRtpOnPause: true,
        encodings: videoEncodings,
        codec: {
          kind: mediaKinds.VIDEO,
          mimeType: `${mediaKinds.VIDEO}/${mediaSettings.simulcastSvc.codec || codecs.VP8}`,
          clockRate: 90000, // Both VP8 and VP9 support 90000 only.
        },
      })
    }
    // NOTE: We skip other dependencies to prevent extra calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, videoTrack, produceTrack, replaceProducerTrack])

  useEffect(() => {
    if (produceTrackError && "message" in produceTrackError) {
      showError("Unable to produce VR video", produceTrackError.message)
    }
  }, [produceTrackError])

  // Request video track on mount.
  useEffect(() => {
    const requestVideoTrack = async (deviceId: string) => {
      deviceRequestCount.current++
      try {
        const videoStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            deviceId,
            width: { exact: overlaySettings.resolution.width },
            height: { exact: overlaySettings.resolution.height },
            frameRate: config.media.frameRate,
          },
        })

        const track = videoStream.getVideoTracks()[0] as MediaStreamTrack
        const selectedDeviceId = track.getSettings().deviceId

        // NOTE: Sometimes getUserMedia picks wrong device for whatever reason. Need to check and re-pick.
        if (selectedDeviceId !== deviceId) {
          if (deviceRequestCount.current <= DEVICE_REQUEST_MAX_COUNT) {
            logger.warn(`Selected device (${selectedDeviceId}) doesn't match with ${deviceId}, requesting again...`)
            requestVideoTrack(deviceId)
            return
          } else {
            logger.warn(
              `Selected device (${selectedDeviceId}) doesn't match with ${deviceId} after ${DEVICE_REQUEST_MAX_COUNT} tries.`,
            )
          }
        }

        setVideoTrack(track)
        if (videoRef.current) {
          videoRef.current.srcObject = videoStream
        }
      } catch (e) {
        logger.error(e)
        showError("Media Error", `Unable to get media from device ${deviceId}.`)
      }
    }

    requestVideoTrack(deviceId)
    // NOTE: We skip other dependencies to prevent extra calls.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId])

  // Stop video track on unmount.
  useEffect(() => {
    return () => videoTrack?.stop()
  }, [videoTrack])

  const annotationAreaWidth = videoRef.current?.offsetWidth || 311
  const annotationAreaHeight = videoRef.current?.offsetHeight || 174

  return (
    <Box width="311px" height="234px" mt="50px" mr="20px" css={{ position: "relative", background: "#000" }}>
      <Video ref={videoRef} autoPlay playsInline />
      <canvas ref={canvasRef} className="hidden" />

      <AnnotationsCanvas
        ref={annotationCanvasRef}
        isAnnotationsEnabled={isAnnotationsEnabled}
        width={annotationAreaWidth}
        height={annotationAreaHeight}
      />

      {isPaused && (
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          fontSize="2"
          fontWeight="bold"
          bg="rgba(0, 0, 0, 0.5)"
          css={{ backdropFilter: "blur(6px)" }}
        >
          Offline
        </Flex>
      )}

      <Button
        onClick={() => setShowResolutionSelect(true)}
        variant="dark"
        p="1px 6px"
        css={{ position: "absolute", left: "6px", bottom: "10px" }}
        data-tip="Select resolution"
        data-for={`overlay-res-${id}`}
      >
        <Settings color={colors.white} />
      </Button>
      <ReactTooltip id={`overlay-res-${id}`} effect="solid" />

      {showResolutionSelect && (
        <Select
          options={resolutionSelectOptions}
          selected={mediaSettings.overlays[id].resolution.title}
          onCheck={onResolutionChange}
          onClose={() => setShowResolutionSelect(false)}
        />
      )}

      <DeviceSelect
        css={{ position: "absolute", left: "55px", bottom: "10px" }}
        deviceType={deviceKinds.VIDEO_INPUT}
        defaultDevice={deviceId}
        onDeviceChange={onDeviceChange}
        allowAutoChoose={false}
        iconSize={22}
      />

      <Button
        variant="dark"
        p="0 6px"
        onClick={onOverlayRemove}
        css={{ position: "absolute", right: "5px", top: "5px" }}
        data-tip="Remove Overlay"
        data-for={`overlay-remove-${id}`}
      >
        <Close color={colors.white} />
      </Button>
      <ReactTooltip id={`overlay-remove-${id}`} effect="solid" />

      <Button
        onClick={() => setIsPaused(!isPaused)}
        variant={isPaused ? "danger" : "success"}
        p="1px 6px"
        css={{ position: "absolute", right: "6px", bottom: "10px" }}
        data-tip={isPaused ? "Resume" : "Pause"}
        data-for={`overlay-pause-${id}`}
      >
        {isPaused ? <RadioButtonOff color={colors.white} /> : <RadioButtonOn color={colors.white} />}
      </Button>
      <ReactTooltip id={`overlay-pause-${id}`} effect="solid" />

      {label && (
        <>
          <Box
            width="fit-content"
            maxWidth="120px"
            p="5px 10px"
            m="0 auto"
            bg="rgba(0, 0, 0, 0.5)"
            css={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: "14px",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            {label}&nbsp;
            <Pencil
              color={colors.white}
              width="15px"
              height="15px"
              onClick={onOverlayRename}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </>
      )}

      {producerId && <MediaStats producerId={producerId} trackId={streamId} />}
    </Box>
  )
}
