import React, { useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { SliderWrapper } from "../SliderWrapper"
import { SpeakerLevelControl } from "./SpeakerLevelControl"
import { SpeakerMuteControl } from "./SpeakerMuteControl"
import logger from "../../../etc/logger"
import { DeviceSelect } from "../DeviceSelect"
import { deviceKinds, envs } from "../../../enums"
import type { Device } from "../../../enums"
import { selectMediaSettings, updateSpeakerSettings } from "../../../redux/mediaSettingsSlice"
import config from "../../../etc/config"
import { selectDevices } from "../../../redux/deviceSlice"

export const SpeakerControl: React.FC = () => {
  const dispatch = useDispatch()
  const devices = useSelector(selectDevices)
  const mediaSettings = useSelector(selectMediaSettings)
  const level = mediaSettings.speaker.level

  const onSetSpeakerLevel = (level: number) => {
    dispatch(updateSpeakerSettings({ level }))
  }

  const onSpeakerMuteToggle = async () => {
    if (level) {
      // Mute.
      onSetSpeakerLevel(0)
    } else {
      // Unmute.
      const systemLevel = await window.electronAPI.audio.speaker.getVolume()
      onSetSpeakerLevel(systemLevel)
    }
  }

  const onSpeakerChange = (device: Device) => {
    dispatch(updateSpeakerSettings({ device }))
  }

  const speakerDevices = useMemo(() => {
    return devices.filter((d) => d.kind === deviceKinds.AUDIO_OUTPUT)
  }, [devices])

  useEffect(() => {
    const setSystemVolume = async () => {
      try {
        // Unmute system volume first.
        const muted = await window.electronAPI.audio.speaker.getMuted()
        if (muted) {
          await window.electronAPI.audio.speaker.setMuted(false)
        }
        // Set system volume at least to the min level, so we have some room to control it on the software level.
        const minLevel = 50
        const systemLevel = await window.electronAPI.audio.speaker.getVolume()
        if (systemLevel < minLevel && config.app.environment !== envs.LOCAL) {
          // NOTE: This will change system level of the default device, even if you've changed it to a custom one.
          //       If you're using a custom one and have never changed its level, it should be 99 by default (it's a USB port specific).
          await window.electronAPI.audio.speaker.setVolume(minLevel)
        }
      } catch (e) {
        logger.warn("Unable to set speaker system level.")
      }
    }

    setSystemVolume()
  }, [])

  return (
    <SliderWrapper>
      <SpeakerLevelControl level={level || 0} onLevelChange={onSetSpeakerLevel} disabled={!speakerDevices.length} />
      <SpeakerMuteControl muted={level === 0} onMuteToggle={onSpeakerMuteToggle} disabled={!speakerDevices.length} />
      <DeviceSelect
        ml="auto"
        alignItems="center"
        fontSize="2"
        deviceType={deviceKinds.AUDIO_OUTPUT}
        defaultDevice={mediaSettings.speaker.device}
        onDeviceChange={onSpeakerChange}
        positionY="bottom"
      />
    </SliderWrapper>
  )
}
