import { createApi } from "@reduxjs/toolkit/query/react";
import { default as customBaseQuery } from "./baseQuery";
export const api = createApi({
  baseQuery: customBaseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    listEvents: build.query<ListEventsApiResponse, ListEventsApiArg>({
      query: (queryArg) => ({
        url: `/v0/events`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createEvent: build.mutation<CreateEventApiResponse, CreateEventApiArg>({
      query: (queryArg) => ({
        url: `/v0/events`,
        method: "POST",
        body: queryArg.createEventRequest,
      }),
    }),
    countEvents: build.query<CountEventsApiResponse, CountEventsApiArg>({
      query: () => ({ url: `/v0/events/count` }),
    }),
    updateEventRegistrationState: build.mutation<
      UpdateEventRegistrationStateApiResponse,
      UpdateEventRegistrationStateApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/registration-state`,
        method: "POST",
        body: queryArg.updateEventRegistrationStateRequest,
      }),
    }),
    getEvent: build.query<GetEventApiResponse, GetEventApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateEvent: build.mutation<UpdateEventApiResponse, UpdateEventApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}`,
        method: "PUT",
        body: queryArg.updateEventRequest,
      }),
    }),
    deleteEvent: build.mutation<DeleteEventApiResponse, DeleteEventApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}`,
        method: "DELETE",
      }),
    }),
    updateEventSettings: build.mutation<
      UpdateEventSettingsApiResponse,
      UpdateEventSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/settings`,
        method: "POST",
        body: queryArg.updateEventSettingsRequest,
      }),
    }),
    updateEventStatus: build.mutation<
      UpdateEventStatusApiResponse,
      UpdateEventStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/status`,
        method: "POST",
        body: queryArg.updateEventStatusRequest,
      }),
    }),
    startEventStreamServer: build.mutation<
      StartEventStreamServerApiResponse,
      StartEventStreamServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/servers/start`,
        method: "POST",
        body: queryArg.startEventStreamServerRequest,
      }),
    }),
    terminateEventStreamServer: build.mutation<
      TerminateEventStreamServerApiResponse,
      TerminateEventStreamServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/servers/terminate`,
        method: "DELETE",
      }),
    }),
    upsertEventUser: build.mutation<
      UpsertEventUserApiResponse,
      UpsertEventUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}`,
        method: "POST",
      }),
    }),
    deleteEventUser: build.mutation<
      DeleteEventUserApiResponse,
      DeleteEventUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    postEventUserStatusActions: build.mutation<
      PostEventUserStatusActionsApiResponse,
      PostEventUserStatusActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}/status/${queryArg.action}`,
        method: "POST",
      }),
    }),
    postEventUserRoleAction: build.mutation<
      PostEventUserRoleActionApiResponse,
      PostEventUserRoleActionApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}/role`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postEventUserNpsAction: build.mutation<
      PostEventUserNpsActionApiResponse,
      PostEventUserNpsActionApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/users/${queryArg.userId}/nps`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getConnectionsReport: build.query<
      GetConnectionsReportApiResponse,
      GetConnectionsReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/reports/connections`,
      }),
    }),
    createConnectionReport: build.mutation<
      CreateConnectionReportApiResponse,
      CreateConnectionReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/reports/connections`,
        method: "POST",
        body: queryArg.createEventConnectionsReportRequest,
      }),
    }),
    listEventConnectionsReport: build.query<
      ListEventConnectionsReportApiResponse,
      ListEventConnectionsReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/reports/connections`,
        params: { filter: queryArg.filter },
      }),
    }),
    getCo2EmissionsReport: build.query<
      GetCo2EmissionsReportApiResponse,
      GetCo2EmissionsReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/reports/co2_emission_reductions`,
      }),
    }),
    createCo2EmissionReport: build.mutation<
      CreateCo2EmissionReportApiResponse,
      CreateCo2EmissionReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/reports/co2_emission_reductions`,
        method: "POST",
        params: { force: queryArg.force },
      }),
    }),
    getInfoReport: build.query<GetInfoReportApiResponse, GetInfoReportApiArg>({
      query: (queryArg) => ({
        url: `/v0/events/${queryArg.eventId}/reports/info`,
      }),
    }),
    listEventNpsReports: build.query<
      ListEventNpsReportsApiResponse,
      ListEventNpsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/reports/nps`,
        params: { filter: queryArg.filter },
      }),
    }),
    listEventsReports: build.query<
      ListEventsReportsApiResponse,
      ListEventsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/events/reports/events`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    listUsers: build.query<ListUsersApiResponse, ListUsersApiArg>({
      query: (queryArg) => ({
        url: `/v0/users`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          search: queryArg.search,
          filter: queryArg.filter,
        },
      }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/users`,
        method: "POST",
        body: queryArg.createUserRequest,
      }),
    }),
    countUsers: build.query<CountUsersApiResponse, CountUsersApiArg>({
      query: () => ({ url: `/v0/users/count` }),
    }),
    inviteEmail: build.mutation<InviteEmailApiResponse, InviteEmailApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/email/invite`,
        method: "POST",
        body: queryArg.emailInviteRequest,
      }),
    }),
    resendInviteHeadsetRequest: build.mutation<
      ResendInviteHeadsetRequestApiResponse,
      ResendInviteHeadsetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/users/email/invite/resend/headset_request`,
        method: "POST",
        body: queryArg.resendHeadsetRequestRequest,
      }),
    }),
    resendInviteJoinCode: build.mutation<
      ResendInviteJoinCodeApiResponse,
      ResendInviteJoinCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/users/email/invite/resend/joincode`,
        method: "POST",
        body: queryArg.exchangeJoinCodeRequest,
      }),
    }),
    verifyEmail: build.mutation<VerifyEmailApiResponse, VerifyEmailApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/email/verify`,
        method: "PUT",
        body: queryArg.emailVerifyRequest,
      }),
    }),
    sendEmailAccountRegistrationConfirmation: build.mutation<
      SendEmailAccountRegistrationConfirmationApiResponse,
      SendEmailAccountRegistrationConfirmationApiArg
    >({
      query: () => ({
        url: `/v0/users/email/registration-confirmation`,
        method: "POST",
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}`,
        method: "PUT",
        body: queryArg.updateUserRequest,
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    upsertUserOrganization: build.mutation<
      UpsertUserOrganizationApiResponse,
      UpsertUserOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}/organizations/${queryArg.organizationId}`,
        method: "POST",
        body: queryArg.upsertUserOrganizationRequest,
      }),
    }),
    deleteUserOrganization: build.mutation<
      DeleteUserOrganizationApiResponse,
      DeleteUserOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}/organizations/${queryArg.organizationId}`,
        method: "DELETE",
      }),
    }),
    sendEmailInviteGeneral: build.mutation<
      SendEmailInviteGeneralApiResponse,
      SendEmailInviteGeneralApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}/invites`,
        method: "POST",
      }),
    }),
    sendEmailInviteEvent: build.mutation<
      SendEmailInviteEventApiResponse,
      SendEmailInviteEventApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}/invites/events/${queryArg.eventId}`,
        method: "POST",
        body: queryArg.sendInvitationRequest,
      }),
    }),
    userRegistrationStatus: build.query<
      UserRegistrationStatusApiResponse,
      UserRegistrationStatusApiArg
    >({
      query: (queryArg) => ({ url: `/v0/users/${queryArg.email}/status` }),
    }),
    verifyUser: build.mutation<VerifyUserApiResponse, VerifyUserApiArg>({
      query: (queryArg) => ({
        url: `/v0/users/${queryArg.userId}/verify`,
        method: "PUT",
      }),
    }),
    sendEmailVerification: build.mutation<
      SendEmailVerificationApiResponse,
      SendEmailVerificationApiArg
    >({
      query: () => ({ url: `/v0/users/verification`, method: "POST" }),
    }),
    sendAccountRemovalRequestNotifications: build.mutation<
      SendAccountRemovalRequestNotificationsApiResponse,
      SendAccountRemovalRequestNotificationsApiArg
    >({
      query: () => ({ url: `/v0/users/removal`, method: "POST" }),
    }),
    listUserConnectionsReport: build.query<
      ListUserConnectionsReportApiResponse,
      ListUserConnectionsReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/users/reports/connections`,
        params: { filter: queryArg.filter },
      }),
    }),
    listUserEventsReports: build.query<
      ListUserEventsReportsApiResponse,
      ListUserEventsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/users/reports/events`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    listOrganizations: build.query<
      ListOrganizationsApiResponse,
      ListOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/organizations`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createOrganization: build.mutation<
      CreateOrganizationApiResponse,
      CreateOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/organizations`,
        method: "POST",
        body: queryArg.createOrganizationRequest,
      }),
    }),
    countOrganizations: build.query<
      CountOrganizationsApiResponse,
      CountOrganizationsApiArg
    >({
      query: () => ({ url: `/v0/organizations/count` }),
    }),
    getOrganization: build.query<
      GetOrganizationApiResponse,
      GetOrganizationApiArg
    >({
      query: (queryArg) => ({ url: `/v0/organizations/${queryArg.orgId}` }),
    }),
    updateOrganization: build.mutation<
      UpdateOrganizationApiResponse,
      UpdateOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/organizations/${queryArg.orgId}`,
        method: "PUT",
        body: queryArg.updateOrganizationRequest,
      }),
    }),
    deleteOrganization: build.mutation<
      DeleteOrganizationApiResponse,
      DeleteOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/organizations/${queryArg.orgId}`,
        method: "DELETE",
      }),
    }),
    getOrganizationEventsReports: build.query<
      GetOrganizationEventsReportsApiResponse,
      GetOrganizationEventsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/organizations/${queryArg.orgId}/reports/events`,
      }),
    }),
    listOrganizationNpsReports: build.query<
      ListOrganizationNpsReportsApiResponse,
      ListOrganizationNpsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/organizations/reports/nps`,
        params: { filter: queryArg.filter },
      }),
    }),
    listOrganizationEventsReports: build.query<
      ListOrganizationEventsReportsApiResponse,
      ListOrganizationEventsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/organizations/reports/events`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    listInstructors: build.query<
      ListInstructorsApiResponse,
      ListInstructorsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/instructors`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createInstructor: build.mutation<
      CreateInstructorApiResponse,
      CreateInstructorApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/instructors`,
        method: "POST",
        body: queryArg.createInstructorRequest,
      }),
    }),
    countInstructors: build.query<
      CountInstructorsApiResponse,
      CountInstructorsApiArg
    >({
      query: () => ({ url: `/v0/instructors/count` }),
    }),
    getInstructor: build.query<GetInstructorApiResponse, GetInstructorApiArg>({
      query: (queryArg) => ({
        url: `/v0/instructors/${queryArg.instructorId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateInstructor: build.mutation<
      UpdateInstructorApiResponse,
      UpdateInstructorApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/instructors/${queryArg.instructorId}`,
        method: "PUT",
        body: queryArg.updateInstructorRequest,
      }),
    }),
    deleteInstructor: build.mutation<
      DeleteInstructorApiResponse,
      DeleteInstructorApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/instructors/${queryArg.instructorId}`,
        method: "DELETE",
      }),
    }),
    listInstructorNpsReports: build.query<
      ListInstructorNpsReportsApiResponse,
      ListInstructorNpsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/instructors/reports/nps`,
        params: { filter: queryArg.filter },
      }),
    }),
    listInstructorEventsReports: build.query<
      ListInstructorEventsReportsApiResponse,
      ListInstructorEventsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/instructors/reports/events`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    listLocations: build.query<ListLocationsApiResponse, ListLocationsApiArg>({
      query: (queryArg) => ({
        url: `/v0/locations`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createLocation: build.mutation<
      CreateLocationApiResponse,
      CreateLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/locations`,
        method: "POST",
        body: queryArg.createLocationRequest,
      }),
    }),
    countLocations: build.query<
      CountLocationsApiResponse,
      CountLocationsApiArg
    >({
      query: () => ({ url: `/v0/locations/count` }),
    }),
    getLocation: build.query<GetLocationApiResponse, GetLocationApiArg>({
      query: (queryArg) => ({
        url: `/v0/locations/${queryArg.locationId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateLocation: build.mutation<
      UpdateLocationApiResponse,
      UpdateLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/locations/${queryArg.locationId}`,
        method: "PUT",
        body: queryArg.updateLocationRequest,
      }),
    }),
    deleteLocation: build.mutation<
      DeleteLocationApiResponse,
      DeleteLocationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/locations/${queryArg.locationId}`,
        method: "DELETE",
      }),
    }),
    listLocationNpsReports: build.query<
      ListLocationNpsReportsApiResponse,
      ListLocationNpsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/locations/reports/nps`,
        params: { filter: queryArg.filter },
      }),
    }),
    listLocationEventsReports: build.query<
      ListLocationEventsReportsApiResponse,
      ListLocationEventsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/locations/reports/events`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    listSpecialties: build.query<
      ListSpecialtiesApiResponse,
      ListSpecialtiesApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/specialties`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createSpecialty: build.mutation<
      CreateSpecialtyApiResponse,
      CreateSpecialtyApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/specialties`,
        method: "POST",
        body: queryArg.createSpecialtyRequest,
      }),
    }),
    getSpecialty: build.query<GetSpecialtyApiResponse, GetSpecialtyApiArg>({
      query: (queryArg) => ({ url: `/v0/specialties/${queryArg.specialtyId}` }),
    }),
    updateSpecialty: build.mutation<
      UpdateSpecialtyApiResponse,
      UpdateSpecialtyApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}`,
        method: "PUT",
        body: queryArg.updateSpecialtyRequest,
      }),
    }),
    deleteSpecialty: build.mutation<
      DeleteSpecialtyApiResponse,
      DeleteSpecialtyApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}`,
        method: "DELETE",
      }),
    }),
    getSubSpecialty: build.query<
      GetSubSpecialtyApiResponse,
      GetSubSpecialtyApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}/${queryArg.subSpecialtyId}`,
      }),
    }),
    updateSubSpecialty: build.mutation<
      UpdateSubSpecialtyApiResponse,
      UpdateSubSpecialtyApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}/${queryArg.subSpecialtyId}`,
        method: "PUT",
        body: queryArg.updateSpecialtyRequest,
      }),
    }),
    deleteSubSpecialty: build.mutation<
      DeleteSubSpecialtyApiResponse,
      DeleteSubSpecialtyApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/specialties/${queryArg.specialtyId}/${queryArg.subSpecialtyId}`,
        method: "DELETE",
      }),
    }),
    listIndustries: build.query<
      ListIndustriesApiResponse,
      ListIndustriesApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/industries`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createIndustry: build.mutation<
      CreateIndustryApiResponse,
      CreateIndustryApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/industries`,
        method: "POST",
        body: queryArg.createIndustryRequest,
      }),
    }),
    getIndustry: build.query<GetIndustryApiResponse, GetIndustryApiArg>({
      query: (queryArg) => ({ url: `/v0/industries/${queryArg.industryId}` }),
    }),
    updateIndustry: build.mutation<
      UpdateIndustryApiResponse,
      UpdateIndustryApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/industries/${queryArg.industryId}`,
        method: "PUT",
        body: queryArg.updateIndustryRequest,
      }),
    }),
    deleteIndustry: build.mutation<
      DeleteIndustryApiResponse,
      DeleteIndustryApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/industries/${queryArg.industryId}`,
        method: "DELETE",
      }),
    }),
    getIndustryJobType: build.query<
      GetIndustryJobTypeApiResponse,
      GetIndustryJobTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/industries/${queryArg.industryId}/${queryArg.jobTypeId}`,
      }),
    }),
    updateIndustryJobType: build.mutation<
      UpdateIndustryJobTypeApiResponse,
      UpdateIndustryJobTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/industries/${queryArg.industryId}/${queryArg.jobTypeId}`,
        method: "PUT",
        body: queryArg.updateIndustryRequest,
      }),
    }),
    deleteIndustryJobType: build.mutation<
      DeleteIndustryJobTypeApiResponse,
      DeleteIndustryJobTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/industries/${queryArg.industryId}/${queryArg.jobTypeId}`,
        method: "DELETE",
      }),
    }),
    listDevices: build.query<ListDevicesApiResponse, ListDevicesApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createDevice: build.mutation<CreateDeviceApiResponse, CreateDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices`,
        method: "POST",
        body: queryArg.createDeviceRequest,
      }),
    }),
    getDevice: build.query<GetDeviceApiResponse, GetDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices/${queryArg.deviceId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateDevice: build.mutation<UpdateDeviceApiResponse, UpdateDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices/${queryArg.deviceId}`,
        method: "PUT",
        body: queryArg.updateDeviceRequest,
      }),
    }),
    deleteDevice: build.mutation<DeleteDeviceApiResponse, DeleteDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/devices/${queryArg.deviceId}`,
        method: "DELETE",
      }),
    }),
    listImages: build.query<ListImagesApiResponse, ListImagesApiArg>({
      query: (queryArg) => ({
        url: `/v0/images`,
        params: {
          include: queryArg.include,
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createImage: build.mutation<CreateImageApiResponse, CreateImageApiArg>({
      query: (queryArg) => ({
        url: `/v0/images`,
        method: "POST",
        body: queryArg.createImageRequest,
      }),
    }),
    getImage: build.query<GetImageApiResponse, GetImageApiArg>({
      query: (queryArg) => ({
        url: `/v0/images/${queryArg.imageId}`,
        params: { include: queryArg.include },
      }),
    }),
    updateImage: build.mutation<UpdateImageApiResponse, UpdateImageApiArg>({
      query: (queryArg) => ({
        url: `/v0/images/${queryArg.imageId}`,
        method: "PUT",
        body: queryArg.updateImageRequest,
      }),
    }),
    deleteImage: build.mutation<DeleteImageApiResponse, DeleteImageApiArg>({
      query: (queryArg) => ({
        url: `/v0/images/${queryArg.imageId}`,
        method: "DELETE",
      }),
    }),
    listDemos: build.query<ListDemosApiResponse, ListDemosApiArg>({
      query: (queryArg) => ({
        url: `/v0/demos`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createDemo: build.mutation<CreateDemoApiResponse, CreateDemoApiArg>({
      query: (queryArg) => ({
        url: `/v0/demos`,
        method: "POST",
        body: queryArg.createDemoRequest,
      }),
    }),
    demosReport: build.query<DemosReportApiResponse, DemosReportApiArg>({
      query: (queryArg) => ({
        url: `/v0/demos/reports`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    getDemoUploadUrl: build.query<
      GetDemoUploadUrlApiResponse,
      GetDemoUploadUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/demos/upload_url`,
        params: { path: queryArg.path },
      }),
    }),
    demoUsersReport: build.query<
      DemoUsersReportApiResponse,
      DemoUsersReportApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/demos/users/reports`,
        params: { filter: queryArg.filter },
      }),
    }),
    getDemo: build.query<GetDemoApiResponse, GetDemoApiArg>({
      query: (queryArg) => ({ url: `/v0/demos/${queryArg.demoId}` }),
    }),
    updateDemo: build.mutation<UpdateDemoApiResponse, UpdateDemoApiArg>({
      query: (queryArg) => ({
        url: `/v0/demos/${queryArg.demoId}`,
        method: "PUT",
        body: queryArg.updateDemoRequest,
      }),
    }),
    deleteDemo: build.mutation<DeleteDemoApiResponse, DeleteDemoApiArg>({
      query: (queryArg) => ({
        url: `/v0/demos/${queryArg.demoId}`,
        method: "DELETE",
      }),
    }),
    listDemoTrackings: build.query<
      ListDemoTrackingsApiResponse,
      ListDemoTrackingsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/demo_trackings`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createDemoTracking: build.mutation<
      CreateDemoTrackingApiResponse,
      CreateDemoTrackingApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/demo_trackings`,
        method: "POST",
        body: queryArg.createDemoTrackingRequest,
      }),
    }),
    listDemoTrackingActions: build.query<
      ListDemoTrackingActionsApiResponse,
      ListDemoTrackingActionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/demo_trackings/actions`,
        params: { page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    createDemoTrackingAction: build.mutation<
      CreateDemoTrackingActionApiResponse,
      CreateDemoTrackingActionApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/demo_trackings/actions`,
        method: "POST",
        body: queryArg.createDemoTrackingActionRequest,
      }),
    }),
    getHeadsetRequestsReports: build.query<
      GetHeadsetRequestsReportsApiResponse,
      GetHeadsetRequestsReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/reports/headset_requests`,
        params: { filter: queryArg.filter },
      }),
    }),
    listHeadsetRequests: build.query<
      ListHeadsetRequestsApiResponse,
      ListHeadsetRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/headset_requests`,
        params: {
          page: queryArg.page,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    createHeadsetRequest: build.mutation<
      CreateHeadsetRequestApiResponse,
      CreateHeadsetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/headset_requests`,
        method: "POST",
        body: queryArg.createHeadsetRequestRequest,
      }),
    }),
    getHeadsetRequest: build.query<
      GetHeadsetRequestApiResponse,
      GetHeadsetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/headset_requests/${queryArg.headsetRequestId}`,
      }),
    }),
    updateHeadsetRequest: build.mutation<
      UpdateHeadsetRequestApiResponse,
      UpdateHeadsetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/headset_requests/${queryArg.headsetRequestId}`,
        method: "PUT",
        body: queryArg.updateHeadsetRequestRequest,
      }),
    }),
    deleteHeadsetRequest: build.mutation<
      DeleteHeadsetRequestApiResponse,
      DeleteHeadsetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/headset_requests/${queryArg.headsetRequestId}`,
        method: "DELETE",
      }),
    }),
    linkHeadsetRequestEvents: build.mutation<
      LinkHeadsetRequestEventsApiResponse,
      LinkHeadsetRequestEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/headset_requests/${queryArg.headsetRequestId}/link_events`,
        method: "PUT",
        body: queryArg.linkHeadsetRequestEventsRequest,
      }),
    }),
    listHeadsetRequestHistory: build.query<
      ListHeadsetRequestHistoryApiResponse,
      ListHeadsetRequestHistoryApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/headset_requests/${queryArg.headsetRequestId}/history`,
      }),
    }),
    listLicenses: build.query<ListLicensesApiResponse, ListLicensesApiArg>({
      query: (queryArg) => ({
        url: `/v0/licenses`,
        params: { filter: queryArg.filter },
      }),
    }),
    createLicense: build.mutation<
      CreateLicenseApiResponse,
      CreateLicenseApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/licenses`,
        method: "POST",
        body: queryArg.createLicenseRequest,
      }),
    }),
    getLicense: build.query<GetLicenseApiResponse, GetLicenseApiArg>({
      query: (queryArg) => ({ url: `/v0/licenses/${queryArg.licenseId}` }),
    }),
    deleteLicense: build.mutation<
      DeleteLicenseApiResponse,
      DeleteLicenseApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/licenses/${queryArg.licenseId}`,
        method: "DELETE",
      }),
    }),
    listPlans: build.query<ListPlansApiResponse, ListPlansApiArg>({
      query: (queryArg) => ({
        url: `/v0/plans`,
        params: { filter: queryArg.filter },
      }),
    }),
    createPlan: build.mutation<CreatePlanApiResponse, CreatePlanApiArg>({
      query: (queryArg) => ({
        url: `/v0/plans`,
        method: "POST",
        body: queryArg.createPlanRequest,
      }),
    }),
    getPlan: build.query<GetPlanApiResponse, GetPlanApiArg>({
      query: (queryArg) => ({ url: `/v0/plans/${queryArg.planId}` }),
    }),
    deletePlan: build.mutation<DeletePlanApiResponse, DeletePlanApiArg>({
      query: (queryArg) => ({
        url: `/v0/plans/${queryArg.planId}`,
        method: "DELETE",
      }),
    }),
    pairDevice: build.mutation<PairDeviceApiResponse, PairDeviceApiArg>({
      query: (queryArg) => ({
        url: `/v0/pairings`,
        method: "POST",
        body: queryArg.pairDeviceRequest,
      }),
    }),
    createJoinCode: build.mutation<
      CreateJoinCodeApiResponse,
      CreateJoinCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/join_codes`,
        method: "POST",
        body: queryArg.createJoinCodeRequest,
      }),
    }),
    exchangeJoinCode: build.mutation<
      ExchangeJoinCodeApiResponse,
      ExchangeJoinCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/join_codes/exchange`,
        method: "POST",
        body: queryArg.exchangeJoinCodeRequest,
      }),
    }),
    customEventUserRegistration: build.mutation<
      CustomEventUserRegistrationApiResponse,
      CustomEventUserRegistrationApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/registration/events/${queryArg.eventId}/users/${queryArg.userId}`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    createRegistrationHeadsetRequest: build.mutation<
      CreateRegistrationHeadsetRequestApiResponse,
      CreateRegistrationHeadsetRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/registration/headset_requests`,
        method: "POST",
        body: queryArg.createHeadsetRequestRequest,
      }),
    }),
    listStreamServers: build.query<
      ListStreamServersApiResponse,
      ListStreamServersApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/stream_servers`,
        params: { page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    upsertStreamServer: build.mutation<
      UpsertStreamServerApiResponse,
      UpsertStreamServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/stream_servers`,
        method: "PUT",
        body: queryArg.upsertStreamServerRequest,
      }),
    }),
    getStreamServer: build.query<
      GetStreamServerApiResponse,
      GetStreamServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/stream_servers/${queryArg.streamServerId}`,
      }),
    }),
    deleteStreamServer: build.mutation<
      DeleteStreamServerApiResponse,
      DeleteStreamServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/stream_servers/${queryArg.streamServerId}`,
        method: "DELETE",
      }),
    }),
    listStreamServerInstanceTypes: build.query<
      ListStreamServerInstanceTypesApiResponse,
      ListStreamServerInstanceTypesApiArg
    >({
      query: () => ({ url: `/v0/stream_servers/meta/instance_types` }),
    }),
    listStreamServerImages: build.query<
      ListStreamServerImagesApiResponse,
      ListStreamServerImagesApiArg
    >({
      query: () => ({ url: `/v0/stream_servers/meta/images` }),
    }),
    listStreamServerIPs: build.query<
      ListStreamServerIPsApiResponse,
      ListStreamServerIPsApiArg
    >({
      query: () => ({ url: `/v0/stream_servers/meta/ips` }),
    }),
    listCxrServers: build.query<
      ListCxrServersApiResponse,
      ListCxrServersApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers`,
        params: { page: queryArg.page, filter: queryArg.filter },
      }),
    }),
    createCxrServer: build.mutation<
      CreateCxrServerApiResponse,
      CreateCxrServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers`,
        method: "POST",
        body: queryArg.createCxrServerRequest,
      }),
    }),
    refreshCxrServers: build.mutation<
      RefreshCxrServersApiResponse,
      RefreshCxrServersApiArg
    >({
      query: () => ({ url: `/v0/cxr_servers/refresh`, method: "POST" }),
    }),
    grabCxrServer: build.mutation<
      GrabCxrServerApiResponse,
      GrabCxrServerApiArg
    >({
      query: () => ({ url: `/v0/cxr_servers/grab`, method: "POST" }),
    }),
    listCxrInstanceTypes: build.query<
      ListCxrInstanceTypesApiResponse,
      ListCxrInstanceTypesApiArg
    >({
      query: () => ({ url: `/v0/cxr_servers/meta/instance_types` }),
    }),
    listCxrImages: build.query<ListCxrImagesApiResponse, ListCxrImagesApiArg>({
      query: () => ({ url: `/v0/cxr_servers/meta/images` }),
    }),
    listCxriPs: build.query<ListCxriPsApiResponse, ListCxriPsApiArg>({
      query: () => ({ url: `/v0/cxr_servers/meta/ips` }),
    }),
    getCxrServer: build.query<GetCxrServerApiResponse, GetCxrServerApiArg>({
      query: (queryArg) => ({ url: `/v0/cxr_servers/${queryArg.cxrServerId}` }),
    }),
    updateCxrServer: build.mutation<
      UpdateCxrServerApiResponse,
      UpdateCxrServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}`,
        method: "PUT",
        body: queryArg.updateCxrServerRequest,
      }),
    }),
    deleteCxrServer: build.mutation<
      DeleteCxrServerApiResponse,
      DeleteCxrServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}`,
        method: "DELETE",
      }),
    }),
    startCxrServer: build.mutation<
      StartCxrServerApiResponse,
      StartCxrServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/start`,
        method: "POST",
      }),
    }),
    stopCxrServer: build.mutation<
      StopCxrServerApiResponse,
      StopCxrServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/stop`,
        method: "POST",
      }),
    }),
    waitCxrServer: build.mutation<
      WaitCxrServerApiResponse,
      WaitCxrServerApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/wait`,
        method: "POST",
        body: queryArg.waitCxrServerRequest,
      }),
    }),
    updateCxrServerInstanceState: build.mutation<
      UpdateCxrServerInstanceStateApiResponse,
      UpdateCxrServerInstanceStateApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/instance_state`,
        method: "POST",
        body: queryArg.updateCxrServerInstanceStateRequest,
      }),
    }),
    unlinkCxrServerUser: build.mutation<
      UnlinkCxrServerUserApiResponse,
      UnlinkCxrServerUserApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/cxr_servers/${queryArg.cxrServerId}/unlink`,
        method: "DELETE",
      }),
    }),
    getMetricsToken: build.query<
      GetMetricsTokenApiResponse,
      GetMetricsTokenApiArg
    >({
      query: () => ({ url: `/v0/metrics/token` }),
    }),
    deleteMetricsToken: build.mutation<
      DeleteMetricsTokenApiResponse,
      DeleteMetricsTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/metrics/token/${queryArg.tokenId}`,
        method: "DELETE",
      }),
    }),
    listContracts: build.query<ListContractsApiResponse, ListContractsApiArg>({
      query: (queryArg) => ({
        url: `/v0/contracts`,
        params: {
          sort: queryArg.sort,
          page: queryArg.page,
          filter: queryArg.filter,
        },
      }),
    }),
    createContract: build.mutation<
      CreateContractApiResponse,
      CreateContractApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/contracts`,
        method: "POST",
        body: queryArg.createContractRequest,
      }),
    }),
    getContract: build.query<GetContractApiResponse, GetContractApiArg>({
      query: (queryArg) => ({ url: `/v0/contracts/${queryArg.contractId}` }),
    }),
    updateContract: build.mutation<
      UpdateContractApiResponse,
      UpdateContractApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/contracts/${queryArg.contractId}`,
        method: "PUT",
        body: queryArg.updateContractRequest,
      }),
    }),
    deleteContract: build.mutation<
      DeleteContractApiResponse,
      DeleteContractApiArg
    >({
      query: (queryArg) => ({
        url: `/v0/contracts/${queryArg.contractId}`,
        method: "DELETE",
      }),
    }),
    getHealthcheck: build.query<
      GetHealthcheckApiResponse,
      GetHealthcheckApiArg
    >({
      query: () => ({ url: `/v0/healthz` }),
    }),
    whoAmI: build.query<WhoAmIApiResponse, WhoAmIApiArg>({
      query: () => ({ url: `/v0/whoami` }),
    }),
    speedTest: build.query<SpeedTestApiResponse, SpeedTestApiArg>({
      query: (queryArg) => ({
        url: `/v0/speedtest`,
        params: { size: queryArg.size, code: queryArg.code },
      }),
    }),
    resendInvitationEmails: build.mutation<
      ResendInvitationEmailsApiResponse,
      ResendInvitationEmailsApiArg
    >({
      query: () => ({ url: `/v0/crons/resend_emails`, method: "POST" }),
    }),
  }),
});
export type ListEventsApiResponse = /** status 200 List Events Response.
 */ ListEventsResponse;
export type ListEventsApiArg = {
  /** List of included resources related to Events.
   */
  include?: (
    | "none"
    | "all"
    | "organizations"
    | "specialties"
    | "instructors"
    | "locations"
    | "users"
  )[];
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "start_time"
    | "-start_time"
    | "end_time"
    | "-end_time"
    | "title"
    | "-title"
    | "status"
    | "-status"
    | "privacy"
    | "-privacy"
    | "average_nps_score"
    | "-average_nps_score"
    | "average_engagements"
    | "-average_engagements"
    | "average_participants"
    | "-average_participants";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    public_only?: boolean;
    self?: boolean;
    title?: string;
    event_ids?: string[];
    user_ids?: string[];
    instructor_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
    end_before?: string;
    end_after?: string;
    start_before?: string;
    start_after?: string;
    specialty_ids?: string[];
    event_funding?: EventFunding[];
    status?: string[];
    types?: EventType[];
    stream_type?: EventStreamType;
  };
};
export type CreateEventApiResponse = /** status 200 Create Event Response.
 */ CreateEventResponse;
export type CreateEventApiArg = {
  /** Create Event Request.
   */
  createEventRequest: CreateEventRequest;
};
export type CountEventsApiResponse = /** status 200 Count Events Response.
 */ CountEventsResponse;
export type CountEventsApiArg = void;
export type UpdateEventRegistrationStateApiResponse = /** status 200 OK
 */ Success;
export type UpdateEventRegistrationStateApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Update Event Registration State Request.
   */
  updateEventRegistrationStateRequest: UpdateEventRegistrationStateRequest;
};
export type GetEventApiResponse = /** status 200 Get Event Response.
 */ GetEventResponse;
export type GetEventApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** List of included resources related to Events.
   */
  include?: (
    | "none"
    | "all"
    | "organizations"
    | "specialties"
    | "instructors"
    | "locations"
    | "users"
  )[];
};
export type UpdateEventApiResponse = /** status 200 OK
 */ Success;
export type UpdateEventApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Update Event Request.
   */
  updateEventRequest: UpdateEventRequest;
};
export type DeleteEventApiResponse = /** status 200 OK
 */ Success;
export type DeleteEventApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
};
export type UpdateEventSettingsApiResponse = /** status 200 OK
 */ Success;
export type UpdateEventSettingsApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Update Event Satus Request.
   */
  updateEventSettingsRequest: UpdateEventSettingsRequest;
};
export type UpdateEventStatusApiResponse = /** status 200 OK
 */ Success;
export type UpdateEventStatusApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Update Event Status Request.
   */
  updateEventStatusRequest: UpdateEventStatusRequest;
};
export type StartEventStreamServerApiResponse = /** status 200 Media Server URL.
 */ StartStreamServerResponse;
export type StartEventStreamServerApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Start an Event Stream Server.
   */
  startEventStreamServerRequest: StartEventStreamServerRequest;
};
export type TerminateEventStreamServerApiResponse = /** status 200 OK
 */ Success;
export type TerminateEventStreamServerApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
};
export type UpsertEventUserApiResponse = /** status 200 OK
 */ Success;
export type UpsertEventUserApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Target User ID.
   */
  userId: FirebaseUserId;
};
export type DeleteEventUserApiResponse = /** status 200 OK
 */ Success;
export type DeleteEventUserApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Target User ID.
   */
  userId: FirebaseUserId;
};
export type PostEventUserStatusActionsApiResponse = /** status 200 OK
 */ Success;
export type PostEventUserStatusActionsApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Target User ID.
   */
  userId: FirebaseUserId;
  action: EventUserStatusAction;
};
export type PostEventUserRoleActionApiResponse = /** status 200 OK
 */ Success;
export type PostEventUserRoleActionApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Target User ID.
   */
  userId: FirebaseUserId;
  /** Event User Role Action Request.
   */
  body: {
    role: EventUserRole;
  };
};
export type PostEventUserNpsActionApiResponse = /** status 200 OK
 */ Success;
export type PostEventUserNpsActionApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Target User ID.
   */
  userId: FirebaseUserId;
  /** Event User NPS Action Request.
   */
  body: {
    nps: {
      answer?: number;
    };
  };
};
export type GetConnectionsReportApiResponse =
  /** status 200 Get Event Connections Report Response.
   */ GetEventConnectionsReportResponse;
export type GetConnectionsReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
};
export type CreateConnectionReportApiResponse =
  /** status 200 Create Report Response.
   */ CreateReportResponse;
export type CreateConnectionReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Create Event Connection Report Request.
   */
  createEventConnectionsReportRequest: CreateEventConnectionsReportRequest;
};
export type ListEventConnectionsReportApiResponse =
  /** status 200 List Event Connections Report Response.
   */ ListEventConnectionsReportResponse;
export type ListEventConnectionsReportApiArg = {
  filter?: {
    event_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
  };
};
export type GetCo2EmissionsReportApiResponse =
  /** status 200 Get Event CO2 Emissions Report Response.
   */ GetEventCo2EmissionsReportResponse;
export type GetCo2EmissionsReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
};
export type CreateCo2EmissionReportApiResponse = /** status 200 OK
 */ Success;
export type CreateCo2EmissionReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Fields to rerun CO2 emission generation.
   */
  force?: boolean;
};
export type GetInfoReportApiResponse =
  /** status 200 Get Event Info Report Response.
   */ GetEventInfoReportResponse;
export type GetInfoReportApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
};
export type ListEventNpsReportsApiResponse =
  /** status 200 List Event NPS Reports Response.
   */ ListNpsReportsResponse;
export type ListEventNpsReportsApiArg = {
  filter?: {
    event_ids?: string[];
    instructor_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
  };
};
export type ListEventsReportsApiResponse =
  /** status 200 List Events Reports Response.
   */ ListEventsReportsResponse;
export type ListEventsReportsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "start_time"
    | "-start_time"
    | "end_time"
    | "-end_time"
    | "title"
    | "-title"
    | "status"
    | "-status"
    | "privacy"
    | "-privacy"
    | "average_nps_score"
    | "-average_nps_score"
    | "average_engagements"
    | "-average_engagements"
    | "average_participants"
    | "-average_participants";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    show_zero_attendance?: boolean;
    hide_no_nps_attendance?: boolean;
    event_ids?: string[];
    user_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
    specialty_ids?: string[];
    end_before?: string;
    end_after?: string;
    start_before?: string;
    start_after?: string;
    event_status?: EventStatus[];
    event_type?: EventType[];
    event_user_role?: EventUserRole[];
    event_user_viewer_type?: ClientType[];
    registration_status?: RegistrationStatus;
    event_funding?: EventFunding[];
  };
};
export type ListUsersApiResponse = /** status 200 List Users Response.
 */ ListUsersResponse;
export type ListUsersApiArg = {
  /** List of included resources related to Users.
   */
  include?: (
    | "none"
    | "all"
    | "organizations"
    | "specialties"
    | "industries"
    | "events"
    | "devices"
    | "streamers"
  )[];
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "email"
    | "-email"
    | "name.first"
    | "-name.first"
    | "name.last"
    | "-name.last"
    | "average_nps_score"
    | "-average_nps_score"
    | "total_events"
    | "-total_events";
  page?: {
    size?: number;
    number?: number;
  };
  /** Search string option.
   */
  search?: string;
  filter?: {
    email?: string;
    name?: string;
    phone?: string;
    self?: boolean;
    user_ids?: string[];
    event_ids?: string[];
    specialty_ids?: string[];
    organization_ids?: string[];
    user_role?: UserRole;
    job_title?: string;
    industry_ids?: string[];
    job_type_ids?: string[];
    registration_status?: RegistrationStatus;
  };
};
export type CreateUserApiResponse = /** status 200 Create User Response.
 */ CreateUserResponse;
export type CreateUserApiArg = {
  /** Create User Request.
   */
  createUserRequest: CreateUserRequest;
};
export type CountUsersApiResponse = /** status 200 Count Users Response.
 */ CountUsersResponse;
export type CountUsersApiArg = void;
export type InviteEmailApiResponse = /** status 200 OK
 */ Success;
export type InviteEmailApiArg = {
  /** Invite User by Email Request.
   */
  emailInviteRequest: EmailInviteRequest;
};
export type ResendInviteHeadsetRequestApiResponse = /** status 200 OK
 */ Success;
export type ResendInviteHeadsetRequestApiArg = {
  /** User and Event details.
   */
  resendHeadsetRequestRequest: ResendHeadsetRequestRequest;
};
export type ResendInviteJoinCodeApiResponse = /** status 200 OK
 */ Success;
export type ResendInviteJoinCodeApiArg = {
  /** Expired code to resend.
   */
  exchangeJoinCodeRequest: ExchangeJoinCodeRequest;
};
export type VerifyEmailApiResponse = /** status 200 OK
 */ Success;
export type VerifyEmailApiArg = {
  /** Verify User by Email Request.
   */
  emailVerifyRequest: EmailVerifyRequest;
};
export type SendEmailAccountRegistrationConfirmationApiResponse =
  /** status 200 OK
   */ Success | /** status 204 No Content */ undefined;
export type SendEmailAccountRegistrationConfirmationApiArg = void;
export type GetUserApiResponse = /** status 200 Get User Response.
 */ GetUserResponse;
export type GetUserApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId;
  /** List of included resources related to Users.
   */
  include?: (
    | "none"
    | "all"
    | "organizations"
    | "specialties"
    | "industries"
    | "events"
    | "devices"
    | "streamers"
  )[];
};
export type UpdateUserApiResponse = /** status 200 OK
 */ Success;
export type UpdateUserApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId;
  /** Update User Request.
    
    NOTE: All users must be part of the root organization "Immertec" (organization_id "FC1CjZ1sauwAgXKrQfuv" in all envs).
     */
  updateUserRequest: UpdateUserRequest;
};
export type DeleteUserApiResponse = /** status 200 OK
 */ Success;
export type DeleteUserApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId;
};
export type UpsertUserOrganizationApiResponse = /** status 200 OK
 */ Success;
export type UpsertUserOrganizationApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId;
  /** Target Organization ID.
   */
  organizationId: FirestoreDocumentId;
  /** Upsert User Organization Request.
   */
  upsertUserOrganizationRequest: UpsertUserOrganizationRequest;
};
export type DeleteUserOrganizationApiResponse = /** status 200 OK
 */ Success;
export type DeleteUserOrganizationApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId;
  /** Target Organization ID.
   */
  organizationId: FirestoreDocumentId;
};
export type SendEmailInviteGeneralApiResponse = /** status 200 OK
 */ Success;
export type SendEmailInviteGeneralApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId;
};
export type SendEmailInviteEventApiResponse = /** status 200 OK
 */ Success;
export type SendEmailInviteEventApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId;
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Send invitation Request.
   */
  sendInvitationRequest: SendInvitationRequest;
};
export type UserRegistrationStatusApiResponse =
  /** status 200 User Registration Status By Email.
   */ GetUserRegistrationStatusResponse;
export type UserRegistrationStatusApiArg = {
  /** User Email.
   */
  email: Email;
};
export type VerifyUserApiResponse = /** status 200 OK
 */ Success;
export type VerifyUserApiArg = {
  /** Target User ID.
   */
  userId: FirebaseUserId;
};
export type SendEmailVerificationApiResponse = /** status 200 OK
 */ Success | /** status 204 No Content */ undefined;
export type SendEmailVerificationApiArg = void;
export type SendAccountRemovalRequestNotificationsApiResponse =
  /** status 200 OK
   */ Success;
export type SendAccountRemovalRequestNotificationsApiArg = void;
export type ListUserConnectionsReportApiResponse =
  /** status 200 List User Connections Report Response.
   */ ListUserConnectionsReportResponse;
export type ListUserConnectionsReportApiArg = {
  filter?: {
    user_ids?: string[];
    organization_ids?: string[];
  };
};
export type ListUserEventsReportsApiResponse =
  /** status 200 List User Events Reports Response.
   */ ListEventsReportsResponse;
export type ListUserEventsReportsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "start_time"
    | "-start_time"
    | "end_time"
    | "-end_time"
    | "title"
    | "-title"
    | "status"
    | "-status"
    | "privacy"
    | "-privacy"
    | "average_nps_score"
    | "-average_nps_score"
    | "average_engagements"
    | "-average_engagements"
    | "average_participants"
    | "-average_participants";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    show_zero_attendance?: boolean;
    hide_no_nps_attendance?: boolean;
    event_ids?: string[];
    user_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
    specialty_ids?: string[];
    end_before?: string;
    end_after?: string;
    start_before?: string;
    start_after?: string;
    event_status?: EventStatus[];
    event_type?: EventType[];
    event_user_role?: EventUserRole[];
    event_user_viewer_type?: ClientType[];
    registration_status?: RegistrationStatus;
    event_funding?: EventFunding[];
  };
};
export type ListOrganizationsApiResponse =
  /** status 200 List Organizations Response.
   */ ListOrganizationsResponse;
export type ListOrganizationsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name"
    | "-name"
    | "status"
    | "-status"
    | "average_nps_score"
    | "-average_nps_score"
    | "average_engagements"
    | "-average_engagements"
    | "total_participants"
    | "-total_participants"
    | "total_events"
    | "-total_events"
    | "owner_user_name"
    | "-owner_user_name";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    self?: boolean;
    organization_ids?: string[];
    name?: string;
    owner?: string;
    status?: string[];
    slug?: string;
    privacy?: "PUBLIC" | "PRIVATE";
  };
};
export type CreateOrganizationApiResponse =
  /** status 200 Create Organization Response.
   */ CreateOrganizationResponse;
export type CreateOrganizationApiArg = {
  /** Create Organization Request.
   */
  createOrganizationRequest: CreateOrganizationRequest;
};
export type CountOrganizationsApiResponse =
  /** status 200 Count Organizations Response.
   */ CountOrganizationsResponse;
export type CountOrganizationsApiArg = void;
export type GetOrganizationApiResponse =
  /** status 200 Get Organization Response.
   */ GetOrganizationResponse;
export type GetOrganizationApiArg = {
  orgId: OrganizationId;
};
export type UpdateOrganizationApiResponse = /** status 200 OK
 */ Success;
export type UpdateOrganizationApiArg = {
  orgId: OrganizationId;
  /** Update Organization Request.
   */
  updateOrganizationRequest: UpdateOrganizationRequest;
};
export type DeleteOrganizationApiResponse = /** status 200 OK
 */ Success;
export type DeleteOrganizationApiArg = {
  orgId: OrganizationId;
};
export type GetOrganizationEventsReportsApiResponse =
  /** status 200 Get Organization Event Reports Response.
   */ GetOrganizationEventsReportsResponse;
export type GetOrganizationEventsReportsApiArg = {
  orgId: OrganizationId;
};
export type ListOrganizationNpsReportsApiResponse =
  /** status 200 List Organization NPS Reports Response.
   */ ListNpsReportsResponse;
export type ListOrganizationNpsReportsApiArg = {
  filter?: {
    event_ids?: string[];
    organization_ids?: string[];
  };
};
export type ListOrganizationEventsReportsApiResponse =
  /** status 200 List Organization Events Reports Response.
   */ ListEventsReportsResponse;
export type ListOrganizationEventsReportsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "start_time"
    | "-start_time"
    | "end_time"
    | "-end_time"
    | "title"
    | "-title"
    | "status"
    | "-status"
    | "privacy"
    | "-privacy"
    | "average_nps_score"
    | "-average_nps_score"
    | "average_engagements"
    | "-average_engagements"
    | "average_participants"
    | "-average_participants";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    show_zero_attendance?: boolean;
    hide_no_nps_attendance?: boolean;
    event_ids?: string[];
    user_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
    specialty_ids?: string[];
    end_before?: string;
    end_after?: string;
    start_before?: string;
    start_after?: string;
    event_status?: EventStatus[];
    event_type?: EventType[];
    event_user_role?: EventUserRole[];
    event_user_viewer_type?: ClientType[];
    registration_status?: RegistrationStatus;
    event_funding?: EventFunding[];
  };
};
export type ListInstructorsApiResponse =
  /** status 200 List Instructors Response.
   */ ListInstructorsResponse;
export type ListInstructorsApiArg = {
  /** List of included resources related to Instructors.
   */
  include?: (
    | "none"
    | "all"
    | "organizations"
    | "specialties"
    | "instructors"
    | "locations"
  )[];
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name.first"
    | "-name.first"
    | "name.last"
    | "-name.last"
    | "status"
    | "-status"
    | "average_nps_score"
    | "-average_nps_score"
    | "total_participants"
    | "-total_participants"
    | "total_events"
    | "-total_events";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    public_only?: boolean;
    organization_ids?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
    specialty_ids?: string[];
    slug?: string;
    name?: string;
    email?: string;
    status?: string[];
  };
};
export type CreateInstructorApiResponse =
  /** status 200 Create Instructor Response.
   */ CreateInstructorResponse;
export type CreateInstructorApiArg = {
  /** Create Instructor Request.
   */
  createInstructorRequest: CreateInstructorRequest;
};
export type CountInstructorsApiResponse =
  /** status 200 Count Instructors Response.
   */ CountInstructorsResponse;
export type CountInstructorsApiArg = void;
export type GetInstructorApiResponse = /** status 200 Get Instructor Response.
 */ GetInstructorResponse;
export type GetInstructorApiArg = {
  /** Target Instructor ID.
   */
  instructorId: FirestoreDocumentId3;
  /** List of included resources related to Instructors.
   */
  include?: (
    | "none"
    | "all"
    | "organizations"
    | "specialties"
    | "instructors"
    | "locations"
  )[];
};
export type UpdateInstructorApiResponse = /** status 200 OK
 */ Success;
export type UpdateInstructorApiArg = {
  /** Target Instructor ID.
   */
  instructorId: FirestoreDocumentId3;
  /** Update Instructor Request.
    
    NOTE: All instructors must be part of the root organization "Immertec" (organization_id "FC1CjZ1sauwAgXKrQfuv" in all envs).
     */
  updateInstructorRequest: UpdateInstructorRequest;
};
export type DeleteInstructorApiResponse = /** status 200 OK
 */ Success;
export type DeleteInstructorApiArg = {
  /** Target Instructor ID.
   */
  instructorId: FirestoreDocumentId3;
};
export type ListInstructorNpsReportsApiResponse =
  /** status 200 List Instructor NPS Reports Response.
   */ ListNpsReportsResponse;
export type ListInstructorNpsReportsApiArg = {
  filter?: {
    event_ids?: string[];
    instructor_ids?: string[];
  };
};
export type ListInstructorEventsReportsApiResponse =
  /** status 200 List Instructor Events Reports Response.
   */ ListEventsReportsResponse;
export type ListInstructorEventsReportsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "start_time"
    | "-start_time"
    | "end_time"
    | "-end_time"
    | "title"
    | "-title"
    | "status"
    | "-status"
    | "privacy"
    | "-privacy"
    | "average_nps_score"
    | "-average_nps_score"
    | "average_engagements"
    | "-average_engagements"
    | "average_participants"
    | "-average_participants";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    show_zero_attendance?: boolean;
    hide_no_nps_attendance?: boolean;
    event_ids?: string[];
    user_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
    specialty_ids?: string[];
    end_before?: string;
    end_after?: string;
    start_before?: string;
    start_after?: string;
    event_status?: EventStatus[];
    event_type?: EventType[];
    event_user_role?: EventUserRole[];
    event_user_viewer_type?: ClientType[];
    registration_status?: RegistrationStatus;
    event_funding?: EventFunding[];
  };
};
export type ListLocationsApiResponse = /** status 200 List Locations Response.
 */ ListLocationsResponse;
export type ListLocationsApiArg = {
  /** List of included resources related to Locations.
   */
  include?: ("none" | "all" | "instructors")[];
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name"
    | "-name"
    | "status"
    | "-status"
    | "average_nps_score"
    | "-average_nps_score"
    | "average_engagements"
    | "-average_engagements"
    | "total_participants"
    | "-total_participants"
    | "total_events"
    | "-total_events";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    public_only?: boolean;
    name?: string;
    status?: string[];
    category?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
    slug?: string;
  };
};
export type CreateLocationApiResponse = /** status 200 Create Location Response.
 */ CreateLocationResponse;
export type CreateLocationApiArg = {
  /** Create Location Request.
   */
  createLocationRequest: CreateLocationRequest;
};
export type CountLocationsApiResponse = /** status 200 Count Locations Response.
 */ CountLocationsResponse;
export type CountLocationsApiArg = void;
export type GetLocationApiResponse = /** status 200 Get Location Response.
 */ GetLocationResponse;
export type GetLocationApiArg = {
  /** Target Location ID.
   */
  locationId: FirestoreDocumentId2;
  /** List of included resources related to Locations.
   */
  include?: ("none" | "all" | "instructors")[];
};
export type UpdateLocationApiResponse = /** status 200 OK
 */ Success;
export type UpdateLocationApiArg = {
  /** Target Location ID.
   */
  locationId: FirestoreDocumentId2;
  /** Update Location Request.
   */
  updateLocationRequest: UpdateLocationRequest;
};
export type DeleteLocationApiResponse = /** status 200 OK
 */ Success;
export type DeleteLocationApiArg = {
  /** Target Location ID.
   */
  locationId: FirestoreDocumentId2;
};
export type ListLocationNpsReportsApiResponse =
  /** status 200 List Location NPS Reports Response.
   */ ListNpsReportsResponse;
export type ListLocationNpsReportsApiArg = {
  filter?: {
    event_ids?: string[];
    location_ids?: string[];
  };
};
export type ListLocationEventsReportsApiResponse =
  /** status 200 List Location Events Reports Response.
   */ ListEventsReportsResponse;
export type ListLocationEventsReportsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "start_time"
    | "-start_time"
    | "end_time"
    | "-end_time"
    | "title"
    | "-title"
    | "status"
    | "-status"
    | "privacy"
    | "-privacy"
    | "average_nps_score"
    | "-average_nps_score"
    | "average_engagements"
    | "-average_engagements"
    | "average_participants"
    | "-average_participants";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    show_zero_attendance?: boolean;
    hide_no_nps_attendance?: boolean;
    event_ids?: string[];
    user_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
    specialty_ids?: string[];
    end_before?: string;
    end_after?: string;
    start_before?: string;
    start_after?: string;
    event_status?: EventStatus[];
    event_type?: EventType[];
    event_user_role?: EventUserRole[];
    event_user_viewer_type?: ClientType[];
    registration_status?: RegistrationStatus;
    event_funding?: EventFunding[];
  };
};
export type ListSpecialtiesApiResponse =
  /** status 200 List Specialties Response.
   */ ListSpecialtiesResponse;
export type ListSpecialtiesApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name"
    | "-name";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    specialty_ids?: string[];
    name?: string;
    subspec_name?: string;
  };
};
export type CreateSpecialtyApiResponse =
  /** status 200 Create Specialty Response.
   */ CreateSpecialtyResponse;
export type CreateSpecialtyApiArg = {
  /** Create Specialty Request.
   */
  createSpecialtyRequest: CreateSpecialtyRequest;
};
export type GetSpecialtyApiResponse = /** status 200 Get Specialty Response.
 */ GetSpecialtyResponse;
export type GetSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id;
};
export type UpdateSpecialtyApiResponse = /** status 200 OK
 */ Success;
export type UpdateSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id;
  /** Update Specialty Request.
   */
  updateSpecialtyRequest: UpdateSpecialtyRequest;
};
export type DeleteSpecialtyApiResponse = /** status 200 OK
 */ Success;
export type DeleteSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id;
};
export type GetSubSpecialtyApiResponse = /** status 200 Get Specialty Response.
 */ GetSpecialtyResponse;
export type GetSubSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id;
  /** Target Sub Specialty ID.
   */
  subSpecialtyId: Id;
};
export type UpdateSubSpecialtyApiResponse = /** status 200 OK
 */ Success;
export type UpdateSubSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id;
  /** Target Sub Specialty ID.
   */
  subSpecialtyId: Id;
  /** Update Sub Specialty Request.
   */
  updateSpecialtyRequest: UpdateSpecialtyRequest;
};
export type DeleteSubSpecialtyApiResponse = /** status 200 OK
 */ Success;
export type DeleteSubSpecialtyApiArg = {
  /** Target Specialty ID.
   */
  specialtyId: Id;
  /** Target Sub Specialty ID.
   */
  subSpecialtyId: Id;
};
export type ListIndustriesApiResponse = /** status 200 List Industries Response.
 */ ListIndustriesResponse;
export type ListIndustriesApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name"
    | "-name";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    industry_ids?: string[];
    name?: string;
    job_type_name?: string;
  };
};
export type CreateIndustryApiResponse = /** status 200 Create Industry Response.
 */ CreateIndustryResponse;
export type CreateIndustryApiArg = {
  /** Create Industry Request.
   */
  createIndustryRequest: CreateIndustryRequest;
};
export type GetIndustryApiResponse = /** status 200 Get Industry Response.
 */ GetIndustryResponse;
export type GetIndustryApiArg = {
  /** Target Industry ID.
   */
  industryId: Id;
};
export type UpdateIndustryApiResponse = /** status 200 OK
 */ Success;
export type UpdateIndustryApiArg = {
  /** Target Industry ID.
   */
  industryId: Id;
  /** Update Industry Request.
   */
  updateIndustryRequest: UpdateIndustryRequest;
};
export type DeleteIndustryApiResponse = /** status 200 OK
 */ Success;
export type DeleteIndustryApiArg = {
  /** Target Industry ID.
   */
  industryId: Id;
};
export type GetIndustryJobTypeApiResponse =
  /** status 200 Get Industry Response.
   */ GetIndustryResponse;
export type GetIndustryJobTypeApiArg = {
  /** Target Industry ID.
   */
  industryId: Id;
  /** Target Job Type ID.
   */
  jobTypeId: Id;
};
export type UpdateIndustryJobTypeApiResponse = /** status 200 OK
 */ Success;
export type UpdateIndustryJobTypeApiArg = {
  /** Target Industry ID.
   */
  industryId: Id;
  /** Target Job Type ID.
   */
  jobTypeId: Id;
  /** Update Industry Job Type Request.
   */
  updateIndustryRequest: UpdateIndustryRequest;
};
export type DeleteIndustryJobTypeApiResponse = /** status 200 OK
 */ Success;
export type DeleteIndustryJobTypeApiArg = {
  /** Target Industry ID.
   */
  industryId: Id;
  /** Target Job Type ID.
   */
  jobTypeId: Id;
};
export type ListDevicesApiResponse = /** status 200 List Devices Response.
 */ ListDevicesResponse;
export type ListDevicesApiArg = {
  /** List of included resources related to Devices.
   */
  include?: ("none" | "all" | "users")[];
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "paired_at"
    | "-paired_at"
    | "serial_number"
    | "-serial_number";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    user_ids?: string[];
    device_ids?: string[];
    serial_number?: string;
  };
};
export type CreateDeviceApiResponse = /** status 200 Create Device Response.
 */ CreateDeviceResponse;
export type CreateDeviceApiArg = {
  /** Create Device Request.
   */
  createDeviceRequest: CreateDeviceRequest;
};
export type GetDeviceApiResponse = /** status 200 Get Device Response.
 */ GetDeviceResponse;
export type GetDeviceApiArg = {
  /** Target Device ID.
   */
  deviceId: DeviceId;
  /** List of included resources related to Devices.
   */
  include?: ("none" | "all" | "users")[];
};
export type UpdateDeviceApiResponse = /** status 200 OK
 */ Success;
export type UpdateDeviceApiArg = {
  /** Target Device ID.
   */
  deviceId: DeviceId;
  /** Update Device Request.
   */
  updateDeviceRequest: UpdateDeviceRequest;
};
export type DeleteDeviceApiResponse = /** status 200 OK
 */ Success;
export type DeleteDeviceApiArg = {
  /** Target Device ID.
   */
  deviceId: DeviceId;
};
export type ListImagesApiResponse = /** status 200 List Images Response.
 */ ListImagesResponse;
export type ListImagesApiArg = {
  /** List of included resources related to Images.
   */
  include?: ("none" | "all")[];
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name"
    | "-name";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    image_ids?: string[];
    name?: string;
    tags?: string[];
  };
};
export type CreateImageApiResponse = /** status 200 Create Image Response.
 */ CreateImageResponse;
export type CreateImageApiArg = {
  /** Create Image Request.
   */
  createImageRequest: CreateImageRequest;
};
export type GetImageApiResponse = /** status 200 Get Image Response.
 */ GetImageResponse;
export type GetImageApiArg = {
  /** Target Image ID.
   */
  imageId: Uuid;
  /** List of included resources related to Images.
   */
  include?: ("none" | "all")[];
};
export type UpdateImageApiResponse = /** status 200 OK
 */ Success;
export type UpdateImageApiArg = {
  /** Target Image ID.
   */
  imageId: Uuid;
  /** Update Image Request.
   */
  updateImageRequest: UpdateImageRequest;
};
export type DeleteImageApiResponse = /** status 200 OK
 */ Success;
export type DeleteImageApiArg = {
  /** Target Image ID.
   */
  imageId: Uuid;
};
export type ListDemosApiResponse = /** status 200 List Demos Response.
 */ ListDemosResponse;
export type ListDemosApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name"
    | "-name"
    | "views"
    | "-views";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    organization_ids?: string[];
    instructor_ids?: string[];
    location_ids?: string[];
    specialty_ids?: string[];
    demo_ids?: string[];
    name?: string;
    group_name?: string;
  };
};
export type CreateDemoApiResponse = /** status 200 Create Demo Response.
 */ CreateDemoResponse;
export type CreateDemoApiArg = {
  /** Create Demo Request.
   */
  createDemoRequest: CreateDemoRequest;
};
export type DemosReportApiResponse = /** status 200 Demos Report Response.
 */ DemosReportResponse;
export type DemosReportApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "name"
    | "-name"
    | "views"
    | "-views";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    demo_ids?: string[];
    organization_ids?: string[];
    location_ids?: string[];
    instructor_ids?: string[];
    specialty_ids?: string[];
    name?: string;
  };
};
export type GetDemoUploadUrlApiResponse =
  /** status 200 Upload Demo Videos Response.
   */ GetDemoUploadUrlResponse;
export type GetDemoUploadUrlApiArg = {
  /** Demo Video Path.
   */
  path: string;
};
export type DemoUsersReportApiResponse =
  /** status 200 Demo Users Report Response.
   */ DemoUsersReportResponse;
export type DemoUsersReportApiArg = {
  filter?: {
    demo_ids?: string[];
    user_ids?: string[];
  };
};
export type GetDemoApiResponse = /** status 200 Get Demo Response.
 */ GetDemoResponse;
export type GetDemoApiArg = {
  /** Target Demo ID.
   */
  demoId: Uuid;
};
export type UpdateDemoApiResponse = /** status 200 OK
 */ Success;
export type UpdateDemoApiArg = {
  /** Target Demo ID.
   */
  demoId: Uuid;
  /** Update Demo Request.
   */
  updateDemoRequest: UpdateDemoRequest;
};
export type DeleteDemoApiResponse = /** status 200 OK
 */ Success;
export type DeleteDemoApiArg = {
  /** Target Demo ID.
   */
  demoId: Uuid;
};
export type ListDemoTrackingsApiResponse =
  /** status 200 List DemoTrackings Response.
   */ ListDemoTrackingsResponse;
export type ListDemoTrackingsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?: "created_at" | "-created_at";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    user_ids?: string[];
    applicant_ids?: string[];
    demo_ids?: string[];
    demo_tracking_ids?: string[];
  };
};
export type CreateDemoTrackingApiResponse =
  /** status 200 Create DemoTracking Response.
   */ CreateDemoTrackingResponse;
export type CreateDemoTrackingApiArg = {
  /** Create DemoTracking Request.
   */
  createDemoTrackingRequest: CreateDemoTrackingRequest;
};
export type ListDemoTrackingActionsApiResponse =
  /** status 200 List DemoTrackingActions Response.
   */ ListDemoTrackingActionsResponse;
export type ListDemoTrackingActionsApiArg = {
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    user_ids?: string[];
    demo_ids?: string[];
    demo_tracking_ids?: string[];
    demo_tracking_action_ids?: string[];
  };
};
export type CreateDemoTrackingActionApiResponse =
  /** status 200 Create DemoTrackingAction Response.
   */ CreateDemoTrackingActionResponse;
export type CreateDemoTrackingActionApiArg = {
  /** Create DemoTrackingAction Request.
   */
  createDemoTrackingActionRequest: CreateDemoTrackingActionRequest;
};
export type GetHeadsetRequestsReportsApiResponse =
  /** status 200 Report Response.
   */ string;
export type GetHeadsetRequestsReportsApiArg = {
  filter?: {
    event_ids?: string[];
  };
};
export type ListHeadsetRequestsApiResponse =
  /** status 200 List Headset Requests Response.
   */ ListHeadsetRequestsResponse;
export type ListHeadsetRequestsApiArg = {
  page?: {
    size?: number;
    number?: number;
  };
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "headset_user_name"
    | "-headset_user_name"
    | "headset_organization_name"
    | "-headset_organization_name"
    | "event_start_time"
    | "-event_start_time"
    | "created_at"
    | "-created_at";
  filter?: {
    self?: boolean;
    headset_request_ids?: string[];
    event_ids?: string[];
    user_ids?: string[];
    organization_ids?: string[];
    before?: string;
    after?: string;
    user_classification?: UserClassification;
    status?: string[];
  };
};
export type CreateHeadsetRequestApiResponse =
  /** status 200 Create Headset Request Response.
   */ CreateHeadsetRequestResponse;
export type CreateHeadsetRequestApiArg = {
  /** Create Headset Request Request.
   */
  createHeadsetRequestRequest: CreateHeadsetRequestRequest;
};
export type GetHeadsetRequestApiResponse =
  /** status 200 Get Headset Request Response.
   */ GetHeadsetRequestResponse;
export type GetHeadsetRequestApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5;
};
export type UpdateHeadsetRequestApiResponse = /** status 200 OK
 */ Success;
export type UpdateHeadsetRequestApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5;
  /** Update Headset Request Request.
   */
  updateHeadsetRequestRequest: UpdateHeadsetRequestRequest;
};
export type DeleteHeadsetRequestApiResponse = /** status 200 OK
 */ Success;
export type DeleteHeadsetRequestApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5;
};
export type LinkHeadsetRequestEventsApiResponse = /** status 200 OK
 */ Success;
export type LinkHeadsetRequestEventsApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5;
  /** Update Image Request.
   */
  linkHeadsetRequestEventsRequest: LinkHeadsetRequestEventsRequest;
};
export type ListHeadsetRequestHistoryApiResponse =
  /** status 200 List Headset Request History Response.
   */ ListHeadsetRequestHistoryResponse;
export type ListHeadsetRequestHistoryApiArg = {
  /** Target Headset Request ID.
   */
  headsetRequestId: FirestoreDocumentId5;
};
export type ListLicensesApiResponse = /** status 200 List Licenses Response.
 */ ListLicensesResponse;
export type ListLicensesApiArg = {
  filter?: {
    license_type?: string;
    active?: boolean;
    license_ids?: string[];
    end_before?: string;
    end_after?: string;
    start_before?: string;
    start_after?: string;
  };
};
export type CreateLicenseApiResponse = /** status 200 Create License Response.
 */ CreateLicenseResponse;
export type CreateLicenseApiArg = {
  /** Create License Request.
   */
  createLicenseRequest: CreateLicenseRequest;
};
export type GetLicenseApiResponse = /** status 200 Get License Response.
 */ GetLicenseResponse;
export type GetLicenseApiArg = {
  /** Target License ID.
   */
  licenseId: Uuid;
};
export type DeleteLicenseApiResponse = /** status 200 OK
 */ Success;
export type DeleteLicenseApiArg = {
  /** Target License ID.
   */
  licenseId: Uuid;
};
export type ListPlansApiResponse = /** status 200 List Plans Response.
 */ ListPlansResponse;
export type ListPlansApiArg = {
  filter?: {
    plan_type?: string;
    plan_ids?: string[];
    organization_ids?: string[];
    license_ids?: string[];
    active?: boolean;
    end_before?: string;
    end_after?: string;
    start_before?: string;
    start_after?: string;
  };
};
export type CreatePlanApiResponse = /** status 200 Create Plan Response.
 */ CreatePlanResponse;
export type CreatePlanApiArg = {
  /** Create Plan Request.
   */
  createPlanRequest: CreatePlanRequest;
};
export type GetPlanApiResponse = /** status 200 Get Plan Response.
 */ GetPlanResponse;
export type GetPlanApiArg = {
  /** Target Plan ID.
   */
  planId: Uuid;
};
export type DeletePlanApiResponse = /** status 200 OK
 */ Success;
export type DeletePlanApiArg = {
  /** Target Plan ID.
   */
  planId: Uuid;
};
export type PairDeviceApiResponse = /** status 200 Pair Device Success.
 */ undefined;
export type PairDeviceApiArg = {
  /** Pair Device Request.
   */
  pairDeviceRequest: PairDeviceRequest;
};
export type CreateJoinCodeApiResponse =
  /** status 200 Create Join Code Response.
   */ CreateJoinCodeResponse;
export type CreateJoinCodeApiArg = {
  /** Create Join Code Request.
   */
  createJoinCodeRequest: CreateJoinCodeRequest;
};
export type ExchangeJoinCodeApiResponse =
  /** status 200 Create Join Code Response.
   */ ExchangeJoinCodeResponse;
export type ExchangeJoinCodeApiArg = {
  /** Exchange Join Code Request.
   */
  exchangeJoinCodeRequest: ExchangeJoinCodeRequest;
};
export type CustomEventUserRegistrationApiResponse =
  /** status 200 Custom event registration Response.
   */ CreateJoinCodeResponse;
export type CustomEventUserRegistrationApiArg = {
  /** Target Event ID.
   */
  eventId: FirestoreDocumentId;
  /** Target User ID.
   */
  userId: FirebaseUserId;
  /** Custom event registration Request.
   */
  body: {
    invite_type?: EventUserInviteType;
  };
};
export type CreateRegistrationHeadsetRequestApiResponse =
  /** status 200 Create Registration Headset Request Response.
   */ CreateHeadsetRequestResponse;
export type CreateRegistrationHeadsetRequestApiArg = {
  /** Create Registration Headset Request Request.
   */
  createHeadsetRequestRequest: CreateHeadsetRequestRequest;
};
export type ListStreamServersApiResponse =
  /** status 200 List StreamServers Response.
   */ ListStreamServersResponse;
export type ListStreamServersApiArg = {
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    stream_server_ids?: string[];
    event_ids?: string[];
  };
};
export type UpsertStreamServerApiResponse =
  /** status 200 Upsert StreamServer Response.
   */ UpsertStreamServerResponse;
export type UpsertStreamServerApiArg = {
  /** Upsert StreamServer Request.
   */
  upsertStreamServerRequest: UpsertStreamServerRequest;
};
export type GetStreamServerApiResponse =
  /** status 200 Get StreamServer Response.
   */ GetStreamServerResponse;
export type GetStreamServerApiArg = {
  /** Target StreamServer ID.
   */
  streamServerId: FirestoreDocumentId5;
};
export type DeleteStreamServerApiResponse = /** status 200 OK
 */ Success;
export type DeleteStreamServerApiArg = {
  /** Target StreamServer ID.
   */
  streamServerId: FirestoreDocumentId5;
};
export type ListStreamServerInstanceTypesApiResponse =
  /** status 200 List StreamServer Instance Types Response.
   */ ListServerInstanceTypesResponse;
export type ListStreamServerInstanceTypesApiArg = void;
export type ListStreamServerImagesApiResponse =
  /** status 200 List StreamServer Images Response.
   */ ListServerImagesResponse;
export type ListStreamServerImagesApiArg = void;
export type ListStreamServerIPsApiResponse =
  /** status 200 List StreamServer IPs Response.
   */ ListServerIPsResponse;
export type ListStreamServerIPsApiArg = void;
export type ListCxrServersApiResponse = /** status 200 List CXRServers Response.
 */ ListCxrServersResponse;
export type ListCxrServersApiArg = {
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    cxr_server_ids?: string[];
    event_ids?: string[];
  };
};
export type CreateCxrServerApiResponse =
  /** status 200 Create CXRServer Response.
   */ CreateCxrServerResponse;
export type CreateCxrServerApiArg = {
  /** Create CXRServer Request.
   */
  createCxrServerRequest: CreateCxrServerRequest;
};
export type RefreshCxrServersApiResponse = /** status 200 OK
 */ Success;
export type RefreshCxrServersApiArg = void;
export type GrabCxrServerApiResponse =
  /** status 200 Get CXRServer Instance Types Response.
   */ GrabCxrServerResponse;
export type GrabCxrServerApiArg = void;
export type ListCxrInstanceTypesApiResponse =
  /** status 200 List CXR Instance Types Response.
   */ ListServerInstanceTypesResponse;
export type ListCxrInstanceTypesApiArg = void;
export type ListCxrImagesApiResponse = /** status 200 List CXR Images Response.
 */ ListServerImagesResponse;
export type ListCxrImagesApiArg = void;
export type ListCxriPsApiResponse = /** status 200 List CXR IPs Response.
 */ ListServerIPsResponse;
export type ListCxriPsApiArg = void;
export type GetCxrServerApiResponse = /** status 200 Get CXRServer Response.
 */ GetCxrServerResponse;
export type GetCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid;
};
export type UpdateCxrServerApiResponse = /** status 200 OK
 */ Success;
export type UpdateCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid;
  /** Update CXRServer Request.
   */
  updateCxrServerRequest: UpdateCxrServerRequest;
};
export type DeleteCxrServerApiResponse = /** status 200 OK
 */ Success;
export type DeleteCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid;
};
export type StartCxrServerApiResponse = /** status 200 OK
 */ Success;
export type StartCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid;
};
export type StopCxrServerApiResponse = /** status 200 OK
 */ Success;
export type StopCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid;
};
export type WaitCxrServerApiResponse = /** status 200 OK
 */ Success;
export type WaitCxrServerApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid;
  /** Wait CXRServer Request.
   */
  waitCxrServerRequest: WaitCxrServerRequest;
};
export type UpdateCxrServerInstanceStateApiResponse = /** status 200 OK
 */ Success;
export type UpdateCxrServerInstanceStateApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid;
  /** Update CXRServer Instance State Request.
   */
  updateCxrServerInstanceStateRequest: UpdateCxrServerInstanceStateRequest;
};
export type UnlinkCxrServerUserApiResponse = /** status 200 OK
 */ Success;
export type UnlinkCxrServerUserApiArg = {
  /** Target CXRServer ID.
   */
  cxrServerId: Uuid;
};
export type GetMetricsTokenApiResponse =
  /** status 200 Influx DB Token Data Response.
   */ GetMetricsTokenResponse;
export type GetMetricsTokenApiArg = void;
export type DeleteMetricsTokenApiResponse = /** status 200 OK
 */ Success;
export type DeleteMetricsTokenApiArg = {
  /** Target InfluxDB Token ID.
   */
  tokenId: string;
};
export type ListContractsApiResponse = /** status 200 List Contracts Response.
 */ ListContractsResponse;
export type ListContractsApiArg = {
  /** Fields to sort by. '-' prefix denotes descending order.
   */
  sort?:
    | "created_at"
    | "-created_at"
    | "updated_at"
    | "-updated_at"
    | "draft_date"
    | "-draft_date"
    | "effective_date"
    | "-effective_date"
    | "duration"
    | "-duration"
    | "status"
    | "-status";
  page?: {
    size?: number;
    number?: number;
  };
  filter?: {
    self?: boolean;
    organization_ids?: string[];
    status?: string[];
  };
};
export type CreateContractApiResponse = /** status 200 Create Contract Response.
 */ CreateContractResponse;
export type CreateContractApiArg = {
  /** Create Contract Request.
   */
  createContractRequest: CreateContractRequest;
};
export type GetContractApiResponse = /** status 200 Get Contract Response.
 */ GetContractResponse;
export type GetContractApiArg = {
  /** Target Contract ID.
   */
  contractId: Id;
};
export type UpdateContractApiResponse = /** status 200 OK
 */ Success;
export type UpdateContractApiArg = {
  /** Target Contract ID.
   */
  contractId: Id;
  /** Update Contract Request.
   */
  updateContractRequest: UpdateContractRequest;
};
export type DeleteContractApiResponse = /** status 200 OK
 */ Success;
export type DeleteContractApiArg = {
  /** Target Contract ID.
   */
  contractId: Id;
};
export type GetHealthcheckApiResponse = /** status 200 Health status
 */ GetHealthzResponse;
export type GetHealthcheckApiArg = void;
export type WhoAmIApiResponse = /** status 200 Authenticated user data.
 */ GetUserResponse;
export type WhoAmIApiArg = void;
export type SpeedTestApiResponse = /** status 200 Success.
 */ string;
export type SpeedTestApiArg = {
  /** Size of the payload to return.
   */
  size?: number;
  /** Status Code to return.
   */
  code?: number;
};
export type ResendInvitationEmailsApiResponse = /** status 200 OK
 */ Success;
export type ResendInvitationEmailsApiArg = void;
export type MetaResponse = {
  total_count?: number;
  total_pages?: number;
};
export type FirestoreDocumentId = string;
export type InternalId = string;
export type DateTime = string;
export type FirebaseUserId = string;
export type Metadata = {
  created_at: DateTime;
  updated_at: DateTime;
  deleted_at?: DateTime;
  created_by: FirebaseUserId;
  updated_by: FirebaseUserId;
  deleted_by?: FirebaseUserId;
};
export type OrganizationId = string;
export type FirestoreDocumentId2 = string;
export type FirestoreDocumentId3 = string;
export type EventUserRole =
  | "UNKNOWN"
  | "PHYSICIAN"
  | "NURSE"
  | "HEALTHCARE_PROFESSIONAL"
  | "SALES"
  | "MARKETING"
  | "R&D"
  | "EDUCATION"
  | "IT"
  | "HR"
  | "COMPLIANCE"
  | "TRAINEE"
  | "HOST"
  | "IMMERTEC_CUSTOMER_SUPPORT"
  | "IMMERTEC_EMPLOYEE"
  | "EMPLOYEE"
  | "OTHER";
export type EventUserRegistrationStatus =
  | "UNKNOWN"
  | "PENDING_USER"
  | "PENDING_CLIENT"
  | "CANCELLED_USER"
  | "CANCELLED_CLIENT"
  | "REJECTED_USER"
  | "REJECTED_CLIENT"
  | "ACCEPTED"
  | "CANCELLED_ACCEPTED_USER"
  | "CANCELLED_ACCEPTED_CLIENT"
  | "CANCELLED_EVENT";
export type HeadsetRequestStatus =
  | "UNKNOWN"
  | "REQUEST_INITIATED"
  | "REQUEST_PROCESSED"
  | "REQUEST_ALLOCATED"
  | "REQUEST_REJECTED"
  | "REQUEST_WAIVED"
  | "SHIPPED"
  | "DELIVERY_CONFIRMED"
  | "RETURN_PENDING"
  | "RETURN_TRANSIT"
  | "RETURN_WAIVED"
  | "RETURN_CONFIRMED"
  | "RETURN_DELINQUENT"
  | "MISSING_INFORMATION"
  | "SHIPPING_ERROR";
export type HeadsetRequestDeviceType =
  | "UNKNOWN"
  | "OCULUS_QUEST_1"
  | "OCULUS_QUEST_2"
  | "OCULUS_QUEST_PRO";
export type UserName = {
  prefix?: string;
  first: string;
  middle?: string;
  last: string;
  suffix?: string;
  alias?: string;
};
export type Address = {
  address_line_1: string;
  address_line_2?: string;
  city: string;
  zip: string;
  state: string;
  country?: string;
  company?: string;
  extra?: string;
};
export type TrackingInfo = {
  return_number?: string;
  return_date?: DateTime;
  shipping_number?: string;
  shipping_date?: DateTime;
};
export type HeadsetRequestLegacy = {
  status?: HeadsetRequestStatus;
  type?: HeadsetRequestDeviceType;
  date?: DateTime;
  name?: UserName;
  address?: Address;
  tracking_info?: TrackingInfo;
  late_fee_ack?: string;
};
export type EventUserNps = {
  answer: number;
  answered_at: DateTime;
};
export type EventUserInviteType = "VR" | "VR_NO_HEADSET" | "IMMERSIVE_2D";
export type EventUser = {
  role: EventUserRole;
  registration_status: EventUserRegistrationStatus;
  headset_request?: HeadsetRequestLegacy;
  nps?: EventUserNps;
  invite_types?: EventUserInviteType[];
};
export type EventUsers = {
  [key: string]: EventUser;
};
export type Email = string;
export type GuestInstructor = {
  email?: Email;
  name: UserName;
};
export type Id = string;
export type Uuid = string;
export type Url = string;
export type StreamSettings = {
  media_server_url?: Url & any;
  user_list_disabled?: boolean;
};
export type EventPrivacy = "PUBLIC" | "PRIVATE" | "ORGANIZATION";
export type EventStatus =
  | "UNKNOWN"
  | "REGISTRATION_OPEN"
  | "REGISTRATION_CLOSED"
  | "PATIENT_ARRIVED"
  | "PATIENT_PREPARED"
  | "TIMED_OUT"
  | "LIVE"
  | "STOPPED"
  | "CANCELLED"
  | "DELAYED";
export type ServerStatus = "NOT_RUNNING" | "RUNNING";
export type EventFunding = "UNKNOWN" | "PAID" | "UNPAID";
export type EventRegistrationState = "OPEN" | "CLOSED";
export type EventType =
  | "LIVE_BROADCAST"
  | "LIVE_BROADCAST_RECORDING"
  | "RECORDING_ONLY"
  | "BROADCAST_RECORDING"
  | "DEVELOPMENT"
  | "OPS_TESTING";
export type EventStreamType = "REGULAR" | "PRERECORDED";
export type Event = {
  event_id: FirestoreDocumentId;
  internal_id?: InternalId;
  slug?: string;
  metadata?: Metadata;
  organization_id: OrganizationId;
  location_id: FirestoreDocumentId2;
  instructor_ids?: FirestoreDocumentId3[];
  event_users?: EventUsers;
  event_users_count?: number;
  guest_instructors?: GuestInstructor[];
  specialty_id: Id;
  sub_specialty_id?: Uuid;
  cs_rep_user_id?: FirebaseUserId;
  demo_id?: Uuid;
  stream_settings?: StreamSettings;
  title: string;
  description: string;
  start_time: DateTime & any;
  end_time: DateTime & any;
  duration?: number;
  privacy: EventPrivacy;
  status: EventStatus;
  server_status?: ServerStatus;
  funding?: EventFunding;
  registration_state?: EventRegistrationState;
  type?: EventType;
  stream_type?: EventStreamType;
  flexible_time: boolean;
  seat_count?: number;
  photo?: Url;
  is_test?: boolean;
};
export type DigitalFootprint = {
  facebook?: Url;
  instagram?: Url;
  linkedin?: Url;
  twitter?: Url;
  website?: Url;
};
export type OrganizationStatus = "ACTIVE" | "INACTIVE";
export type Phone = {
  mobile?: string;
};
export type UserInfo = {
  email: Email;
  name: UserName;
  phone?: Phone;
};
export type Coordinates = {
  latitude?: number;
  longitude?: number;
};
export type OrganizationPrivacy = "PRIVATE" | "PUBLIC";
export type Organization = {
  organization_id: OrganizationId;
  internal_id?: InternalId;
  slug?: string;
  metadata?: Metadata;
  name: string;
  description: string;
  digital_footprint?: DigitalFootprint;
  status: OrganizationStatus;
  photo?: Url;
  owner: UserInfo;
  address: Address;
  coordinates?: Coordinates;
  cs_rep_user_id?: FirebaseUserId;
  privacy: OrganizationPrivacy;
  plan_id?: Id;
};
export type BaseTaxonomy = {
  id?: Id;
  metadata?: Metadata;
  parent_id?: Id;
};
export type BaseSpecialty = BaseTaxonomy & {
  name: string;
  photo?: Url;
};
export type Specialty = BaseSpecialty & {
  children?: {
    [key: string]: BaseSpecialty;
  };
};
export type SpecialtyCertificationStatus =
  | "UNKNOWN"
  | "PENDING"
  | "DECLARED"
  | "VERIFIED"
  | "REJECTED";
export type UserSpecialty = {
  certification?: {
    status?: SpecialtyCertificationStatus;
  };
  child_ids?: Id[];
};
export type UserSpecialties = {
  [key: string]: UserSpecialty;
};
export type InstructorStatus = "ACTIVE" | "COMING_SOON" | "INACTIVE";
export type Display = {
  marketing?: boolean;
};
export type Instructor = {
  instructor_id: FirestoreDocumentId3;
  internal_id?: InternalId;
  slug?: string;
  metadata?: Metadata;
  location_ids?: FirestoreDocumentId2[];
  organization_ids: FirestoreDocumentId2[];
  instructor_specialties: UserSpecialties;
  email: Email;
  name: UserName;
  phone?: Phone;
  status?: InstructorStatus;
  photo?: Url;
  quote?: string;
  practice?: string;
  description: string;
  digital_footprint?: DigitalFootprint;
  display?: Display;
  hs_instructor_id?: string;
};
export type LocationCategory =
  | "UNKNOWN"
  | "HOSPITAL"
  | "ASC"
  | "BIO_SKILLS_LAB"
  | "OFFICE_LAB"
  | "COMPANY_HQ_LAB"
  | "PRACTICE";
export type LocationStatus = "ACTIVE" | "COMING_SOON" | "UNAVAILABLE";
export type Location = {
  location_id: FirestoreDocumentId2;
  internal_id?: InternalId;
  slug?: string;
  metadata?: Metadata;
  instructor_ids?: FirestoreDocumentId3[];
  address: Address;
  category: LocationCategory;
  coordinates?: Coordinates;
  digital_footprint?: DigitalFootprint;
  name: string;
  description: string;
  photo?: Url;
  status: LocationStatus;
  display?: Display;
};
export type OrganizationRole =
  | "UNKNOWN"
  | "ADMIN"
  | "MANAGER"
  | "MEMBER"
  | "TRAINEE";
export type UserOrganization = {
  org_role?: OrganizationRole;
  license_id?: Uuid;
};
export type UserOrganizations = {
  [key: string]: UserOrganization;
};
export type UserEvents = {
  [key: string]: EventUser;
};
export type UserRole =
  | "UNKNOWN"
  | "USER"
  | "FIELD_SUPPORT_ENGINEER"
  | "MARKETING"
  | "CUSTOMER_SUCCESS"
  | "EVENT_SUCCESS"
  | "STREAMER"
  | "GLOBAL_ADMIN"
  | "IMMERTEC_EMPLOYEE";
export type UserClassification =
  | "UNKNOWN"
  | "MDC_PARTICIPANT"
  | "PARTICIPANT"
  | "INSTRUCTOR_KOL"
  | "IMMERTEC_EMPLOYEE"
  | "BUSINESS_DEVELOPMENT";
export type UserPreferences = {
  notifications?: {
    email?: boolean;
    sms?: boolean;
    push?: boolean;
  };
};
export type UserData = {
  user_id: FirebaseUserId;
  metadata?: Metadata;
  user_organizations: UserOrganizations;
  user_specialties?: UserSpecialties;
  user_events?: UserEvents;
  address?: Address;
  photo?: Url;
  user_role: UserRole;
  classification?: UserClassification;
  industry_id?: Id;
  job_type_id?: Id;
  job_title?: string;
  preferences?: UserPreferences;
  assistant?: UserInfo;
  location_ids?: FirestoreDocumentId2[];
  can_use_streamer?: boolean;
  accepted_terms?: boolean;
  email_verified?: boolean;
  last_login?: DateTime;
  last_auth_refresh?: DateTime;
  default_event_user_role?: EventUserRole;
};
export type User = UserInfo & UserData;
export type BaseIndustry = BaseTaxonomy & {
  name: string;
  photo?: Url;
};
export type Industry = BaseIndustry & {
  children?: {
    [key: string]: BaseIndustry;
  };
};
export type IncludedEvent = {
  organizations?: {
    [key: string]: Organization;
  };
  specialties?: {
    [key: string]: Specialty;
  };
  instructors?: {
    [key: string]: Instructor;
  };
  locations?: {
    [key: string]: Location;
  };
  users?: {
    [key: string]: User;
  };
  industries?: {
    [key: string]: Industry;
  };
};
export type ListEventsResponse = {
  meta?: MetaResponse;
  data?: Event[];
  included?: IncludedEvent;
};
export type Error = {
  success: false;
  message?: string;
};
export type Success = {
  success: boolean;
  message?: string;
};
export type CreateEventResponse = Success & {
  data: {
    event_id: FirestoreDocumentId;
  };
};
export type UpdateEventSettingsRequest = {
  stream_settings?: StreamSettings;
};
export type BaseEventRequest = UpdateEventSettingsRequest & {
  location_id: FirestoreDocumentId2;
  organization_id: OrganizationId;
  instructor_ids?: FirestoreDocumentId3[];
  specialty_id: Id;
  sub_specialty_id?: Uuid;
  cs_rep_user_id?: FirebaseUserId;
  demo_id?: Uuid;
  start_time: DateTime;
  end_time: DateTime;
  title: string;
  description?: string;
  seat_count?: number;
  privacy: EventPrivacy;
  funding?: EventFunding;
  type?: EventType;
  stream_type?: EventStreamType;
  registration_state?: EventRegistrationState;
  photo?: Url;
  guest_instructors?: GuestInstructor[];
  flexible_time?: boolean;
  skip_notifications?: boolean;
};
export type CreateEventRequest = BaseEventRequest & {
  status?: "REGISTRATION_OPEN" | "REGISTRATION_CLOSED";
};
export type CountEventsResponse = {
  data?: {
    count: number;
  };
};
export type UpdateEventRegistrationStateRequest = {
  registration_state: EventRegistrationState;
};
export type GetEventResponse = {
  data?: Event;
  included?: IncludedEvent;
};
export type UpdateEventStatusRequest = {
  status: EventStatus;
  skip_notifications?: boolean;
};
export type UpdateEventRequest = BaseEventRequest & UpdateEventStatusRequest;
export type StartStreamServerResponse = Success & {
  data: {
    url: string;
  };
};
export type CloudProvider = "AWS" | "GCP";
export type CreateCxrServerRequest = {
  provider?: CloudProvider;
  instance_type?: string;
  image_name?: string;
};
export type StartEventStreamServerRequest = CreateCxrServerRequest;
export type EventUserStatusAction = "INITIATE" | "CANCEL" | "ACCEPT";
export type EventDisconnectionReason =
  | "server namespace disconnect"
  | "client namespace disconnect"
  | "server shutting down"
  | "ping timeout"
  | "transport close"
  | "transport error"
  | "parse error"
  | "forced close"
  | "forced server close";
export type UserConnectionRecord = {
  connection_id?: string;
  start_time: DateTime;
  end_time: DateTime;
  disconnection_reason?: EventDisconnectionReason;
};
export type ClientType =
  | "CXR_INSTANCE"
  | "CXR_MANAGER"
  | "EVENTS"
  | "GH"
  | "IDB"
  | "IUC"
  | "IWS"
  | "MAD"
  | "MCR"
  | "MMA"
  | "MMD"
  | "MMS"
  | "MS"
  | "MTS"
  | "MWV"
  | "PORTAL"
  | "TD"
  | "MEDIA_ADMIN";
export type DeviceType =
  | "console"
  | "mobile"
  | "tablet"
  | "smarttv"
  | "wearable"
  | "embedded"
  | "pc";
export type UserTrackingData = {
  client_type?: ClientType;
  client_version?: string;
  client_build_number?: string;
  client_commit?: string;
  user_ip?: string;
  user_device_type?: DeviceType;
  user_device_model?: string;
  user_device_vendor?: string;
  user_device_pixel_ratio?: number;
  user_browser_name?: string;
  user_browser_version?: string;
  user_mobile_model?: string;
  user_mobile_vendor?: string;
  user_mobile_model_name?: string;
  user_os_name?: string;
  user_os_version?: string;
  user_screen_height?: number;
  user_screen_width?: number;
  user_location?: Coordinates;
};
export type EventConnectionUser = {
  user_id: FirebaseUserId;
  name: UserName;
  photo?: Url;
  start_time: DateTime & any;
  end_time: DateTime & any;
  attendance_percentage: number;
  total_connection_time: number;
  total_disconnection_time: number;
  total_disconnections: number;
  connections: UserConnectionRecord[];
  invite_types: EventUserInviteType[];
} & UserTrackingData;
export type EventConnectionsReport = {
  event_id: FirestoreDocumentId;
  start_time: DateTime & any;
  end_time: DateTime & any;
  total_connection_time: number;
  total_disconnection_time: number;
  total_disconnections: number;
  event_users?: EventConnectionUser[];
};
export type GetEventConnectionsReportResponse = {
  data?: EventConnectionsReport;
};
export type FirestoreDocumentId4 = string;
export type CreateReportResponse = Success & {
  data: {
    report_id: FirestoreDocumentId4;
  };
};
export type EventConnectionType =
  | "EVENT_USER_CONNECT"
  | "EVENT_USER_DISCONNECT";
export type CreateEventConnectionsReportRequest = {
  type: EventConnectionType;
  user_id: FirebaseUserId;
  disconnection_reason?: EventDisconnectionReason;
  connection_id?: string;
  is_streamer?: boolean;
};
export type ListEventConnectionsReportResponse = {
  data?: EventConnectionsReport[];
};
export type EventCo2EmissionUser = {
  user_id: FirebaseUserId;
  co2_emission: number;
};
export type EventCo2EmissionsReport = {
  event_id: FirestoreDocumentId;
  co2_emission_users: EventCo2EmissionUser[];
};
export type GetEventCo2EmissionsReportResponse = {
  data?: EventCo2EmissionsReport;
};
export type EventInfo = {
  event_id: FirestoreDocumentId;
  event_name: string;
  event_date: DateTime & any;
  event_start_date: DateTime & any;
  event_end_date: DateTime & any;
  event_duration: number;
  event_status: string;
  event_type: string;
  invite_types: EventUserInviteType[];
  viewer_type: string;
  organization_name: string;
  instructor_name: string;
  event_specialty: string;
  event_location_name: string;
  event_location_address: string;
  event_country: string;
  participant_id: string;
  participant_name: string;
  participant_email: Email;
  participant_role: string;
  nps_score: number;
  c02_emissions_saved: number;
  participant_attendance_duration: number;
};
export type EventInfoReport = {
  event_info_reports: EventInfo[];
};
export type GetEventInfoReportResponse = {
  data?: EventInfoReport;
};
export type UserEventNps = {
  event_id: Id;
  user_id: Id;
  nps_star: number;
};
export type NpsReport = {
  average_nps_star: number;
  average_nps_score: number;
  event_user_nps_reports: {
    [key: string]: UserEventNps[];
  };
};
export type ListNpsReportsResponse = {
  data?: {
    [key: string]: NpsReport;
  };
};
export type EventReport = {
  total_participants: number;
  total_2d_participants: number;
  total_vr_participants: number;
  total_engagement_time: number;
  total_vr_engagement_time: number;
  total_2d_engagement_time: number;
  total_co2_savings: number;
  total_2d_co2_savings: number;
  total_vr_co2_savings: number;
  average_participants: number;
  average_engagements: number;
  average_vr_engagements: number;
  average_2d_engagements: number;
  average_nps_star: number;
  average_2d_nps_star: number;
  average_vr_nps_star: number;
  average_nps_score: number;
  average_2d_nps_score: number;
  average_vr_nps_score: number;
  event_participant_reports: {
    [key: string]: EventInfo[];
  };
};
export type ListEventsReportsResponse = {
  meta?: MetaResponse;
  data?: {
    [key: string]: EventReport;
  };
};
export type RegistrationStatus =
  | "UNKNOWN"
  | "UNVERIFIED"
  | "INVITED"
  | "INCOMPLETE"
  | "COMPLETE";
export type DeviceId = string;
export type PairingCode = string;
export type Device = {
  device_id: DeviceId;
  metadata?: Metadata;
  name?: string;
  user_id: FirebaseUserId;
  pairing_code: PairingCode;
  serial_number: Uuid;
  paired_at: DateTime;
};
export type IncludedUser = {
  organizations?: {
    [key: string]: Organization;
  };
  specialties?: {
    [key: string]: Specialty;
  };
  industries?: {
    [key: string]: Industry;
  };
  instructors?: {
    [key: string]: Instructor;
  };
  devices?: {
    [key: string]: Device;
  };
  events?: {
    [key: string]: Event;
  };
  locations?: {
    [key: string]: Location;
  };
};
export type ListUsersResponse = {
  meta?: MetaResponse;
  data?: User[];
  included?: IncludedUser;
};
export type CreateUserResponse = Success & {
  data: {
    user_id: FirebaseUserId;
  };
};
export type BaseUserRequest = {
  user_specialties?: UserSpecialties;
  user_role?: UserRole;
  classification?: UserClassification;
  name: UserName;
  address?: Address;
  photo?: Url;
  industry_id?: Id;
  job_type_id?: Id;
  job_title?: string;
  location_ids?: FirestoreDocumentId2[];
  phone?: Phone;
  preferences?: UserPreferences;
  assistant?: UserInfo;
  default_event_user_role?: EventUserRole;
  accepted_terms?: boolean;
};
export type CreateUserRequest = BaseUserRequest & {
  email: Email;
  user_organizations?: UserOrganizations;
  skip_notifications?: boolean;
};
export type CountUsersResponse = {
  data?: {
    count: number;
  };
};
export type EmailInviteRequest = {
  email: Email;
};
export type ResendHeadsetRequestRequest = {
  user_id: FirebaseUserId;
  event_id: FirestoreDocumentId;
};
export type MeetingId = number;
export type Passcode = number;
export type VrPasscode = number;
export type ExchangeJoinCodeRequest =
  | {
      meeting_id: MeetingId;
      passcode: Passcode;
    }
  | {
      vr_passcode: VrPasscode;
      serial_number?: Uuid;
    };
export type EmailVerifyRequest = {
  email: Email;
};
export type GetUserResponse = {
  data?: User;
  included?: IncludedUser;
};
export type UpdateUserRequest = BaseUserRequest;
export type UpsertUserOrganizationRequest = {
  org_role?: OrganizationRole;
};
export type SendInvitationRequest = {
  invite_type?: EventUserInviteType;
};
export type UserRegistrationStatus = {
  registration_status: RegistrationStatus;
};
export type GetUserRegistrationStatusResponse = {
  data?: UserRegistrationStatus;
};
export type UserConnectionsReport = {
  event_id: FirestoreDocumentId;
  start_time: DateTime & any;
  end_time: DateTime & any;
  total_connection_time: number;
  total_disconnection_time: number;
  total_disconnections: number;
};
export type ListUserConnectionsReportResponse = {
  data?: {
    [key: string]: UserConnectionsReport[];
  };
};
export type ListOrganizationsResponse = {
  meta?: MetaResponse;
  data?: Organization[];
};
export type CreateOrganizationResponse = Success & {
  data: {
    organization_id: OrganizationId;
  };
};
export type CreateOrganizationRequest = {
  name: string;
  description: string;
  digital_footprint?: DigitalFootprint;
  status: OrganizationStatus;
  privacy: OrganizationPrivacy;
  owner: UserInfo;
  address: Address;
  photo?: Url;
  coordinates?: Coordinates;
  cs_rep_user_id?: FirebaseUserId;
};
export type CountOrganizationsResponse = {
  data?: {
    count: number;
  };
};
export type GetOrganizationResponse = {
  data?: Organization;
};
export type UpdateOrganizationRequest = CreateOrganizationRequest;
export type GetOrganizationEventsReportsResponse = {
  data?: EventInfoReport;
};
export type IncludedInstructor = {
  organizations?: {
    [key: string]: Organization;
  };
  specialties?: {
    [key: string]: Specialty;
  };
  locations?: {
    [key: string]: Location;
  };
};
export type ListInstructorsResponse = {
  meta?: MetaResponse;
  data?: Instructor[];
  included?: IncludedInstructor;
};
export type CreateInstructorResponse = Success & {
  data: {
    instructor_id: FirestoreDocumentId3;
  };
};
export type CreateInstructorRequest = {
  organization_ids: OrganizationId[];
  location_ids?: FirestoreDocumentId2[];
  instructor_specialties: UserSpecialties & any;
  email: Email & any;
  name: UserName;
  photo?: Url & any;
  phone?: Phone;
  status?: InstructorStatus;
  slug?: string;
  quote?: string;
  practice?: string;
  description: string;
  digital_footprint?: DigitalFootprint;
  display?: Display;
  hs_instructor_id?: string;
};
export type CountInstructorsResponse = {
  data?: {
    count: number;
  };
};
export type GetInstructorResponse = {
  data?: Instructor;
  included?: IncludedInstructor;
};
export type UpdateInstructorRequest = CreateInstructorRequest;
export type IncludedLocation = {
  instructors?: {
    [key: string]: Instructor;
  };
};
export type ListLocationsResponse = {
  meta?: MetaResponse;
  data?: Location[];
  included?: IncludedLocation;
};
export type CreateLocationResponse = Success & {
  data: {
    location_id: FirestoreDocumentId2;
  };
};
export type CreateLocationRequest = {
  instructor_ids?: FirestoreDocumentId3[];
  address: Address;
  category: LocationCategory;
  name: string;
  description: string;
  status: LocationStatus;
  coordinates?: Coordinates;
  photo?: Url;
  digital_footprint?: DigitalFootprint;
  display?: Display;
  slug?: string;
};
export type CountLocationsResponse = {
  data?: {
    count: number;
  };
};
export type GetLocationResponse = {
  data?: Location;
  included?: IncludedLocation;
};
export type UpdateLocationRequest = CreateLocationRequest;
export type ListSpecialtiesResponse = {
  meta?: MetaResponse;
  data?: Specialty[];
};
export type CreateSpecialtyResponse = Success & {
  data: {
    id: Id;
  };
};
export type BaseTaxonomyRequest = {
  parent_id?: Id;
};
export type CreateSpecialtyRequest = BaseTaxonomyRequest & BaseSpecialty;
export type GetSpecialtyResponse = {
  data?: Specialty;
};
export type UpdateSpecialtyRequest = CreateSpecialtyRequest;
export type ListIndustriesResponse = {
  meta?: MetaResponse;
  data?: Industry[];
};
export type CreateIndustryResponse = Success & {
  data: {
    id: Id;
  };
};
export type CreateIndustryRequest = BaseTaxonomyRequest & BaseIndustry;
export type GetIndustryResponse = {
  data?: Industry;
};
export type UpdateIndustryRequest = CreateIndustryRequest;
export type IncludedDevice = {
  users?: {
    [key: string]: User;
  };
};
export type ListDevicesResponse = {
  meta?: MetaResponse;
  data?: Device[];
  included?: IncludedDevice;
};
export type CreateDeviceResponse = Success & {
  data: {
    device_id: DeviceId;
  };
};
export type CreateDeviceRequest = {
  name?: string;
  pairing_code: PairingCode;
  serial_number: Uuid;
};
export type GetDeviceResponse = {
  data?: Device;
  included?: IncludedDevice;
};
export type UpdateDeviceRequest = CreateDeviceRequest;
export type Image = {
  image_id: Uuid;
  metadata?: Metadata;
  name: string;
  url: Url;
  tags?: string[];
  public?: boolean;
  organization_id?: OrganizationId;
};
export type IncludedImage = {};
export type ListImagesResponse = {
  meta?: MetaResponse;
  data?: Image[];
  included?: IncludedImage;
};
export type CreateImageResponse = Success & {
  data: {
    image_id: Uuid;
  };
};
export type CreateImageRequest = {
  name: string;
  url: Url;
  tags?: string[];
  public?: boolean;
  organization_id?: OrganizationId;
};
export type GetImageResponse = {
  data?: Image;
  included?: IncludedImage;
};
export type UpdateImageRequest = CreateImageRequest;
export type TrackingId = number;
export type MainVideo = {
  url: Url;
  url_sm?: Url;
  settings?: {
    fov?: number;
    horizontal_shift?: number;
    vertical_shift?: number;
  };
};
export type VrVideo = {
  url: Url;
  settings?: {
    fov?: number;
    horizontal_shift?: number;
    vertical_shift?: number;
  };
};
export type Overlay = {
  url: Url;
  label?: string;
};
export type DemoAttendee = {
  name: string;
  photo?: Url;
};
export type Demo = {
  demo_id: Uuid;
  metadata?: Metadata;
  location_id: FirestoreDocumentId2;
  specialty_id: Id;
  tracking_id: TrackingId;
  organization_ids: OrganizationId[];
  instructor_ids?: FirestoreDocumentId3[];
  title: string;
  description?: string;
  photo?: Url;
  main_video: MainVideo;
  vr_video?: VrVideo;
  overlays?: Overlay[];
  attendees?: DemoAttendee[];
  guest_instructors?: GuestInstructor[];
  duration?: number;
  views?: number;
  is_public?: boolean;
  allowed_organization_ids?: OrganizationId[];
  allowed_user_ids?: FirebaseUserId[];
  group_name?: string;
};
export type IncludedDemo = {
  organizations?: {
    [key: string]: Organization;
  };
  instructors?: {
    [key: string]: Instructor;
  };
};
export type ListDemosResponse = {
  meta?: MetaResponse;
  data?: Demo[];
  included?: IncludedDemo;
};
export type CreateDemoResponse = Success & {
  data: {
    demo_id: Uuid;
  };
};
export type CreateDemoRequest = {
  organization_ids: OrganizationId[];
  instructor_ids?: FirestoreDocumentId3[];
  guest_instructors?: GuestInstructor[];
  specialty_id: Id;
  location_id: FirestoreDocumentId2;
  title: string;
  description?: string;
  photo?: Url;
  duration?: number;
  main_video: MainVideo;
  vr_video?: VrVideo;
  overlays?: Overlay[];
  attendees?: DemoAttendee[];
  is_public?: boolean;
  allowed_organization_ids?: OrganizationId[];
  allowed_user_ids?: FirebaseUserId[];
  group_name?: string;
};
export type DemosReport = {
  total_views: number;
  average_views: number;
  demos: Demo[];
};
export type DemosReportResponse = {
  meta?: MetaResponse;
  data?: DemosReport;
};
export type GetDemoUploadUrlResponse = Success & {
  data: {
    url: string;
  };
};
export type DemoUsersReport = {
  total_time: number;
  total_views: number;
  demo_user_reports: object;
};
export type DemoUsersReportResponse = {
  meta?: MetaResponse;
  data?: DemoUsersReport;
};
export type GetDemoResponse = {
  data?: Demo;
};
export type UpdateDemoRequest = CreateDemoRequest;
export type DemoTracking = {
  demo_tracking_id: TrackingId;
  demo_id: Uuid;
  user_id: Uuid;
  applicant_id?: Uuid;
  metadata: Metadata;
  nps?: EventUserNps;
  feedback?: string;
  notes?: string;
} & UserTrackingData;
export type ListDemoTrackingsResponse = {
  meta?: MetaResponse;
  data?: DemoTracking[];
};
export type CreateDemoTrackingResponse = Success & {
  data: {
    demo_id?: Uuid;
  };
};
export type CreateDemoTrackingRequest = {
  demo_id: Uuid;
  user_id?: Uuid;
  created_at?: DateTime;
  nps?: {
    answer?: number;
  };
  feedback?: string;
  notes?: string;
};
export type DemoTrackingActionType =
  | "PLAYBACK_PLAY"
  | "PLAYBACK_PAUSE"
  | "PLAYBACK_STOP"
  | "PLAYBACK_MOVE"
  | "PLAYBACK_MUTE"
  | "PLAYBACK_UNMUTE"
  | "OVERLAY_MOVE"
  | "OVERLAY_RESIZE"
  | "OVERLAY_SWAP"
  | "MICROPHONE_MUTE"
  | "MICROPHONE_UNMUTE";
export type DemoTrackingActionData = {
  url?: Url;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  screen_width?: number;
  screen_height?: number;
  current_playback_time?: number;
  new_playback_time?: number;
};
export type DemoTrackingAction = {
  demo_tracking_action_id: Uuid;
  demo_tracking_id: TrackingId;
  action_type: DemoTrackingActionType;
  action_data?: DemoTrackingActionData;
  created_at?: DateTime;
};
export type ListDemoTrackingActionsResponse = {
  meta?: MetaResponse;
  data?: DemoTrackingAction[];
};
export type CreateDemoTrackingActionResponse = Success & {
  data: {
    demo_tracking_action_id: Uuid;
  };
};
export type CreateDemoTrackingActionRequest = {
  demo_tracking_id: TrackingId;
  action_type: DemoTrackingActionType;
  action_data?: DemoTrackingActionData;
  created_at?: string;
};
export type HeadsetRequestLoanType = "UNKNOWN" | "NORMAL" | "PERMANENT";
export type HeadsetRequest = {
  headset_request_id: Uuid;
  metadata?: Metadata;
  user_id: FirebaseUserId;
  event_ids?: FirestoreDocumentId[];
  organization_id?: OrganizationId;
  status?: HeadsetRequestStatus;
  device_type?: HeadsetRequestDeviceType;
  loan_type?: HeadsetRequestLoanType;
  expected_delivery_date?: DateTime;
  order_date?: DateTime;
  name?: UserName;
  address?: Address;
  tracking_info?: TrackingInfo;
  late_fee_ack?: string;
  user_classification?: UserClassification;
  headset_serial_number?: string;
};
export type ListHeadsetRequestsResponse = {
  meta?: MetaResponse;
  data?: HeadsetRequest[];
};
export type FirestoreDocumentId5 = string;
export type CreateHeadsetRequestResponse = Success & {
  data: {
    headset_request_id: FirestoreDocumentId5;
  };
};
export type CreateHeadsetRequestRequest = {
  user_id?: string;
  event_ids?: FirestoreDocumentId[];
  organization_id?: OrganizationId;
  status?: HeadsetRequestStatus;
  device_type?: HeadsetRequestDeviceType;
  loan_type?: HeadsetRequestLoanType;
  expected_delivery_date?: DateTime;
  order_date?: DateTime;
  name?: UserName;
  address?: Address;
  tracking_info?: TrackingInfo;
  late_fee_ack?: string;
  user_classification?: UserClassification;
  headset_serial_number?: string;
};
export type GetHeadsetRequestResponse = {
  data?: HeadsetRequest;
};
export type UpdateHeadsetRequestRequest = CreateHeadsetRequestRequest;
export type LinkHeadsetRequestEventsRequest = {
  event_ids: FirestoreDocumentId[];
};
export type ListHeadsetRequestHistoryResponse = {
  meta?: MetaResponse;
  data?: HeadsetRequest[];
};
export type LicenseType = "IMMERSIVE_2D" | "IMMERSIVE_3D" | "IMMERSIVE_3D_MDM";
export type License = {
  license_id?: Uuid;
  metadata?: Metadata;
  license_type: LicenseType;
  start_date?: string;
  end_date?: string;
  plan_id: Uuid;
  user_id?: FirebaseUserId;
};
export type ListLicensesResponse = {
  meta?: MetaResponse;
  data?: License[];
};
export type CreateLicenseResponse = Success & {
  data: {
    license_id?: Uuid;
  };
};
export type CreateLicenseRequest = {
  license_type: LicenseType;
  plan_id: Uuid;
  user_id?: FirebaseUserId;
};
export type GetLicenseResponse = {
  data?: License;
};
export type PlanType = "STANDARD" | "PREMIUM" | "ENTERPRISE";
export type Plan = {
  plan_id?: Uuid;
  metadata?: Metadata;
  plan_type: PlanType;
  start_date: string;
  end_date: string;
  license_type_immersive_2d_limit: number;
  license_type_immersive_3d_limit: number;
  license_type_immersive_3d_mdm_limit: number;
  organization_id: OrganizationId;
};
export type ListPlansResponse = {
  meta?: MetaResponse;
  data?: (Plan & {
    license_ids: Uuid[];
  })[];
};
export type CreatePlanResponse = Success & {
  data: {
    plan_id?: Uuid;
  };
};
export type CreatePlanRequest = {
  plan_type: PlanType;
  start_date: string;
  end_date: string;
  license_type_immersive_2d_limit?: number;
  license_type_immersive_3d_limit?: number;
  license_type_immersive_3d_mdm_limit?: number;
  organization_id: OrganizationId;
};
export type GetPlanResponse = {
  data?: Plan & {
    license_ids: Uuid[];
  };
};
export type PairDeviceAction = "PAIR_DEVICE" | "UNPAIR_DEVICE";
export type PairDeviceRequest = {
  pairing_code: PairingCode;
  event_id?: FirestoreDocumentId;
};
export type CreateJoinCodeResponse = Success &
  (
    | {
        data: {
          join_code_id: FirestoreDocumentId5;
          meeting_id: MeetingId;
          passcode: Passcode;
          expiration: DateTime;
        };
      }
    | {
        data: {
          join_code_id: FirestoreDocumentId5;
          passcode: VrPasscode;
          expiration: DateTime;
        };
      }
  );
export type CreateJoinCodeRequest = {
  user_id: FirebaseUserId;
  event_id?: FirestoreDocumentId;
  serial_number?: Uuid;
};
export type ExchangeJoinCodeResponse = Success & {
  data: {
    token: string;
    event_id?: FirestoreDocumentId;
    user_id: FirebaseUserId;
  };
};
export type InstanceImage = {
  provider: CloudProvider;
  image_id: string;
  name: string;
  versions: {
    [key: string]: string;
  };
  created_at: DateTime;
};
export type InstanceIp = {
  provider: CloudProvider;
  ip_id: string;
  domain: string;
  public_ip: string;
  private_ip: string;
};
export type Server = {
  server_id: Uuid;
  provider: CloudProvider;
  name: string;
  instance_id: string;
  image: InstanceImage;
  instance_type: string;
  launch_time?: DateTime;
  instance_state: string;
  ip: InstanceIp;
  tags: {
    [key: string]: string;
  };
  manager_version: string;
  metadata: Metadata;
};
export type StreamServer = Server & {
  event_id: FirestoreDocumentId;
};
export type ListStreamServersResponse = {
  meta?: MetaResponse;
  data?: StreamServer[];
};
export type UpsertStreamServerResponse = Success & {
  data: {
    headset_request_id?: FirestoreDocumentId5;
  };
};
export type UpsertStreamServerRequest = {
  event_id: FirestoreDocumentId;
  user_id: FirebaseUserId;
  eip_id: string;
  public_ip: string;
  domain: string;
  instance_id: string;
  ami_image_id: string;
  instance_type: string;
  launch_time: DateTime;
};
export type GetStreamServerResponse = {
  data?: StreamServer;
};
export type InstanceType = {
  provider: CloudProvider;
  name: string;
};
export type ListServerInstanceTypesResponse = {
  meta?: MetaResponse;
  data?: InstanceType[];
};
export type ListServerImagesResponse = {
  meta?: MetaResponse;
  data?: InstanceImage[];
};
export type ListServerIPsResponse = {
  meta?: MetaResponse;
  data?: InstanceIp[];
};
export type CxrServer = Server & {
  user_id?: FirebaseUserId;
  device_id?: DeviceId;
  monitor_running?: DateTime;
  monitor_first_start?: DateTime;
  monitor_prepped?: DateTime;
  ready?: DateTime;
};
export type ListCxrServersResponse = {
  meta?: MetaResponse;
  data?: CxrServer[];
};
export type CreateCxrServerResponse = Success & {
  data: {
    cxr_server_id: string;
  };
};
export type GrabCxrServerResponse = {
  data?: {
    cxr_server_id: Uuid;
    public_ip: string;
    domain: string;
  };
};
export type GetCxrServerResponse = {
  data?: CxrServer;
};
export type UpdateCxrServerRequest = {
  launch_time?: DateTime;
  instance_state?: string;
  monitor_running?: boolean;
  ready?: boolean;
};
export type WaitCxrServerRequest = {
  instance_state: string;
};
export type UpdateCxrServerInstanceStateRequest = {
  instance_state: string;
};
export type InfluxDbMetricsToken = {
  id: string;
  org: string;
  bucket: string;
  token: string;
};
export type GetMetricsTokenResponse = {
  data?: InfluxDbMetricsToken;
};
export type ContractStatus =
  | "CONTRACT_OPEN"
  | "CONTRACT_CLOSED"
  | "CONTRACT_PREPARED";
export type Contract = {
  status: ContractStatus;
  draft_date: string;
  effective_date: string;
  duration: number;
  fields: string;
  max_2d_licenses_per_event: number;
  max_vr_licenses_per_event: number;
  num_admin_licenses: number;
  num_white_glove_services: number;
  organization_id: OrganizationId;
};
export type ListContractsResponse = {
  meta?: MetaResponse;
  data?: Contract[];
};
export type CreateContractResponse = Success & {
  data: {
    demo_id: Uuid;
  };
};
export type CreateContractRequest = {
  status?: ContractStatus;
  draft_date?: string;
  effective_date?: string;
  duration?: number;
  fields: string;
  max_2d_licenses_per_event: number;
  max_vr_licenses_per_event: number;
  num_admin_licenses: number;
  num_white_glove_services: number;
  organization_id: OrganizationId;
  payment_terms?: string;
};
export type GetContractResponse = {
  data?: Contract;
};
export type UpdateContractRequest = CreateContractRequest;
export type Healthz = {
  env: string;
  status: "UNHEALTHY" | "HEALTHY";
  version: string;
};
export type GetHealthzResponse = {
  data?: Healthz;
};
export const {
  useListEventsQuery,
  useCreateEventMutation,
  useCountEventsQuery,
  useUpdateEventRegistrationStateMutation,
  useGetEventQuery,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useUpdateEventSettingsMutation,
  useUpdateEventStatusMutation,
  useStartEventStreamServerMutation,
  useTerminateEventStreamServerMutation,
  useUpsertEventUserMutation,
  useDeleteEventUserMutation,
  usePostEventUserStatusActionsMutation,
  usePostEventUserRoleActionMutation,
  usePostEventUserNpsActionMutation,
  useGetConnectionsReportQuery,
  useCreateConnectionReportMutation,
  useListEventConnectionsReportQuery,
  useGetCo2EmissionsReportQuery,
  useCreateCo2EmissionReportMutation,
  useGetInfoReportQuery,
  useListEventNpsReportsQuery,
  useListEventsReportsQuery,
  useListUsersQuery,
  useCreateUserMutation,
  useCountUsersQuery,
  useInviteEmailMutation,
  useResendInviteHeadsetRequestMutation,
  useResendInviteJoinCodeMutation,
  useVerifyEmailMutation,
  useSendEmailAccountRegistrationConfirmationMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpsertUserOrganizationMutation,
  useDeleteUserOrganizationMutation,
  useSendEmailInviteGeneralMutation,
  useSendEmailInviteEventMutation,
  useUserRegistrationStatusQuery,
  useVerifyUserMutation,
  useSendEmailVerificationMutation,
  useSendAccountRemovalRequestNotificationsMutation,
  useListUserConnectionsReportQuery,
  useListUserEventsReportsQuery,
  useListOrganizationsQuery,
  useCreateOrganizationMutation,
  useCountOrganizationsQuery,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationEventsReportsQuery,
  useListOrganizationNpsReportsQuery,
  useListOrganizationEventsReportsQuery,
  useListInstructorsQuery,
  useCreateInstructorMutation,
  useCountInstructorsQuery,
  useGetInstructorQuery,
  useUpdateInstructorMutation,
  useDeleteInstructorMutation,
  useListInstructorNpsReportsQuery,
  useListInstructorEventsReportsQuery,
  useListLocationsQuery,
  useCreateLocationMutation,
  useCountLocationsQuery,
  useGetLocationQuery,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
  useListLocationNpsReportsQuery,
  useListLocationEventsReportsQuery,
  useListSpecialtiesQuery,
  useCreateSpecialtyMutation,
  useGetSpecialtyQuery,
  useUpdateSpecialtyMutation,
  useDeleteSpecialtyMutation,
  useGetSubSpecialtyQuery,
  useUpdateSubSpecialtyMutation,
  useDeleteSubSpecialtyMutation,
  useListIndustriesQuery,
  useCreateIndustryMutation,
  useGetIndustryQuery,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,
  useGetIndustryJobTypeQuery,
  useUpdateIndustryJobTypeMutation,
  useDeleteIndustryJobTypeMutation,
  useListDevicesQuery,
  useCreateDeviceMutation,
  useGetDeviceQuery,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useListImagesQuery,
  useCreateImageMutation,
  useGetImageQuery,
  useUpdateImageMutation,
  useDeleteImageMutation,
  useListDemosQuery,
  useCreateDemoMutation,
  useDemosReportQuery,
  useGetDemoUploadUrlQuery,
  useDemoUsersReportQuery,
  useGetDemoQuery,
  useUpdateDemoMutation,
  useDeleteDemoMutation,
  useListDemoTrackingsQuery,
  useCreateDemoTrackingMutation,
  useListDemoTrackingActionsQuery,
  useCreateDemoTrackingActionMutation,
  useGetHeadsetRequestsReportsQuery,
  useListHeadsetRequestsQuery,
  useCreateHeadsetRequestMutation,
  useGetHeadsetRequestQuery,
  useUpdateHeadsetRequestMutation,
  useDeleteHeadsetRequestMutation,
  useLinkHeadsetRequestEventsMutation,
  useListHeadsetRequestHistoryQuery,
  useListLicensesQuery,
  useCreateLicenseMutation,
  useGetLicenseQuery,
  useDeleteLicenseMutation,
  useListPlansQuery,
  useCreatePlanMutation,
  useGetPlanQuery,
  useDeletePlanMutation,
  usePairDeviceMutation,
  useCreateJoinCodeMutation,
  useExchangeJoinCodeMutation,
  useCustomEventUserRegistrationMutation,
  useCreateRegistrationHeadsetRequestMutation,
  useListStreamServersQuery,
  useUpsertStreamServerMutation,
  useGetStreamServerQuery,
  useDeleteStreamServerMutation,
  useListStreamServerInstanceTypesQuery,
  useListStreamServerImagesQuery,
  useListStreamServerIPsQuery,
  useListCxrServersQuery,
  useCreateCxrServerMutation,
  useRefreshCxrServersMutation,
  useGrabCxrServerMutation,
  useListCxrInstanceTypesQuery,
  useListCxrImagesQuery,
  useListCxriPsQuery,
  useGetCxrServerQuery,
  useUpdateCxrServerMutation,
  useDeleteCxrServerMutation,
  useStartCxrServerMutation,
  useStopCxrServerMutation,
  useWaitCxrServerMutation,
  useUpdateCxrServerInstanceStateMutation,
  useUnlinkCxrServerUserMutation,
  useGetMetricsTokenQuery,
  useDeleteMetricsTokenMutation,
  useListContractsQuery,
  useCreateContractMutation,
  useGetContractQuery,
  useUpdateContractMutation,
  useDeleteContractMutation,
  useGetHealthcheckQuery,
  useWhoAmIQuery,
  useSpeedTestQuery,
  useResendInvitationEmailsMutation,
} = api;

