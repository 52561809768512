import { lighten } from "polished"

export const colors = {
  primary: "#3b6cb3",
  secondary: "#47D7AC",
  white: "#fff",
  dark: "#34434d",
  light: "#a3a3a3",
  blue: "#3b6cb3",
  green: "#15b937",
  red: "#e11932",
  yellow: "#e88836",
  gray: "#5a6874",
  lightgray: "#cdd0d1",
  darkgray: "#1a2126",
  black: "#010507",
  text: "white",
  background: "#07161e",
  side: "#1a3545",
  grays: {
    50: "#F6F7FB",
    100: "#E4E6ED",
    200: "#BDC2C7",
    300: "#929BA3",
    400: "#6F7E8B",
    500: "#2D3F4E",
    600: "#21303D",
    700: "#1B2830",
    800: "#141D24",
    900: "#0E151A",
  },
}

export default {
  colors: colors,
  fonts: {
    body: "Gilroy, sans-serif",
    heading: "Gilroy, sans-serif",
  },
  fontSizes: [14, 16, 18, 22, 26, 32],
  fontWeights: {
    body: 400,
    bold: 700,
  },
  sizes: {
    avatar: 35,
  },
  forms: {
    pinInput: {
      borderRadius: 3,
      background: "side",
    },
  },
  radii: {
    small: 4,
    medium: 6,
    big: 10,
    max: "100%",
  },
  shadows: {
    buttonOutside: "0 2px 8px rgba(0,0,0, .35)",
    buttonInside: "inset 1px 2px 2px rgba(0,0,0, .35) !important",
    none: "none !important",
  },
  variants: {
    avatar: {
      width: "avatar",
      height: "avatar",
      borderRadius: "max",
    },
    link: {
      color: "blue", // colors.blue
      textDecoration: "underline",
      ":hover": {
        textDecoration: "none",
      },
    },
  },
  buttons: {
    success: {
      bg: "green", // colors.green
      color: "white",
      ":hover": { bg: lighten(0.1, colors.green) },
      ":disabled": { bg: lighten(0.2, colors.green) },
    },
    primary: {
      bg: "blue",
      color: "white",
      ":hover": { bg: lighten(0.1, colors.blue) },
      ":disabled": { bg: lighten(0.2, colors.blue) },
    },
    secondary: {
      bg: "gray",
      ":hover": { bg: lighten(0.1, colors.gray) },
      ":disabled": { bg: lighten(0.2, colors.gray) },
    },
    dark: {
      bg: "darkgray",
      ":hover": { bg: lighten(0.1, colors.darkgray) },
      ":disabled": { bg: lighten(0.2, colors.darkgray) },
    },
    danger: {
      bg: "red",
      color: "white",
      ":hover": { bg: lighten(0.1, colors.red) },
      ":disabled": { bg: lighten(0.2, colors.red) },
    },
    warning: {
      bg: "yellow",
      color: "white",
      ":hover": { bg: lighten(0.1, colors.yellow) },
      ":disabled": { bg: lighten(0.2, colors.yellow) },
    },
    outline: {
      color: `${colors.lightgray} !important`,
      backgroundColor: "transparent",
      borderColor: `${colors.dark} !important`,
      ":hover": {
        borderColor: `${colors.gray} !important`,
      },
    },
  },
}
