import * as model from "./model"
import { enums } from "./enums"

import type { NamePrefix, NameSuffix } from "./enums"
import { UserRole } from "./generated"

const normalizeUserName = (data: Partial<model.generated.UserName> = {}): model.UserName => ({
  prefix: data.prefix as NamePrefix,
  first: data.first ?? "",
  middle: data.middle,
  last: data.last ?? "",
  suffix: data.suffix as NameSuffix,
  alias: data.alias,
})

const normalizeUserPreferences = (data: Partial<model.generated.UserPreferences> = {}): model.UserPreferences => ({
  notifications: {
    email: data.notifications?.email ?? false,
    sms: data.notifications?.sms ?? false,
    push: data.notifications?.push ?? false,
  },
})

const normalizeUserInfo = (data: Partial<model.generated.UserInfo> = {}): model.UserInfo => ({
  email: data?.email ?? "",
  name: normalizeUserName(data?.name),
  phone: data?.phone,
})

const normalizeAddress = (addr: Partial<model.generated.Address> = {}): model.Address | undefined => {
  if (
    !addr.address_line_1 &&
    !addr.address_line_2 &&
    !addr.city &&
    !addr.zip &&
    !addr.state &&
    !addr.country &&
    !addr.company
  ) {
    return undefined
  }
  return mustNormalizeAddress(addr)
}

const mustNormalizeAddress = (addr: Partial<model.generated.Address> = {}): model.Address => {
  return {
    addressLine1: addr.address_line_1 ?? "",
    addressLine2: addr.address_line_2,
    city: addr.city ?? "",
    zip: addr.zip ?? "",
    state: addr.state ?? "",
    country: addr.country,
    company: addr.company,
    extra: addr.extra,
  }
}

const normalizeTrackingInfo = (data: Partial<model.generated.TrackingInfo>): model.TrackingInfo => ({
  returnNumber: data.return_number,
  shippingNumber: data.shipping_number,
})

const normalizeHeadsetRequest = (data: Partial<model.generated.HeadsetRequest>): model.NormalizedHeadsetRequest => ({
  status: data.status ?? enums.CommonStatus.UNKNOWN,
  date: !data.order_date ? 0 : new Date(data.order_date).getTime(),
  trackingInfo: normalizeTrackingInfo(data.tracking_info ?? {}),
  name: normalizeUserName(data.name),
  address: normalizeAddress(data.address),
  lateFeeAck: !data.late_fee_ack ? 0 : new Date(data.late_fee_ack).getTime(),
})

const normalizeEventUser = (userId: string, data: Partial<model.generated.EventUser>): model.NormalizedEventUser => {
  return {
    id: userId,
    registrationStatus: data.registration_status ?? enums.CommonStatus.UNKNOWN,
    role: data.role ?? enums.CommonStatus.UNKNOWN,
    headsetRequest: !data.headset_request ? undefined : normalizeHeadsetRequest(data.headset_request),
  }
}

const normalizeStreamSettings = (data?: Partial<model.generated.StreamSettings>): undefined | model.StreamSettings => {
  if (!data) return undefined
  if (!data.media_server_url && !data.user_list_disabled) return undefined

  return {
    mediaServerUrl: data.media_server_url ?? "",
    userListDisabled: data.user_list_disabled ?? false,
  }
}

const normalizeDigitalFootprint = (data: Partial<model.generated.DigitalFootprint> = {}): model.DigitalFootprint => ({
  facebook: data.facebook?.trim(),
  instagram: data.instagram?.trim(),
  linkedin: data.linkedin?.trim(),
  twitter: data.twitter?.trim(),
  website: data.website?.trim(),
})

const normalizeDisplay = (data: Partial<model.generated.Display> = {}): model.Display => ({
  marketing: data.marketing ?? false,
})

export const normalizeUser = (data: Partial<model.generated.User>): model.NormalizedUser => {
  if (!data.user_id) {
    throw new Error("missing user_id")
  }

  return {
    id: data.user_id,

    industry: !data.industry_id ? undefined : { industry: data.industry_id, jobTypeId: data.job_type_id },

    organizations: Object.entries(data.user_organizations ?? {}).map(([organizationId, userOrg]) => ({
      organization: organizationId,
      role: userOrg.org_role ?? enums.CommonStatus.UNKNOWN,
    })),

    specialties: Object.entries(data.user_specialties ?? {}).map(([id, elem]) => ({
      specialty: id,
      certification: {
        status: elem.certification?.status ?? enums.CommonStatus.UNKNOWN,
      },
      subspecialtyIds: elem.child_ids ?? [],
    })),

    events: Object.entries(data.user_events ?? {}).map(([id, elem]) => ({
      event: id,
      registrationStatus: elem.registration_status ?? enums.CommonStatus.UNKNOWN,
      role: elem.role ?? enums.CommonStatus.UNKNOWN,
    })),

    email: data.email ?? "",
    roles: data.user_role ? (data.user_role.split(",") as UserRole[]) : [],
    name: normalizeUserName(data.name),
    phone: data.phone,
    address: normalizeAddress(data.address),
    preferences: normalizeUserPreferences(data.preferences),
    assistant: data.assistant && normalizeUserInfo(data.assistant),
    photo: data.photo,

    acceptedTerms: data.accepted_terms,
    emailVerified: data.email_verified,
    lastLogin: !data.last_login ? 0 : new Date(data.last_login).getTime(),
    lastAuthRefresh: !data.last_auth_refresh ? 0 : new Date(data.last_auth_refresh).getTime(),
  }
}

export const normalizeEvent = (data: Partial<model.generated.Event>): model.NormalizedEvent => {
  if (!data.event_id) {
    throw new Error("missing event_id")
  }
  if (!data.organization_id) {
    throw new Error("missing organization_id")
  }

  const users = Object.entries(data.event_users ?? {}).map(([id, elem]) => normalizeEventUser(id, elem))

  return {
    id: data.event_id,

    organization: data.organization_id,

    location: data.location_id,

    instructors: data.instructor_ids ?? [],
    guestInstructors: (data.guest_instructors ?? []).map((elem) => ({
      email: elem.email,
      name: normalizeUserName(elem.name),
    })),

    customerSuccessRep: data.cs_rep_user_id,

    users,

    specialty: !data.specialty_id
      ? undefined
      : {
          specialty: data.specialty_id,
          subspecialtyId: data.sub_specialty_id,
        },

    title: data.title ?? "",
    description: data.description ?? "",
    privacy: data.privacy ?? "PRIVATE",
    status: data.status ?? enums.CommonStatus.UNKNOWN,
    startTime: !data.start_time ? 0 : new Date(data.start_time).getTime(),
    endTime: !data.end_time ? 0 : new Date(data.end_time).getTime(),
    seatCount: data.seat_count ?? 0,
    usersCount: data.event_users_count ?? 0,
    streamSettings: normalizeStreamSettings(data.stream_settings),
    photo: data.photo,
  }
}

export const normalizeInstructor = (data: Partial<model.generated.Instructor>): model.NormalizedInstructor => {
  if (!data.instructor_id) {
    throw new Error("missing instructor_id")
  }

  return {
    id: data.instructor_id,

    organizations: data.organization_ids ?? [],

    locations: data.location_ids ?? [],

    specialties: Object.entries(data.instructor_specialties ?? {}).map(([id, elem]) => ({
      specialty: id,
      certification: {
        status: elem.certification?.status ?? enums.CommonStatus.UNKNOWN,
      },
      subspecialtyIds: elem.child_ids ?? [],
    })),

    email: data.email ?? "",
    status: data.status ?? enums.CommonStatus.INACTIVE,
    slug: data.slug ?? "",
    description: data.description ?? "",

    name: normalizeUserName(data.name),
    digitalFootprint: normalizeDigitalFootprint(data.digital_footprint),
    display: normalizeDisplay(data.display),

    quote: data.quote,
    practice: data.practice,
    photo: data.photo,
  }
}

export const mapEventFilter = (
  filter?: model.ListEventsApiArg["filter"],
): model.generated.ListEventsApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      public_only: filter.publicOnly,
      self: filter.self,
      event_ids: filter.eventIds,
      user_ids: filter.userIds,
      instructor_ids: filter.instructorIds,
      organization_ids: filter.organizationIds,
      end_before: filter.endBefore,
      end_after: filter.endAfter,
      start_before: filter.startBefore,
      start_after: filter.startAfter,
      specialty_ids: filter.specialtyIds,
      stream_type: filter.streamType,
    }).filter(([, v]) => v !== undefined),
  )
}

export const mapUserFilter = (filter?: model.ListUsersApiArg["filter"]): model.generated.ListUsersApiArg["filter"] => {
  if (!filter) return undefined
  if (!Object.values(filter).find((v) => v !== undefined)) {
    return undefined
  }
  return Object.fromEntries(
    Object.entries({
      name: filter.name,
      email: filter.email,
      self: filter.self,
      user_ids: filter.userIds,
      specialty_ids: filter.specialtyIds,
      organization_ids: filter.organizationIds,
      user_role: filter.userRole,
      event_ids: filter.eventIds,
      registration_status: filter.registrationStatus,
    }).filter(([, v]) => v !== undefined),
  )
}
