export const deviceKinds = {
  VIDEO_INPUT: "videoinput",
  AUDIO_INPUT: "audioinput",
  AUDIO_OUTPUT: "audiooutput",
} as const

export type Device = {
  readonly id: string
  readonly title: string
  readonly kind: MediaDeviceKind
}
