import React from "react"
import Swal from "sweetalert2"
import { Flex, Box, Link } from "rebass"
import { Link as RouterLink } from "react-router-dom"
import { Button } from "../../components/Button"
import Version from "../../components/Version"
import { colors } from "../../etc/theme"
import homeBg from "./images/bg.png"
import logo from "./images/logo.png"

export const Home: React.FC = () => {
  const onTurnOff = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    Swal.fire({
      title: "Would you like to shut down the system?",
      text: "This will turn the system off completely",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: colors.red,
      cancelButtonColor: colors.blue,
      confirmButtonText: "Shut Down",
    }).then((result) => {
      if (result.value) {
        window.electronAPI.shutDown()
      }
    })
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      className="vh-full"
      sx={{
        backgroundImage: `url(${homeBg})`,
        backgroundSize: "cover",
      }}
    >
      <Box width="450px" fontSize="20px" lineHeight="24px" css={{ position: "relative" }}>
        <Link
          height="380px"
          display="block"
          sx={{
            background: `url(${logo}) no-repeat center center`,
          }}
        />
        <Button as={RouterLink} to="/events" width="180px" m="80px auto 20px" p="12px 10px">
          Enter
        </Button>
        <Button width="180px" m="0 auto" p="12px 10px" variant="warning" onClick={onTurnOff}>
          Shut Down
        </Button>
      </Box>
      <Version />
    </Flex>
  )
}
