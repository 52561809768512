import React, { useState, useEffect } from "react"
import { Box, BoxProps } from "rebass"
import { formatAMPM } from "../../helpers"

// Displays current user's time (updates it every n sec).
export const Time: React.FC<BoxProps> = (props) => {
  const [time, setTime] = useState(formatAMPM(new Date()))
  const interval = 1000 * 10 // 10 sec.

  useEffect(() => {
    const timeInterval = setInterval(() => setTime(formatAMPM(new Date())), interval)

    return () => clearInterval(timeInterval)
  }, [])

  return <Box {...props}>{time}</Box>
}
